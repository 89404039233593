import { useEffect, useState } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { Add as AddIcon } from '@mui/icons-material'
import { useMutation } from 'urql'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../redux/store'
import { filterFranchisesIds } from '../../../utils/utils'
import { useGetAllExpTraining } from '../../../hooks/ExperimentalTrainings/useExpTrainings'
import { DELETE_EXP_TRAINING } from '../../../queries/expTraining'
import { handleErrors } from '../../../utils/handleGraphQLErrors'
import { toastDanger, toastSuccess } from '../../../redux/toast/actions'

export const useExperimentalTrainingsContainer = (isOpen: boolean) => {
  const {
    globalFilter: { selectedFranchises },
  } = useAppSelector((state) => state)

  const [isFetching, response, reExecute] = useGetAllExpTraining(false, {
    filterFranchisesIds: filterFranchisesIds(selectedFranchises),
  })

  const [tableData, setTableData] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(0)

  const navigate = useNavigate()

  const addExpTraining = () => navigate(`/geral/experimental-trainings/addExpTraining`)
  const editExpTraining = (expTrainingId: string) => {
    navigate(`/geral/experimental-trainings/${expTrainingId}`)
  }

  const panelButtons = [
    {
      onClick: addExpTraining,
      buttonIcon: <AddIcon />,
      name: 'Novo treino exp.',
    },
  ]

  useEffect(() => {
    if (!isOpen) reExecute({ requestPolicy: 'network-only' })
  }, [reExecute, isOpen])

  useEffect(() => {
    if (!isFetching && response) {
      const expTrainingMappedList = response?.data?.map((expTrainingData: any) => ({
        id: expTrainingData?.id,
        name: expTrainingData?.name,
        phone: expTrainingData?.phone,
        franchise: expTrainingData?.franchise?.name,
        team: expTrainingData?.team?.name,
        createdAt: moment(expTrainingData?.attendanceDay).format('LL'),
      }))
      setTableData(expTrainingMappedList ?? [])
      setTotal(response?.total ?? 0)
    }
  }, [isFetching, response])

  return {
    isFetching,
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    total,
    setCurrentPage,
    addExpTraining,
    editExpTraining,
    panelButtons,
  }
}

export const useDeleteExpTrainingList = () => {
  const [deleteExpTrainingResult, deleteExpTraining] = useMutation(DELETE_EXP_TRAINING)
  const [isOpen, setIsOpen] = useState(false)
  const [deleteOptions, setDeleteOptions] = useState({ id: '', name: '' })

  const dispatch = useDispatch()

  const handleDeleteExpTraining = (expTrainingId: string, athleteName: string) => {
    setDeleteOptions({ id: expTrainingId ?? '0', name: athleteName })
    setIsOpen(true)
  }

  const handleDeleteAction = () => {
    deleteExpTraining({
      id: parseInt(deleteOptions.id, 10),
    })
      .then((result: any) => {
        if (result?.error) {
          const [error] = handleErrors(result?.error)
          dispatch(toastDanger(error))
        } else {
          dispatch(toastSuccess('Treino exp. removido com sucesso!'))
          setIsOpen(false)
          setDeleteOptions({ id: '', name: '' })
        }
      })
      .catch(() => {
        dispatch(toastDanger('Erro ao remover treino exp.'))
      })
  }

  useEffect(() => {
    if (!isOpen) setDeleteOptions({ id: '', name: '' })
  }, [isOpen])

  const isFetchingDelete = deleteExpTrainingResult.fetching

  return {
    handleDeleteExpTraining,
    handleDeleteAction,
    isOpen,
    setIsOpen,
    deleteOptions,
    isFetchingDelete,
  }
}
