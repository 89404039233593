import { BreadcrumbItem, Panel } from '../../../../components'
import AddEditAthleteForm from '../../../../Forms/AddEditAthleteForm/AddEditAthleteForm'
import { useAddEditAthlete } from './useAddEditAthlete'

function AddEditAthlete() {
  const {
    isFetching,
    initialValues,
    optionsSelect,
    isAddMode,
    handleSubmit,
    handleGoBack,
    getTitle,
    isAthlete,
    dataTeams,
  } = useAddEditAthlete()

  const breadcrumbs = [
    <BreadcrumbItem key="tecnico" text="Técnico" link="/geral/atletas" />,
    <BreadcrumbItem key="atletas" text="Atletas" link="/geral/atletas" />,
    <BreadcrumbItem key="adicionar-editar" text={getTitle()} />,
  ]

  return (
    <Panel
      title={getTitle()}
      isLoading={isFetching}
      breadcrumbs={isAthlete ? [] : breadcrumbs}
      backButton={
        !isAthlete
          ? {
              onClick: handleGoBack,
              name: 'Voltar',
            }
          : undefined
      }
    >
      <AddEditAthleteForm
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        optionsFranchises={optionsSelect}
        isAthlete={isAthlete}
        isAddMode={isAddMode}
        dataTeams={dataTeams}
      />
    </Panel>
  )
}

export default AddEditAthlete
