import styled from 'styled-components'
import DatePicker, { registerLocale } from 'react-datepicker'
import ptBr from 'date-fns/locale/pt-BR'
import { Tooltip } from '@mui/material'
import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material'
import { DatePickerComponentProps, DatePickerWrapperProps, WrapperProps } from './interface'

registerLocale('pt-BR', ptBr)

const Wrapper = styled('div')<WrapperProps>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props?.disableGrow ? 0 : 1)} 1
    ${(props) => (props?.width ? props?.width : '200px')};
  height: 5rem;
  @media screen and (max-width: 769px) {
    width: 100%;
  }
  margin-top: ${(props) => props?.theme?.spacing?.spc10};
  label {
    font-size: ${(props) => props?.theme?.fontSizes?.font18};
    font-weight: 600;
    color: ${(props) => props?.theme?.colors?.primary};
  }
`

const DatePickerWrapper = styled('div')<DatePickerWrapperProps>`
  display: flex;
  align-items: center;
  background-color: ${(props) => props?.theme?.colors?.white};
  padding: ${(props) => props?.theme?.spacing?.spc8} ${(props) => props?.theme?.spacing?.spc8};
  border-radius: 4px;
  border: 1px solid
    ${(props) => (props.isError ? props?.theme?.colors?.red : props?.theme?.colors?.primary)};
  margin-top: ${(props) => props?.theme?.spacing?.spc8};
  input {
    width: 90%;
    font-size: ${(props) => props?.theme?.fontSizes?.font20};
    color: ${(props) => props?.theme?.colors?.black};
    border: none;
    outline: none;
    background-color: ${(props) => props?.theme?.colors?.white};
  }
  svg {
    cursor: pointer;
    color: ${(props) => props?.theme?.colors?.white};
    background: ${(props) =>
      props.isError ? props?.theme?.colors?.red : props?.theme?.colors?.primary};
    border-radius: 50%;
    font-size: ${(props) => props?.theme?.fontSizes?.font16};
  }
  .react-datepicker-popper {
    z-index: 9999 !important;
  }
`

const ErrorDiv = styled('div')`
  color: ${(props) => props?.theme?.colors?.red};
  font-size: ${(props) => props?.theme?.fontSizes?.font14};
  margin-top: ${(props) => props?.theme?.spacing?.spc6};
`

function DatePickerComponent({
  name,
  label,
  props,
  setValue,
  value,
  isText,
  width,
  disabled,
  dateFormat,
  excludeDates,
  disableGrow,
}: DatePickerComponentProps) {
  const handleClear = () => {
    if (setValue) setValue(null)
    if (props?.setFieldValue) props?.setFieldValue(name, null)
  }

  const handleOnChange = (date: Date | null) => {
    if (setValue) setValue(date)
    if (props?.setFieldValue) props?.setFieldValue(name, date)
  }
  return (
    <Wrapper width={width} disableGrow={disableGrow} data-testid="date-picker-component">
      {label && <label htmlFor={name}>{label}</label>}
      {isText && <p>{value}</p>}
      {!isText && (
        <DatePickerWrapper isError={!!props?.errors[name]}>
          <DatePicker
            name={name}
            selected={value}
            onChange={handleOnChange}
            maxDate={new Date()}
            dateFormat={dateFormat ?? 'dd/MM/yyyy'}
            locale="pt-BR"
            excludeDates={excludeDates}
            showMonthYearPicker={!!dateFormat}
          />
          {value && !disabled && (
            <Tooltip key={`date-picker-component-${name?.trim()}`} title="Limpar">
              <CloseRoundedIcon onClick={handleClear} />
            </Tooltip>
          )}
        </DatePickerWrapper>
      )}
      {props?.touched && props?.errors && props?.touched[name] && props?.errors[name] && (
        <ErrorDiv>{props?.errors[name]}</ErrorDiv>
      )}
    </Wrapper>
  )
}

export default DatePickerComponent
