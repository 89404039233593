import { useEffect, useState } from 'react'
import { useMutation } from 'urql'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { FormikHelpers } from 'formik'
import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../redux/store'
import { DELETE_ATTENDANCE_LIST } from '../../../queries/attendanceList'
import {
  AttendanceListFilterFormValues,
  AttendanceListFilterTeamOptionsProps,
  AttendanceListFilterValues,
  useFiltersAttendanceListProps,
} from '../../../@types/attendanceList'
import { filterFranchisesIds } from '../../../utils/utils'
import { useGetAllAttendanceListQuery } from '../../../hooks/AttendanceList/useAttendanceList'
import { useTeamsQuery } from '../../../hooks/Teams/useTeams'
import { toastDanger, toastSuccess } from '../../../redux/toast/actions'
import { handleErrors } from '../../../utils/handleGraphQLErrors'

export const useAttendanceListContainer = (isOpen: boolean) => {
  const {
    auth: { user },
    globalFilter: { selectedFranchises },
  } = useAppSelector((state) => state)

  const [tableData, setTableData] = useState([] as any)
  const [pageSize, setPageSize] = useState<number>(50)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  const [isOpenFilter, setIsOpenFilter] = useState(false)

  const [teamsOptions, setTeamsOptions] = useState([] as AttendanceListFilterTeamOptionsProps[])
  const [filters, setFilters] = useState<AttendanceListFilterValues>({
    month: 0,
    year: 0,
    teamId: 0,
  })
  const isAthleteOrClient = !['Administrador', 'Admin Unidade', 'Treinador'].includes(user?.role)
  const isAthlete = user?.role === 'Atleta'
  const [deleteAttendanceListResult, deleteAttendanceList] = useMutation(DELETE_ATTENDANCE_LIST)
  const franchisesIds = filterFranchisesIds(selectedFranchises)
  const {
    data: dataGetAllAttendance,
    isFetching: isFetchingAttendance,
    reExecute: reExecuteAttendance,
  } = useGetAllAttendanceListQuery(isAthleteOrClient, {
    pageNumber: currentPage,
    pageSize,
    teamId: filters.teamId,
    month: filters.month,
    year: filters.year,
    ...(!isEmpty(selectedFranchises) && {
      filterFranchisesIds: franchisesIds,
    }),
    sort: 'DESC',
  })

  const { data: dataTeams, isFetching: isFetchingTeams } = useTeamsQuery(isAthlete, {
    ...(!isEmpty(selectedFranchises) && {
      filterFranchisesIds: franchisesIds,
    }),
  })

  const fetching = isFetchingAttendance || isFetchingTeams || deleteAttendanceListResult.fetching

  const navigate = useNavigate()
  const editAttendanceList = (attendanceListId: string | number) =>
    navigate(`/tecnico/chamadas/${attendanceListId}`)

  useEffect(() => {
    if (!isFetchingAttendance && dataGetAllAttendance) {
      moment.locale('pt-br')
      const attendanceListMapped = dataGetAllAttendance?.data?.map((item) => ({
        id: item?.id,
        attendanceDay: moment(item?.attendanceDay).format('LL'),
        updatedAt: moment(item?.updatedAt).format('LL'),
        franchise: item?.franchise?.name,
        team: item?.team?.name,
      }))
      setTableData(attendanceListMapped ?? [])
      setTotal(dataGetAllAttendance?.total ?? 0)
    }
  }, [dataGetAllAttendance, isFetchingAttendance])

  useEffect(() => {
    if (!isFetchingTeams && dataTeams) {
      const teamListMapped = dataTeams?.data?.map((team: any) => ({
        id: team?.id,
        name: team?.name,
        franchiseId: team?.franchise?.id,
      }))
      setTeamsOptions(teamListMapped ?? [])
    }
  }, [isFetchingTeams, dataTeams])

  useEffect(() => {
    if (!isOpen) reExecuteAttendance({ requestPolicy: 'network-only' })
  }, [reExecuteAttendance, isOpen])

  return {
    fetching,
    tableData,
    deleteAttendanceList,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    total,
    editAttendanceList,
    isOpen,
    filters,
    setFilters,
    teamsOptions,
    isOpenFilter,
    setIsOpenFilter,
    navigate,
    franchisesIds,
  }
}

export const useFiltersAttendanceList = ({
  setFilters,
  setCurrentPage,
}: useFiltersAttendanceListProps) => {
  const initialValuesFilter: AttendanceListFilterFormValues = {
    team: 0,
    attendanceMonth: '',
  }

  const handleSearch = (
    values: AttendanceListFilterFormValues,
    actions: FormikHelpers<AttendanceListFilterFormValues>
  ) => {
    const month = values.attendanceMonth ? moment(values.attendanceMonth).month() + 1 : 0
    const year = values.attendanceMonth ? moment(values.attendanceMonth).year() : 0
    setCurrentPage(0)
    setFilters({
      month,
      year,
      teamId: values.team,
    })

    actions.setSubmitting(false)
  }

  return {
    handleSearch,
    initialValuesFilter,
  }
}

export const useDeleteAttendanceList = () => {
  const [deleteAttendanceListResult, deleteAttendanceList] = useMutation(DELETE_ATTENDANCE_LIST)
  const [isOpen, setIsOpen] = useState(false)
  const [deleteOptions, setDeleteOptions] = useState({ id: '', name: '' })

  const dispatch = useDispatch()

  const handleDeleteAttendanceList = (attendanceId: string, attendanceName: string) => {
    setDeleteOptions({ id: attendanceId ?? '0', name: attendanceName })
    setIsOpen(true)
  }

  const handleDeleteAction = () => {
    deleteAttendanceList({
      id: parseInt(deleteOptions.id, 10),
    })
      .then((result: any) => {
        if (result?.error) {
          const [error] = handleErrors(result?.error)
          dispatch(toastDanger(error))
        } else {
          dispatch(toastSuccess('Chamada removida com sucesso!'))
          setIsOpen(false)
          setDeleteOptions({ id: '', name: '' })
        }
      })
      .catch(() => {
        dispatch(toastDanger('Erro ao remover chamada'))
      })
  }

  useEffect(() => {
    if (!isOpen) setDeleteOptions({ id: '', name: '' })
  }, [isOpen])

  const isFetchingDelete = deleteAttendanceListResult.fetching

  return {
    handleDeleteAttendanceList,
    handleDeleteAction,
    isOpen,
    setIsOpen,
    deleteOptions,
    isFetchingDelete,
  }
}
