export const VERSION = 'Version 5.0.3'

export const CONSTANTS = {
  ROLES: {
    ADMINISTRADOR: 'Administrador',
    ADMIN_UNIDADE: 'Admin Unidade',
    TREINADOR: 'Treinador',
    ATLETA: 'Atleta',
    CLIENTE: 'Cliente',
  },
  ENVIRONMENT: {
    PRODUCTION: 'portal.idsports.com.br',
    STAGING: 'staging-admin.idsports.com.br',
  },
}

export const FILTER_ALLOWED_PATHS = [
  '/geral/dashboard',
  '/geral/unidades',
  '/geral/atletas',
  '/geral/turmas',
  '/geral/administradores',
  '/financeiro/clientes',
  '/financeiro/chaves',
  '/tecnico/treinadores',
  '/tecnico/chamadas',
  '/tecnico/avaliacoes',
]

export const STATES = [
  { label: 'Acre', value: 'AC' },
  { label: 'Alagoas', value: 'AL' },
  { label: 'Amapá', value: 'AP' },
  { label: 'Amazonas', value: 'AM' },
  { label: 'Bahia', value: 'BA' },
  { label: 'Ceará', value: 'CE' },
  { label: 'Distrito Federal', value: 'DF' },
  { label: 'Espírito Santo', value: 'ES' },
  { label: 'Goiás', value: 'GO' },
  { label: 'Maranhão', value: 'MA' },
  { label: 'Mato Grosso', value: 'MT' },
  { label: 'Mato Grosso do Sul', value: 'MS' },
  { label: 'Minas Gerais', value: 'MG' },
  { label: 'Pará', value: 'PA' },
  { label: 'Paraíba', value: 'PB' },
  { label: 'Parana', value: 'PR' },
  { label: 'Pernambuco', value: 'PE' },
  { label: 'Piauí', value: 'PI' },
  { label: 'Rio de Janeiro', value: 'RJ' },
  { label: 'Rio Grande do Norte', value: 'RN' },
  { label: 'Rio Grande do Sul', value: 'RS' },
  { label: 'Rondônia', value: 'RO' },
  { label: 'Roraima', value: 'RR' },
  { label: 'Santa Catarina', value: 'SC' },
  { label: 'São Paulo', value: 'SP' },
  { label: 'Sergipe', value: 'SE' },
  { label: 'Tocantins', value: 'TO' },
]

export const OPTIONS_RELATIONSHIP = [
  { value: 'Pai', label: 'Pai' },
  { value: 'Mãe', label: 'Mãe' },
  { value: 'Avô', label: 'Avô' },
  { value: 'Avó', label: 'Avó' },
  { value: 'Padrastro', label: 'Padrastro' },
  { value: 'Madastra', label: 'Madrastra' },
  { value: 'Outro', label: 'Outro' },
]

export const OPTIONS_STATUS = [
  { label: 'Ativo', value: 'active' },
  { label: 'Inativo', value: 'inactive' },
]

export const OPTIONS_GENDER = [
  { label: 'Feminino', value: 'Feminino' },
  { label: 'Masculino', value: 'Masculino' },
]

export const OPTIONS_GENDER_ENGLISH = [
  { value: 'female', label: 'Feminino' },
  { value: 'male', label: 'Masculino' },
]

export const OPTIONS_INTERVAL = [
  { value: 'Semanal', label: 'Semanal' },
  { value: 'Quinzenal', label: 'Quinzenal' },
  { value: 'Mensal', label: 'Mensal' },
  { value: 'Bimestral', label: 'Bimestral' },
  { value: 'Trimestral', label: 'Trimestral' },
  { value: 'Semestral', label: 'Semestral' },
  { value: 'Anual', label: 'Anual' },
]

export const OPTIONS_PAYMENT_METHODS = [
  { label: 'Boleto', value: 'boleto' },
  { label: 'Cartão de crédito', value: 'credit_card' },
  { label: 'Cartão de débito', value: 'debit_card' },
]

export const OPTIONS_BILLING_TYPES = [
  { value: 'prepavalue', label: 'Pré-pago' },
  { value: 'postpavalue', label: 'Pós-pago' },
  { value: 'exact_day', label: 'Dia exato' },
]

export const OPTIONS_BEEP = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '1.1', label: '1.1' },
  { value: '1.2', label: '1.2' },
  { value: '1.3', label: '1.3' },
  { value: '1.4', label: '1.4' },
  { value: '1.5', label: '1.5' },
  { value: '1.6', label: '1.6' },
  { value: '1.7', label: '1.7' },
  { value: '2', label: '2' },
  { value: '2.1', label: '2.1' },
  { value: '2.2', label: '2.2' },
  { value: '2.3', label: '2.3' },
  { value: '2.3', label: '2.3' },
  { value: '2.4', label: '2.4' },
  { value: '2.5', label: '2.5' },
  { value: '2.6', label: '2.6' },
  { value: '2.7', label: '2.7' },
  { value: '2.8', label: '2.8' },
  { value: '3', label: '3' },
  { value: '3.1', label: '3.1' },
  { value: '3.2', label: '3.2' },
  { value: '3.3', label: '3.3' },
  { value: '3.4', label: '3.4' },
  { value: '3.5', label: '3.5' },
  { value: '3.6', label: '3.6' },
  { value: '3.7', label: '3.7' },
  { value: '3.8', label: '3.8' },
  { value: '4', label: '4' },
  { value: '4.1', label: '4.1' },
  { value: '4.2', label: '4.2' },
  { value: '4.3', label: '4.3' },
  { value: '4.4', label: '4.4' },
  { value: '4.5', label: '4.5' },
  { value: '4.6', label: '4.6' },
  { value: '4.7', label: '4.7' },
  { value: '4.8', label: '4.8' },
  { value: '4.9', label: '4.9' },
  { value: '5', label: '5' },
  { value: '5.1', label: '5.1' },
  { value: '5.2', label: '5.2' },
  { value: '5.3', label: '5.3' },
  { value: '5.4', label: '5.4' },
  { value: '5.5', label: '5.5' },
  { value: '5.6', label: '5.6' },
  { value: '5.7', label: '5.7' },
  { value: '5.8', label: '5.8' },
  { value: '5.9', label: '5.9' },
  { value: '6', label: '6' },
  { value: '6.1', label: '6.1' },
  { value: '6.2', label: '6.2' },
  { value: '6.3', label: '6.3' },
  { value: '6.4', label: '6.4' },
  { value: '6.5', label: '6.5' },
  { value: '6.6', label: '6.6' },
  { value: '6.7', label: '6.7' },
  { value: '6.8', label: '6.8' },
  { value: '6.9', label: '6.9' },
  { value: '6.10', label: '6.10' },
  { value: '7', label: '7' },
  { value: '7.1', label: '7.1' },
  { value: '7.2', label: '7.2' },
  { value: '7.3', label: '7.3' },
  { value: '7.4', label: '7.4' },
  { value: '7.5', label: '7.5' },
  { value: '7.6', label: '7.6' },
  { value: '7.7', label: '7.7' },
  { value: '7.8', label: '7.8' },
  { value: '7.9', label: '7.9' },
  { value: '7.10', label: '7.10' },
  { value: '8', label: '8' },
  { value: '8.1', label: '8.1' },
  { value: '8.2', label: '8.2' },
  { value: '8.3', label: '8.3' },
  { value: '8.4', label: '8.4' },
  { value: '8.5', label: '8.5' },
  { value: '8.6', label: '8.6' },
  { value: '8.7', label: '8.7' },
  { value: '8.8', label: '8.8' },
  { value: '8.9', label: '8.9' },
  { value: '8.10', label: '8.10' },
  { value: '8.11', label: '8.11' },
  { value: '9', label: '9' },
  { value: '9.1', label: '9.1' },
  { value: '9.2', label: '9.2' },
  { value: '9.3', label: '9.3' },
  { value: '9.4', label: '9.4' },
  { value: '9.5', label: '9.5' },
  { value: '9.6', label: '9.6' },
  { value: '9.7', label: '9.7' },
  { value: '9.8', label: '9.8' },
  { value: '9.9', label: '9.9' },
  { value: '9.10', label: '9.10' },
  { value: '9.11', label: '9.11' },

  { value: '10', label: '10' },
  { value: '10.1', label: '10.1' },
  { value: '10.2', label: '10.2' },
  { value: '10.3', label: '10.3' },
  { value: '10.4', label: '10.4' },
  { value: '10.5', label: '10.5' },
  { value: '10.6', label: '10.6' },
  { value: '10.7', label: '10.7' },
  { value: '10.8', label: '10.8' },
  { value: '10.9', label: '10.9' },
  { value: '10.10', label: '10.10' },
  { value: '10.11', label: '10.11' },

  { value: '11', label: '11' },
  { value: '11.1', label: '11.1' },
  { value: '11.2', label: '11.2' },
  { value: '11.3', label: '11.3' },
  { value: '11.4', label: '11.4' },
  { value: '11.5', label: '11.5' },
  { value: '11.6', label: '11.6' },
  { value: '11.7', label: '11.7' },
  { value: '11.8', label: '11.8' },
  { value: '11.9', label: '11.9' },
  { value: '11.10', label: '11.10' },
  { value: '11.11', label: '11.11' },
  { value: '11.12', label: '11.12' },
  { value: '12', label: '12' },
  { value: '12.1', label: '12.1' },
  { value: '12.2', label: '12.2' },
  { value: '12.3', label: '12.3' },
  { value: '12.4', label: '12.4' },
  { value: '12.5', label: '12.5' },
  { value: '12.6', label: '12.6' },
  { value: '12.7', label: '12.7' },
  { value: '12.8', label: '12.8' },
  { value: '12.9', label: '12.9' },
  { value: '12.10', label: '12.10' },
  { value: '12.11', label: '12.11' },
  { value: '12.12', label: '12.12' },
  { value: '13', label: '13' },
  { value: '13.1', label: '13.1' },
  { value: '13.2', label: '13.2' },
  { value: '13.3', label: '13.3' },
  { value: '13.4', label: '13.4' },
  { value: '13.5', label: '13.5' },
  { value: '13.6', label: '13.6' },
  { value: '13.7', label: '13.7' },
  { value: '13.8', label: '13.8' },
  { value: '13.9', label: '13.9' },
  { value: '13.10', label: '13.10' },
  { value: '13.11', label: '13.11' },
  { value: '13.12', label: '13.12' },
  { value: '13.13', label: '13.13' },
  { value: '14', label: '14' },
  { value: '14.1', label: '14.1' },
  { value: '14.2', label: '14.2' },
  { value: '14.3', label: '14.3' },
  { value: '14.4', label: '14.4' },
  { value: '14.5', label: '14.5' },
  { value: '14.6', label: '14.6' },
  { value: '14.7', label: '14.7' },
  { value: '14.8', label: '14.8' },
  { value: '14.9', label: '14.9' },
  { value: '14.10', label: '14.10' },
  { value: '14.11', label: '14.11' },
  { value: '14.12', label: '14.12' },
  { value: '14.13', label: '14.13' },
  { value: '15', label: '15' },
  { value: '15.1', label: '15.1' },
  { value: '15.2', label: '15.2' },
  { value: '15.3', label: '15.3' },
  { value: '15.4', label: '15.4' },
  { value: '15.5', label: '15.5' },
  { value: '15.6', label: '15.6' },
  { value: '15.7', label: '15.7' },
  { value: '15.8', label: '15.8' },
  { value: '15.9', label: '15.9' },
  { value: '15.10', label: '15.10' },
  { value: '15.11', label: '15.11' },
  { value: '15.12', label: '15.12' },
  { value: '15.13', label: '15.13' },
  { value: '16', label: '16' },
  { value: '16.1', label: '16.1' },
  { value: '16.2', label: '16.2' },
  { value: '16.3', label: '16.3' },
  { value: '16.4', label: '16.4' },
  { value: '16.5', label: '16.5' },
  { value: '16.6', label: '16.6' },
  { value: '16.7', label: '16.7' },
  { value: '16.8', label: '16.8' },
  { value: '16.9', label: '16.9' },
  { value: '16.10', label: '16.10' },
  { value: '16.11', label: '16.11' },
  { value: '16.12', label: '16.12' },
  { value: '16.13', label: '16.13' },
  { value: '16.14', label: '16.14' },
  { value: '17', label: '17' },
  { value: '17.1', label: '17.1' },
  { value: '17.2', label: '17.2' },
  { value: '17.3', label: '17.3' },
  { value: '17.4', label: '17.4' },
  { value: '17.5', label: '17.5' },
  { value: '17.6', label: '17.6' },
  { value: '17.7', label: '17.7' },
  { value: '17.8', label: '17.8' },
  { value: '17.9', label: '17.9' },
  { value: '17.10', label: '17.10' },
  { value: '17.11', label: '17.11' },
  { value: '17.12', label: '17.12' },
  { value: '17.13', label: '17.13' },
  { value: '17.14', label: '17.14' },
  { value: '18', label: '18' },
  { value: '18.1', label: '18.1' },
  { value: '18.2', label: '18.2' },
  { value: '18.3', label: '18.3' },
  { value: '18.4', label: '18.4' },
  { value: '18.5', label: '18.5' },
  { value: '18.6', label: '18.6' },
  { value: '18.7', label: '18.7' },
  { value: '18.8', label: '18.8' },
  { value: '18.9', label: '18.9' },
  { value: '18.10', label: '18.10' },
  { value: '18.11', label: '18.11' },
  { value: '18.12', label: '18.12' },
  { value: '18.13', label: '18.13' },
  { value: '18.14', label: '18.14' },
  { value: '18.15', label: '18.15' },
  { value: '19', label: '19' },
  { value: '19.1', label: '19.1' },
  { value: '19.2', label: '19.2' },
  { value: '19.3', label: '19.3' },
  { value: '19.4', label: '19.4' },
  { value: '19.5', label: '19.5' },
  { value: '19.6', label: '19.6' },
  { value: '19.7', label: '19.7' },
  { value: '19.8', label: '19.8' },
  { value: '19.9', label: '19.9' },
  { value: '19.10', label: '19.10' },
  { value: '19.11', label: '19.11' },
  { value: '19.12', label: '19.12' },
  { value: '19.13', label: '19.13' },
  { value: '19.14', label: '19.14' },
  { value: '19.15', label: '19.15' },
  { value: '20', label: '20' },
  { value: '20.1', label: '20.1' },
  { value: '20.2', label: '20.2' },
  { value: '20.3', label: '20.3' },
  { value: '20.4', label: '20.4' },
  { value: '20.5', label: '20.5' },
  { value: '20.6', label: '20.6' },
  { value: '20.7', label: '20.7' },
  { value: '20.8', label: '20.8' },
  { value: '20.9', label: '20.9' },
  { value: '20.10', label: '20.10' },
  { value: '20.11', label: '20.11' },
  { value: '20.12', label: '20.12' },
  { value: '20.13', label: '20.13' },
  { value: '20.14', label: '20.14' },
  { value: '20.15', label: '20.15' },
  { value: '20.16', label: '20.16' },
  { value: '21', label: '21' },
  { value: '21.1', label: '21.1' },
  { value: '21.2', label: '21.2' },
  { value: '21.3', label: '21.3' },
  { value: '21.4', label: '21.4' },
  { value: '21.5', label: '21.5' },
  { value: '21.6', label: '21.6' },
  { value: '21.7', label: '21.7' },
  { value: '21.8', label: '21.8' },
  { value: '21.9', label: '21.9' },
  { value: '21.10', label: '21.10' },
  { value: '21.11', label: '21.11' },
  { value: '21.12', label: '21.12' },
  { value: '21.13', label: '21.13' },
  { value: '21.14', label: '21.14' },
  { value: '21.15', label: '21.15' },
  { value: '21.16', label: '21.16' },
]

export const POSITIONS_COACHES = [
  { label: 'Treinador', value: 'Treinador' },
  { label: 'Auxiliar Técnico', value: 'Auxiliar Técnico' },
  { label: 'Preparador Físico', value: 'Preparador Físico' },
  { label: 'Preparador de Goleiros', value: 'Preparador de Goleiros' },
  { label: 'Estagiário', value: 'Estagiário' },
]

export const OPTIONS_DAYS = [
  { label: 'Segunda', value: 'Segunda' },
  { label: 'Terça', value: 'Terça' },
  { label: 'Quarta', value: 'Quarta' },
  { label: 'Quinta', value: 'Quinta' },
  { label: 'Sexta', value: 'Sexta' },
  { label: 'Sábado', value: 'Sábado' },
  { label: 'Domingo', value: 'Domingo' },
]

export const OPTIONS_ROLES_ADMIN = [
  { label: 'Administrador', value: 'Administrador' },
  { label: 'Admin Unidade', value: 'Admin Unidade' },
]
