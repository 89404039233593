import { useEffect, useState } from 'react'
import { FormikHelpers } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from 'urql'
import { useDispatch } from 'react-redux'
import { toastDanger, toastSuccess } from '../../../../redux/toast/actions'
import { ADD_FRANCHISE, UPDATE_FRANCHISE } from '../../../../queries/franchise'
import { handleErrors } from '../../../../utils/handleGraphQLErrors'
import { useAppSelector } from '../../../../redux/store'
import { FranchiseFormValues } from '../../../../@types/franchises'
import { useGetFranchiseById } from '../../../../hooks/Franchises/useFranchises'

export const useAddEditFranchise = () => {
  const {
    auth: {
      user: { role: userRole },
    },
  } = useAppSelector((state) => state)

  const { id: franchiseId } = useParams()
  const isEditMode = franchiseId !== 'addFranchise'
  const isNotFranchiseAdmin = userRole !== 'Admin Unidade'

  const [addFranchiseResult, addFranchise] = useMutation(ADD_FRANCHISE)
  const [updateFranchiseResult, updateFranchise] = useMutation(UPDATE_FRANCHISE)
  const [dataQuery, errorQuery, isFetchingQuery] = useGetFranchiseById(!isEditMode, {
    id: parseInt(franchiseId ?? '0', 10),
  })

  const isFetching =
    addFranchiseResult?.fetching || updateFranchiseResult?.fetching || isFetchingQuery

  const [initialValues, setInitialValues] = useState<FranchiseFormValues>({
    name: '',
    email: '',
    cnpj: '',
    street: '',
    streetNumber: '',
    complement: '',
    cep: '',
    neighborhood: '',
    city: '',
    state: '',
  })

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isEditMode) {
      if (errorQuery) dispatch(toastDanger('Erro ao buscar unidade!'))

      setInitialValues({
        name: dataQuery?.name,
        email: dataQuery?.email,
        cnpj: dataQuery?.cnpj,
        street: dataQuery?.logradouro,
        streetNumber: dataQuery?.number,
        complement: dataQuery?.complement,
        cep: dataQuery?.cep,
        neighborhood: dataQuery?.neighborhood,
        city: dataQuery?.city,
        state: dataQuery?.state,
      })
    }
  }, [dispatch, isEditMode, dataQuery, errorQuery])

  const handleSubmit = async (
    values: FranchiseFormValues,
    actions: FormikHelpers<FranchiseFormValues>
  ) => {
    const variablesAddEditFranchise = {
      ...(isEditMode && { id: parseInt(franchiseId || '0', 10) }),
      name: values.name,
      email: values.email,
      cnpj: values.cnpj,
      logradouro: values.street,
      number: values.streetNumber,
      neighborhood: values.neighborhood,
      complement: values.complement,
      cep: values.cep,
      city: values.city,
      state: values.state,
    }
    if (!isEditMode) {
      const response = await addFranchise(variablesAddEditFranchise)
      if (response?.error) {
        const [error] = handleErrors(response?.error)
        dispatch(toastDanger(error))
      } else {
        dispatch(toastSuccess('Unidade criada com sucesso!'))
        navigate('/geral/unidades')
      }
    } else {
      const response = await updateFranchise(variablesAddEditFranchise)
      if (response?.error) {
        const [error] = handleErrors(response?.error)
        dispatch(toastDanger(error))
      } else {
        dispatch(toastSuccess('Unidade atualizada com sucesso!'))
        navigate('/geral/unidades')
      }
    }
    actions.setSubmitting(false)
  }

  const handleGoBack = () => navigate('/geral/unidades')
  const backButton = isNotFranchiseAdmin ? { name: 'Voltar', onClick: handleGoBack } : undefined
  const title = isEditMode ? 'Editar unidade' : 'Adicionar unidade'

  return {
    isFetching,
    initialValues,
    handleSubmit,
    handleGoBack,
    isEditMode,
    backButton,
    title,
  }
}
