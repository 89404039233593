import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { AttendanceListAthleteTableInterface } from '../../@types/attendanceList'
import { useAttendanceListTableProps } from './interface'

export const useAttendanceListTable = ({
  tableData,
  setTableData,
}: useAttendanceListTableProps) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [searchValue, setSearchValue] = useState<string>('')
  const [data, setData] = useState<AttendanceListAthleteTableInterface[]>([])

  const renderCheckList = (
    tableList: AttendanceListAthleteTableInterface[],
    athleteId: string,
    operationType: string,
    pse?: number
  ) => {
    let pseValue: number = pse ?? 0
    if (pse && pse > 10) pseValue = 10
    if (pse && pse < 0) pseValue = 0

    return tableList?.map((item: AttendanceListAthleteTableInterface) => {
      if (item?.athleteId === parseInt(athleteId, 10)) {
        return {
          ...item,
          ...(operationType === 'P' && { p: true, f: false, fj: false }),
          ...(operationType === 'F' && { p: false, f: true, fj: false }),
          ...(operationType === 'FJ' && { p: false, f: false, fj: true }),
          ...(operationType === 'PSE' && (pse || pse === 0) && { pse: pseValue }),
          reason: '',
        }
      }
      return item
    })
  }

  const handleCheck = (athleteId: string, operationType: string, pse?: number) => {
    setTableData((prev) => renderCheckList(prev, athleteId, operationType, pse))
    setData((prev) => renderCheckList(prev, athleteId, operationType, pse))
  }

  const handleReason = (athleteId: string, reason: string) => {
    const newTableData = tableData?.map((item: any) => {
      if (item?.athleteId === parseInt(athleteId, 10)) {
        return {
          ...item,
          reason,
        }
      }
      return item
    })
    setTableData(newTableData)
    setData(newTableData)
  }

  useEffect(() => {
    if (!isEmpty(tableData)) {
      const sortedTableData = tableData.sort((a, b) => Number(b.p) - Number(a.p))
      setData(sortedTableData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (searchValue) {
      const newDataList = tableData.filter((row) =>
        row.name.toLowerCase().includes(searchValue.toLowerCase())
      )
      setData(newDataList)
    } else {
      setData(tableData)
    }
  }, [searchValue, tableData])

  return {
    handleCheck,
    handleReason,
    currentPage,
    setCurrentPage,
    searchValue,
    setSearchValue,
    data,
  }
}
