import { gql } from 'urql'

export const GET_ALL_EXP_TRAINING = gql`
  query($filterFranchisesIds: [Int!]) {
    getAllExperimentalTrainings(filterFranchisesIds: $filterFranchisesIds) {
      data {
        id
        name
        birthday
        team {
          id
          name
        }
        franchise {
          id
          name
        }
        createdAt
      }
      total
    }
  }
`

export const ADD_EXP_TRAINING = gql`
  mutation(
    $name: String!
    $email: String
    $phone: String
    $birthday: DateTime
    $franchiseId: Int!
    $teamId: Int!
  ) {
    createExperimentalTraining(
      input: {
        name: $name
        email: $email
        phone: $phone
        birthday: $birthday
        franchiseId: $franchiseId
        teamId: $teamId
      }
    ) {
      id
      name
      birthday
    }
  }
`

export const UPDATE_EXP_TRAINING = gql`
  mutation(
    $id: Int!
    $name: String!
    $email: String
    $phone: String
    $birthday: DateTime
    $franchiseId: Int!
    $teamId: Int!
  ) {
    updateExperimentalTraining(
      input: {
        id: $id
        name: $name
        email: $email
        phone: $phone
        birthday: $birthday
        franchiseId: $franchiseId
        teamId: $teamId
      }
    ) {
      id
      name
      email
    }
  }
`

export const GET_EXP_TRAINING_BY_ID = gql`
  query($id: Int!) {
    getExperimentalTrainingById(id: $id) {
      id
      name
      email
      phone
      birthday
      franchise {
        id
        name
      }
      team {
        id
        name
      }
    }
  }
`

export const DELETE_EXP_TRAINING = gql`
  mutation($id: Int!) {
    deleteExperimentalTraining(id: $id)
  }
`
