import styled from 'styled-components'
import { Formik, Form, FormikProps } from 'formik'
import {
  ButtonComponent,
  DatePickerComponent,
  InputComponent,
  SelectComponent,
} from '../../components'
import { OtherProps } from '../../@types'
import { ValidationExpTrainingForm } from './validation'
import { AddEditExpTrainingProps, ExpTrainingFormValues } from '../../@types/expTrainings'
import { TeamPublic } from '../../@types/responses'

const FormContainer = styled(Form)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`

const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const AddEditExpTrainingForm = ({
  handleSubmit,
  initialValues,
  optionsFranchises,
  dataTeams,
}: AddEditExpTrainingProps) => {
  const teamsOptions = (franchiseId: number | string) =>
    dataTeams?.data
      ?.filter((team: TeamPublic) => team?.franchise?.id === franchiseId)
      ?.map((team: TeamPublic) => ({ label: team?.name, value: team?.id })) ?? []

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      validationSchema={ValidationExpTrainingForm}
      enableReinitialize
    >
      {(props: OtherProps & FormikProps<ExpTrainingFormValues>) => (
        <FormContainer>
          <InputComponent
            name="name"
            label="Nome *:"
            value={props.values.name}
            props={props}
            width="350px"
          />
          <InputComponent
            name="email"
            label="E-mail:"
            value={props.values.email}
            props={props}
            width="300px"
          />
          <InputComponent
            name="phone"
            label="Telefone:"
            value={props.values.phone}
            mask="+55(99)99999-9999"
            props={props}
            width="250px"
          />
          <DatePickerComponent
            name="birthday"
            label="Data de nascimento:"
            value={props.values.birthday}
            props={props}
            width="200px"
          />
          <SelectComponent
            name="franchiseId"
            label="Unidade *:"
            placeholderText="Selecione uma unidade..."
            options={optionsFranchises}
            value={props.values.franchiseId}
            props={props}
            width="300px"
          />
          <SelectComponent
            name="teamId"
            label="Turma *:"
            placeholderText="Selecione a turma..."
            options={teamsOptions(props.values.franchiseId)}
            value={props.values.teamId}
            props={props}
            width="300px"
          />
          <ButtonWrapper>
            <ButtonComponent text="Salvar" />
          </ButtonWrapper>
        </FormContainer>
      )}
    </Formik>
  )
}

export default AddEditExpTrainingForm
