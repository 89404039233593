import { useEffect, useState } from 'react'
import { FormikHelpers } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from 'urql'
import { useDispatch } from 'react-redux'
import { toastDanger, toastSuccess } from '../../../../redux/toast/actions'
import {
  ADD_PHYSICAL_PERFORMANCE,
  UPDATE_PHYSICAL_PERFORMANCE,
} from '../../../../queries/physicalPerformance'
import { handleErrors } from '../../../../utils/handleGraphQLErrors'
import { useAppSelector } from '../../../../redux/store'
import {
  PhysicalPerformanceFormValues,
  PhysicalPerformanceModalReportsInfo,
} from '../../../../@types/physicalPerformance'
import {
  useGetTeamInfoOnPhysicalPerformanceQuery,
  usePhysicalPerformanceByIdQuery,
} from '../../../../hooks/PhysicalPerformances/usePhysicalPerformances'

export const useAddEditPhysicalPerformanceContainer = () => {
  const {
    auth: { user },
  } = useAppSelector((state) => state)

  const { id: physicalId } = useParams()
  const isAthlete: boolean = user?.role === 'Atleta'
  const isAddPhysicalPerformance: boolean = physicalId === 'addPhysicalPerformance'
  const physicalIdNumber: number = parseInt(physicalId ?? '0', 10)

  const [addPhysicalPerformanceResult, addPhysicalPerformance] = useMutation(
    ADD_PHYSICAL_PERFORMANCE
  )
  const [updatePhysicalPerformanceResult, updatePhysicalPerformance] = useMutation(
    UPDATE_PHYSICAL_PERFORMANCE
  )
  const {
    data: getPhysicalPerformance,
    isFetching: isFetchingGetPhysicalPerformance,
  } = usePhysicalPerformanceByIdQuery(isAddPhysicalPerformance || isAthlete, {
    id: physicalIdNumber,
  })

  const isFetching =
    addPhysicalPerformanceResult?.fetching ||
    updatePhysicalPerformanceResult?.fetching ||
    isFetchingGetPhysicalPerformance

  const [initialValues, setInitialValues] = useState<PhysicalPerformanceFormValues>({
    name: '',
    coach: isAddPhysicalPerformance ? user?.name : '',
    franchise: 0,
    team: 0,
    status: 'Aberta',
  })
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleSubmit = (
    values: PhysicalPerformanceFormValues,
    actions: FormikHelpers<PhysicalPerformanceFormValues>
  ) => {
    if (isAddPhysicalPerformance) {
      addPhysicalPerformance({
        franchiseId: values.franchise,
        teamId: values.team,
        userId: user?.id,
      })
        .then((result: any) => {
          if (result?.error) {
            const [error] = handleErrors(result?.error)
            dispatch(toastDanger(error))
          } else {
            const idSavedPhysicalPerformance = result?.data?.createPhysicalPerformance?.id
            dispatch(toastSuccess('Avaliação criada com sucesso!'))
            navigate(`/tecnico/avaliacoes/${idSavedPhysicalPerformance}/avaliacao-atleta/addRating`)
          }
        })
        .catch(() => {
          dispatch(toastDanger('Erro ao criar avaliação!'))
        })
    } else setIsOpen(true)

    actions.setSubmitting(false)
  }

  const handleGenerateReports = () => {
    updatePhysicalPerformance({
      id: physicalIdNumber,
      name: initialValues.name,
    })
      .then((result: any) => {
        if (result?.error) {
          const [error] = handleErrors(result?.error)
          dispatch(toastDanger(error))
        } else {
          setIsOpen(false)
          dispatch(toastSuccess('Relatórios gerados com sucesso!'))
        }
      })
      .catch(() => dispatch(toastDanger('Erro ao gerar relatórios!')))
  }

  const addAthleteTest = () =>
    navigate(`/tecnico/avaliacoes/${physicalId}/avaliacao-atleta/addRating`)

  const handleGoBack = () => navigate('/tecnico/avaliacoes')

  const labelValue = () => {
    if (isAthlete) return 'Avaliações realizadas'
    if (isAddPhysicalPerformance) return 'Criar avaliação'
    if (initialValues.status === 'Fechada' && !isAthlete) return 'Vizualizar avaliações'
    return 'Editar avaliação'
  }

  useEffect(() => {
    if (!isAddPhysicalPerformance) {
      setInitialValues({
        name: getPhysicalPerformance?.name,
        coach: getPhysicalPerformance?.user?.name,
        franchise: getPhysicalPerformance?.franchise?.id,
        team: getPhysicalPerformance?.team?.id,
        status: getPhysicalPerformance?.status,
      })
    }
  }, [getPhysicalPerformance, isAddPhysicalPerformance])

  return {
    isFetching,
    initialValues,
    isOpen,
    setIsOpen,
    handleSubmit,
    addAthleteTest,
    handleGoBack,
    labelValue,
    isAthlete,
    isAddPhysicalPerformance,
    physicalIdNumber,
    handleGenerateReports,
  }
}

export const useModalGenerateReports = (isOpen: boolean) => {
  const [infoReport, setInfoReport] = useState<PhysicalPerformanceModalReportsInfo>({
    teamName: '',
    totalTeamAthletes: 0,
    totalRemainingAthletes: 0,
    remainingAthletes: [],
  })

  const { id: physicalId } = useParams()
  const physicalIdNumber: number = parseInt(physicalId ?? '0', 10)

  const { data, isFetching } = useGetTeamInfoOnPhysicalPerformanceQuery(!isOpen, {
    id: physicalIdNumber,
  })

  useEffect(() => {
    if (isOpen && data && !isFetching) {
      setInfoReport({
        teamName: data?.teamName,
        totalTeamAthletes: data?.totalTeamAthletes,
        totalRemainingAthletes: data?.totalRemainingAthletes,
        remainingAthletes: data?.remainingAthletes,
      })
    }
  }, [data, isFetching, isOpen])

  return {
    isFetching,
    infoReport,
  }
}
