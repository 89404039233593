import { useEffect, useState } from 'react'
import { useQuery } from 'urql'
import { GET_ALL_FRANCHISE, GET_FRANCHISE_BY_ID } from '../../queries/franchise'
import { FranchiseItemIT } from '../../@types/menu'
import { useAppSelector } from '../../redux/store'
import { GenericSelectProps } from '../../@types'
import { variablesUseGetAllFranchises } from './interface'
import { IdQueryVariableType } from '../interface'

export const useGetAllFranchises = (pause: boolean, variables: variablesUseGetAllFranchises) => {
  const [getAllFranchisesResult] = useQuery({
    query: GET_ALL_FRANCHISE,
    requestPolicy: 'network-only',
    pause,
    variables,
  })

  const isFetching = getAllFranchisesResult.fetching
  const data = getAllFranchisesResult.data?.getAllFranchises

  return [isFetching, data]
}

export const useGetFranchiseById = (pause: boolean, variables: IdQueryVariableType) => {
  const [getFranchiseByIdResult] = useQuery({
    query: GET_FRANCHISE_BY_ID,
    variables,
    pause,
  })

  const data = getFranchiseByIdResult.data?.getFranchiseById
  const { error } = getFranchiseByIdResult
  const isFetching = getFranchiseByIdResult.fetching

  return [data, error, isFetching]
}

export const useFranchisesCached = () => {
  const {
    auth: { user, franchises },
  } = useAppSelector((state) => state)

  const [options, setOptions] = useState<FranchiseItemIT[]>([])
  const [optionsSelect, setOptionsSelect] = useState<GenericSelectProps[]>([])

  const [getAllFranchisesResult] = useQuery({
    query: GET_ALL_FRANCHISE,
    requestPolicy: 'cache-first',
    pause: ['Admin Unidade', 'Treinador', 'Atleta'].includes(user?.role),
  })

  const franchisesOptionsSelect = (franchisesList: FranchiseItemIT[]) =>
    franchisesList?.map((franchise) => ({
      value: franchise?.id ?? 0,
      label: franchise?.name ?? '',
    })) ?? []

  useEffect(() => {
    const franchisesData = getAllFranchisesResult?.data?.getAllFranchises?.data ?? []
    if (user?.role === 'Administrador' && franchisesData?.length > 0) {
      setOptions(franchisesData)
      setOptionsSelect(franchisesOptionsSelect(franchisesData))
    } else if (['Admin Unidade', 'Treinador', 'Atleta'].includes(user?.role)) {
      const franchisesOptions = franchises?.map((franchise) => ({
        id: franchise?.id ?? 0,
        name: franchise?.name ?? '',
      }))
      setOptionsSelect(franchisesOptionsSelect(franchisesOptions))
      setOptions(franchisesOptions ?? [])
    }
  }, [user, getAllFranchisesResult, franchises])

  return {
    options,
    optionsSelect,
  }
}
