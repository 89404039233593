import { toast } from 'react-toastify'
import {
  ToastErrorActionInterface,
  ToastInfoActionInterface,
  ToastSuccessActionInterface,
  ToastWarningActionInterface,
} from '../types/toast'

const toastSuccess = (message: string): ToastSuccessActionInterface => {
  toast.success(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })

  return {
    type: 'TOAST_CHANGE_SUCCESS',
    message,
  }
}

const toastDanger = (message: string): ToastErrorActionInterface => {
  toast.error(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })

  return {
    type: 'TOAST_CHANGE_ERROR',
    message,
  }
}

const toastWarning = (message: string): ToastWarningActionInterface => {
  toast.warning(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })

  return {
    type: 'TOAST_CHANGE_WARNING',
    message,
  }
}

const toastInfo = (message: string): ToastInfoActionInterface => {
  toast.info(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })

  return {
    type: 'TOAST_CHANGE_INFO',
    message,
  }
}

export { toastSuccess, toastDanger, toastWarning, toastInfo }
