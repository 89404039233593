import { useQuery } from 'urql'
import { GET_ALL_USERS, GET_USER_BY_ID } from '../../queries/users'
import { GetAllUsersQueryTypes } from './interface'
import { getAllUsers, getUserById } from '../../@types/responses'
import { IdQueryVariableType } from '../interface'

export const useUsersQuery = (pause: boolean, variables: GetAllUsersQueryTypes) => {
  const [getAllUsersResult, reExecute] = useQuery({
    query: GET_ALL_USERS,
    requestPolicy: 'network-only',
    variables,
    pause,
  })

  const data: getAllUsers = getAllUsersResult.data?.getAllUsers
  const isFetching: boolean = getAllUsersResult.fetching
  const { error } = getAllUsersResult

  return { data, error, isFetching, reExecute }
}

export const useGetUserByIdQuery = (pause: boolean, variables: IdQueryVariableType) => {
  const [getUserByIdResult, reExecute] = useQuery({
    query: GET_USER_BY_ID,
    variables,
    pause,
    requestPolicy: 'network-only',
  })

  const data: getUserById = getUserByIdResult.data?.getUserById
  const isFetching: boolean = getUserByIdResult.fetching
  const { error } = getUserByIdResult

  return { data, error, isFetching, reExecute }
}
