import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FormikHelpers } from 'formik'
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { ReportAttendanceFormValues } from '../../../../@types/attendanceList'
import { useReportAttendanceListQuery } from '../../../../hooks/AttendanceList/useAttendanceList'
import { ReportAttendanceListQuery } from '../../../../@types/responses'
import { useTeamsQuery } from '../../../../hooks/Teams/useTeams'

export const useReportAttendanceContainer = () => {
  const initialValues = {
    attendanceDay: '',
    franchise: 0,
    team: 0,
  }
  const [stateReport, setStateReport] = useState<ReportAttendanceListQuery>({
    franchiseName: '',
    teamName: '',
    ownerName: '',
    period: '',
    reportItems: [],
  })
  const [queryState, setQueryState] = useState({
    attendanceDate: '',
    franchiseId: 0,
    teamId: 0,
  })

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const pauseQuery =
    queryState.attendanceDate && queryState.franchiseId && queryState.attendanceDate
  const {
    data: dataReportAttendance,
    isFetching: isFetchingReportAttendance,
  } = useReportAttendanceListQuery(!pauseQuery, queryState)

  const { data: dataTeams, isFetching: isFetchingTeams } = useTeamsQuery(false, {})

  const shouldDisplayPreview = pauseQuery && !isEmpty(dataReportAttendance?.reportItems)
  const isFetching = isFetchingReportAttendance || isFetchingTeams

  const handleSubmit = (
    values: ReportAttendanceFormValues,
    actions: FormikHelpers<ReportAttendanceFormValues>
  ) => {
    setQueryState({
      attendanceDate: values.attendanceDay,
      teamId: values.team,
      franchiseId: values.franchise,
    })
    actions.setSubmitting(false)
  }

  const backButton = { name: 'Voltar', onClick: () => navigate('/tecnico/chamadas') }

  useEffect(() => {
    if (dataReportAttendance && !isFetching) setStateReport(dataReportAttendance)
  }, [dispatch, dataReportAttendance, isFetching])

  return {
    isFetching,
    initialValues,
    stateReport,
    handleSubmit,
    backButton,
    dataTeams,
    shouldDisplayPreview,
  }
}
