import AddEditUserForm from '../../../../Forms/AddEditUserForm/AddEditUserForm'
import { BreadcrumbItem, Panel } from '../../../../components'
import { useFranchisesCached } from '../../../../hooks/Franchises/useFranchises'
import { useAddEditUser } from './useAddEditUserContainer'

function AddEditUser() {
  const {
    isFetching,
    titleText,
    isAdmin,
    initialValues,
    handleSubmit,
    backButton,
  } = useAddEditUser()
  const { optionsSelect } = useFranchisesCached()

  const breadcrumbs = [
    <BreadcrumbItem key="Geral" text="Geral" link="/geral/administradores" />,
    <BreadcrumbItem key="Administradores" text="Administradores" link="/geral/administradores" />,
    <BreadcrumbItem key="administrador" text={titleText} />,
  ]

  return (
    <Panel
      title={titleText}
      isLoading={isFetching}
      breadcrumbs={breadcrumbs}
      backButton={backButton}
    >
      <AddEditUserForm
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        optionsFranchises={optionsSelect}
        isAdmin={isAdmin}
      />
    </Panel>
  )
}

export default AddEditUser
