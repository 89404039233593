import AddEditExpTrainingForm from '../../../../Forms/AddEditExpTrainingForm/AddEditExpTrainingForm'
import { BreadcrumbItem, Panel } from '../../../../components'
import { useFranchisesCached } from '../../../../hooks/Franchises/useFranchises'
import { useAddEditExpTraining } from './useAddEditExpTraining'

function AddEditExperimentalTraining() {
  const {
    isFetching,
    initialValues,
    handleSubmit,
    backButton,
    title,
    dataTeams,
  } = useAddEditExpTraining()
  const { optionsSelect } = useFranchisesCached()

  const breadcrumbs = [
    <BreadcrumbItem key="geral" text="Geral" link="/geral/experimental-trainings" />,
    <BreadcrumbItem
      key="experimental-trainings"
      text="Treinos experimentais"
      link="/geral/experimental-trainings"
    />,
    <BreadcrumbItem key="addEditExpTraining" text={title} />,
  ]

  return (
    <Panel title={title} isLoading={isFetching} breadcrumbs={breadcrumbs} backButton={backButton}>
      <AddEditExpTrainingForm
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        optionsFranchises={optionsSelect}
        dataTeams={dataTeams}
      />
    </Panel>
  )
}

export default AddEditExperimentalTraining
