import { gql } from 'urql'

export const GET_ALL_FRANCHISE = gql`
  query($filterFranchisesIds: [Int!]) {
    getAllFranchises(filterFranchisesIds: $filterFranchisesIds) {
      data {
        id
        name
        cnpj
        neighborhood
        city
        active
      }
      total
    }
  }
`

export const ADD_FRANCHISE = gql`
  mutation(
    $name: String!
    $email: String!
    $cnpj: String!
    $logradouro: String!
    $number: String!
    $neighborhood: String!
    $complement: String!
    $cep: String!
    $city: String!
    $state: String!
  ) {
    createFranchise(
      input: {
        name: $name
        email: $email
        cnpj: $cnpj
        logradouro: $logradouro
        number: $number
        neighborhood: $neighborhood
        complement: $complement
        cep: $cep
        city: $city
        state: $state
      }
    ) {
      id
      name
      email
      logradouro
      active
    }
  }
`

export const UPDATE_FRANCHISE = gql`
  mutation(
    $id: Int!
    $name: String!
    $email: String!
    $cnpj: String!
    $logradouro: String!
    $number: String!
    $neighborhood: String!
    $complement: String!
    $cep: String!
    $city: String!
    $state: String!
  ) {
    updateFranchise(
      input: {
        id: $id
        name: $name
        email: $email
        cnpj: $cnpj
        logradouro: $logradouro
        number: $number
        neighborhood: $neighborhood
        complement: $complement
        cep: $cep
        city: $city
        state: $state
      }
    ) {
      id
      name
      email
    }
  }
`

export const GET_FRANCHISE_BY_ID = gql`
  query($id: Int!) {
    getFranchiseById(id: $id) {
      id
      name
      email
      cnpj
      logradouro
      number
      neighborhood
      complement
      cep
      city
      state
      active
    }
  }
`

export const DELETE_FRANCHISE = gql`
  mutation($id: Int!) {
    deleteFranchise(id: $id)
  }
`
