import { useEffect, useState } from 'react'
import { FormikHelpers } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from 'urql'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { toastDanger, toastSuccess } from '../../../../redux/toast/actions'
import { ADD_TEAM, UPDATE_TEAM } from '../../../../queries/teams'
import { handleErrors } from '../../../../utils/handleGraphQLErrors'
import { useAppSelector } from '../../../../redux/store'
import { AddEditTeamFormValues } from '../../../../@types/team'
import { useFranchisesCached } from '../../../../hooks/Franchises/useFranchises'
import { useTeamByIdQuery } from '../../../../hooks/Teams/useTeams'

export const useAddEditTeamContainer = () => {
  const {
    auth: { user },
  } = useAppSelector((state) => state)

  const { optionsSelect } = useFranchisesCached()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [initialValues, setInitialValues] = useState<AddEditTeamFormValues>({
    name: '',
    franchise: 0,
    days: [],
    startTime: moment(),
    endTime: moment(),
    gender: '',
    active: true,
  })
  const [checkedIds, setCheckedIds] = useState<number[]>([])
  const [genderFranchise, setGenderFranchise] = useState({ gender: '', franchise: 0 })

  const { id: teamId } = useParams()
  const isEditMode = teamId !== 'addTeam'

  const [addTeamResult, addTeam] = useMutation(ADD_TEAM)
  const [updateTeamResult, updateTeam] = useMutation(UPDATE_TEAM)

  const { data: teamData, error: teamError, isFetching: isFetchingTeam } = useTeamByIdQuery(
    !isEditMode,
    {
      id: parseInt(teamId || '0', 10),
      isAthleteActive: true,
    }
  )

  const getFieldValue = (fieldName: string, value: any) => {
    setGenderFranchise({
      ...genderFranchise,
      [fieldName]: value,
    })
  }

  const handleSubmit = async (
    values: AddEditTeamFormValues,
    actions: FormikHelpers<AddEditTeamFormValues>
  ) => {
    const hoursStart = moment(values.startTime?._d).format('YYYY-MM-DD HH:mm:ss')
    const hoursEnd = moment(values.endTime?._d).format('YYYY-MM-DD HH:mm:ss')
    const daysValues = values.days.join(',')
    if (!isEditMode) {
      const result = await addTeam({
        name: values.name,
        franchiseId: values.franchise,
        days: daysValues,
        gender: values.gender,
        startTime: hoursStart,
        endTime: hoursEnd,
        athletesId: checkedIds,
        active: values.active,
      })

      if (result?.error) {
        const [error] = handleErrors(result?.error)
        dispatch(toastDanger(error))
      } else {
        dispatch(toastSuccess('Turma criada com sucesso!'))
        navigate('/geral/turmas')
      }
    } else {
      const result = await updateTeam({
        id: parseInt(teamId || '0', 10),
        name: values.name,
        franchiseId: values.franchise,
        days: daysValues,
        gender: values.gender,
        startTime: hoursStart,
        endTime: hoursEnd,
        athletesId: checkedIds,
        active: values.active,
      })

      if (result?.error) {
        const [error] = handleErrors(result?.error)
        dispatch(toastDanger(error))
      } else {
        dispatch(toastSuccess('Turma atualizada com sucesso!'))
        navigate('/geral/turmas')
      }
    }
    actions.setSubmitting(false)
  }

  const handleAdAthletes = () => navigate(`/geral/turmas/${teamId}/atletas`)

  const isFetching = addTeamResult?.fetching || updateTeamResult?.fetching || isFetchingTeam
  const backButton = { name: 'Voltar', onClick: () => navigate('/geral/turmas') }
  const labelValue = isEditMode ? 'Editar turma' : 'Adicionar turma'

  useEffect(() => {
    if (isEditMode) {
      if (teamError) dispatch(toastDanger('Erro ao buscar turma!'))
      setInitialValues({
        name: teamData?.name,
        franchise: teamData?.franchise?.id,
        days: teamData?.days?.split(','),
        startTime: moment(teamData?.startTime, 'HH:mm'),
        endTime: moment(teamData?.endTime, 'HH:mm'),
        gender: teamData?.gender,
        active: teamData?.active,
      })
    }
  }, [dispatch, isEditMode, teamData, teamError])

  return {
    user,
    isFetching,
    initialValues,
    handleSubmit,
    isEditMode,
    labelValue,
    backButton,
    optionsSelect,
    checkedIds,
    setCheckedIds,
    getFieldValue,
    genderFranchise,
    teamId,
    handleAdAthletes,
  }
}
