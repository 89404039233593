import { useQuery } from 'urql'
import { variablesUseGetAllExpTraining } from './interface'
import { IdQueryVariableType } from '../interface'
import { GET_ALL_EXP_TRAINING, GET_EXP_TRAINING_BY_ID } from '../../queries/expTraining'

export const useGetAllExpTraining = (pause: boolean, variables: variablesUseGetAllExpTraining) => {
  const [getAllExpTrainingResult, reExecute] = useQuery({
    query: GET_ALL_EXP_TRAINING,
    requestPolicy: 'network-only',
    pause,
    variables,
  })

  const isFetching = getAllExpTrainingResult.fetching
  const data = getAllExpTrainingResult.data?.getAllExperimentalTrainings

  return [isFetching, data, reExecute]
}

export const useGetExpTrainingById = (pause: boolean, variables: IdQueryVariableType) => {
  const [getExperimentalTrainingByIdResult] = useQuery({
    query: GET_EXP_TRAINING_BY_ID,
    variables,
    pause,
  })

  const data = getExperimentalTrainingByIdResult.data?.getExperimentalTrainingById
  const { error } = getExperimentalTrainingByIdResult
  const isFetching = getExperimentalTrainingByIdResult.fetching

  return [data, error, isFetching]
}
