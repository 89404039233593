import { FormikHelpers } from 'formik'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from 'urql'
import * as Yup from 'yup'
import jwt_decode, { JwtPayload } from 'jwt-decode'
import { useDispatch } from 'react-redux'
import { toastDanger, toastSuccess } from '../../redux/toast/actions'
import { CREATE_NEW_PASSWORD } from '../../queries/users'
import { CreateNewPasswordFormValues } from '../../@types/createNewPassword'

export const validationCreateNewPassword = Yup.object({
  passwd: Yup.string().required('Campo obrigatório'),
  confirmPassword: Yup.string()
    .min(3, 'Senha muito curta')
    .oneOf([Yup.ref('passwd'), null], 'As senhas não coincidem')
    .required('Campo obrigatório'),
})

export const useCreateNewPassword = () => {
  const [createNewPassResult, createNewPass] = useMutation(CREATE_NEW_PASSWORD)

  const { token } = useParams()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isFetching = createNewPassResult.fetching
  const initialValues: CreateNewPasswordFormValues = { passwd: '', confirmPassword: '' }

  const handleSubmit = async (
    values: CreateNewPasswordFormValues,
    actions: FormikHelpers<CreateNewPasswordFormValues>
  ) => {
    try {
      const response = await createNewPass({ password: values.passwd })
      if (response?.error) dispatch(toastDanger('Erro ao redefinir senha!'))
      else {
        dispatch(toastSuccess('Senha redefinida com sucesso!'))
        navigate('/')
      }
    } catch (error) {
      dispatch(toastDanger('Erro ao redefinir senha!'))
    }
    actions.setSubmitting(false)
  }

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwt_decode<JwtPayload>(token)
        const currentDate = Date.now()
        if (decodedToken?.exp) {
          if (decodedToken?.exp * 1000 < currentDate) navigate('/')

          localStorage.setItem('token', token)
        } else navigate('/')
      } catch (error) {
        navigate('/')
      }
    } else navigate('/')
  }, [navigate, token])

  return {
    initialValues,
    isFetching,
    handleSubmit,
  }
}
