import * as Yup from 'yup'

export const validation = Yup.object({
  name: Yup.string().max(45, 'Nome muito longo').required('Campo obrigatório'),
  email: Yup.string()
    .max(45, 'E-mail muito longo')
    .email('Por favor, insira um e-mail válido')
    .required('Campo obrigatório'),
  franchises: Yup.array().min(1, 'Campo obrigatório'),
  role: Yup.string().required('Campo obrigatório'),
  passwd: Yup.string()
    .min(3, 'Senha muito curta')
    .max(45, 'Senha muito longa')
    .required('Campo obrigatório'),
  confirmPassword: Yup.string()
    .min(3, 'Senha muito curta')
    .oneOf([Yup.ref('passwd'), null], 'As senhas não coincidem')
    .required('Campo obrigatório'),
})
