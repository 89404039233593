import { gql } from 'urql'

export const GET_ALL_ATHLETES = gql`
  query(
    $pageNumber: Int
    $pageSize: Int
    $name: String
    $gender: String
    $sort: String
    $filterFranchisesIds: [Int!]
    $active: Boolean
  ) {
    getAllAthletes(
      input: {
        pageNumber: $pageNumber
        pageSize: $pageSize
        name: $name
        gender: $gender
        sort: $sort
        active: $active
      }
      filterFranchisesIds: $filterFranchisesIds
    ) {
      data {
        id
        name
        email
        cpf
        gender
        active
        user {
          franchises {
            id
            name
          }
        }
        clientParents {
          id
          name
        }
        teams {
          id
          name
        }
      }
      total
    }
  }
`

export const ADD_ATHLETE = gql`
  mutation(
    $name: String!
    $email: String!
    $cpf: String!
    $phone: String!
    $gender: String!
    $birthday: DateTime!
    $teamsId: [Int!]!
    $franchiseId: Int!
    $active: Boolean!
  ) {
    createAthlete(
      input: {
        name: $name
        email: $email
        cpf: $cpf
        phone: $phone
        gender: $gender
        birthday: $birthday
        teamsId: $teamsId
        franchiseId: $franchiseId
        active: $active
      }
    ) {
      id
      name
      email
    }
  }
`

export const UPDATE_ATHLETE = gql`
  mutation(
    $id: Int!
    $name: String
    $email: String
    $cpf: String
    $phone: String
    $gender: String
    $birthday: DateTime
    $teamsId: [Int!]
    $franchiseId: Int
    $active: Boolean!
    $passwd: String
  ) {
    updateAthlete(
      input: {
        id: $id
        name: $name
        email: $email
        cpf: $cpf
        phone: $phone
        gender: $gender
        birthday: $birthday
        teamsId: $teamsId
        franchiseId: $franchiseId
        active: $active
        passwd: $passwd
      }
    ) {
      id
      name
      email
    }
  }
`

export const GET_ATHLETE_BY_ID = gql`
  query($id: Int!) {
    getAthleteById(id: $id) {
      id
      name
      email
      cpf
      phone
      gender
      idNumber
      birthday
      active
      user {
        franchises {
          id
        }
      }
      clientParents {
        id
        name
      }
      teams {
        id
        name
      }
    }
  }
`

export const GET_ALL_ATHLETE_BY_PERFORMANCE_ID = gql`
  query($id: Int!) {
    getAllAthleteByPerformanceId(id: $id) {
      data {
        id
        name
        email
        cpf
      }
      team {
        id
        name
      }
      total
    }
  }
`

export const DELETE_ATHLETE = gql`
  mutation($id: Int!) {
    deleteAthlete(id: $id)
  }
`

export const GET_ALL_ATHLETES_REPORT = gql`
  query($name: String, $gender: String, $active: Boolean, $filterFranchisesIds: [Int!]) {
    getAllAthletesReport(
      name: $name
      gender: $gender
      active: $active
      filterFranchisesIds: $filterFranchisesIds
    ) {
      id
      matricula
      nome
      cpf
      telefone
      email
      categoria
      data_nascimento
      ativo
      unidade
      turmas
      nome_responsavel
    }
  }
`
