import { useMemo } from 'react'
import { Panel, TableComponent, BreadcrumbItem, TableActions, Overlay } from '../../../components'
import { useDeleteUsers, useUsersContainer } from './useUsersContainer'
import { concatTableTexts } from '../../../utils/utils'

function Users() {
  const {
    handleDeleteAdmin,
    handleDeleteAction,
    isOpen,
    setIsOpen,
    deleteOptions,
    isFetchingDelete,
  } = useDeleteUsers()
  const {
    fetching,
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    total,
    panelButtons,
    editUser,
  } = useUsersContainer(isOpen)

  const columns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name',
        width: 150,
      },
      {
        Header: 'Email',
        accessor: 'email',
        width: 150,
      },
      {
        Header: 'Unidades',
        accessor: 'franchise',
        width: 150,
        Cell: (props: any) => {
          const franchises = props.row.original?.franchises
          return concatTableTexts(franchises)
        },
      },
      {
        Header: 'Função',
        accessor: 'role',
        width: 50,
      },
      {
        Header: 'Ações',
        accessor: 'actions',
        width: 50,
        noSort: true,
        Cell: (props: any) => {
          const {
            row: {
              original: { id: userId, name: adminName },
            },
          } = props
          return (
            <TableActions
              actionsList={[
                {
                  type: 'edit',
                  title: 'Editar',
                  handleClick: () => editUser(userId),
                },
                {
                  type: 'delete',
                  title: 'Excluir',
                  handleClick: () => handleDeleteAdmin(userId, adminName),
                },
              ]}
            />
          )
        },
      },
    ],
    [handleDeleteAdmin, editUser]
  )

  const breadcrumbs = [
    <BreadcrumbItem key="geral" text="Geral" link="/geral/administradores" />,
    <BreadcrumbItem key="administradores" text="Administradores" />,
  ]

  return (
    <Panel
      title="Administradores"
      buttons={panelButtons}
      isLoading={fetching || isFetchingDelete}
      breadcrumbs={breadcrumbs}
    >
      <TableComponent
        columns={columns}
        fetching={fetching}
        data={tableData}
        canPreviousPage={currentPage !== 1}
        canNextPage={currentPage !== Math.ceil(total / pageSize)}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={Math.ceil(total / pageSize)}
      />
      <Overlay
        overlayTitle="Excluir administrador"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        buttonText="Confirmar"
        handleClick={handleDeleteAction}
      >
        <p>{`Realmente deseja excluir a administrador ${deleteOptions.name} ?`}</p>
      </Overlay>
    </Panel>
  )
}

export default Users
