import styled from 'styled-components'
import {
  Save as SaveIcon,
  Check as CheckIcon,
  CloudUpload as CloudUploadIcon,
  Sync as SyncIcon,
} from '@mui/icons-material'
import { ButtonSubmitComp, ButtonSubmitProps } from './interface'

const ButtonSubmit = styled.button<ButtonSubmitComp>`
  ${(props) => `
    display: flex;
    align-items: center;
    justify-content: center;
    ${props.isFullSize && 'width: 100%'};
    ${props?.isMarginTop && 'margin-top: 2rem'};
    border: none;
    border-radius: 4px;
    font-size: ${props.theme.fontSizes.font18};
    color: ${props?.isWhite ? props.theme.colors.primary : props.theme.colors.white};
    background: ${props.isWhite ? props.theme.colors.white : props.theme.colors.primary};
    text-decoration: uppercase;
    ${props?.isWhite && `border: 1px solid ${props.theme.colors.primary}`};
    padding: ${props.theme.spacing.spc12} ${props.theme.spacing.spc12};
    cursor: pointer;
    svg {
      font-size: ${props.theme.spacing.spc18};
      margin-right: 0.2rem;
    }
  `}
`

const addButtonIcon = (text: string | JSX.Element) => {
  if (typeof text === 'string') {
    if (text === 'Confirmar') return <CheckIcon />
    if (text === 'Salvar') return <SaveIcon />
    if (text === 'Sincronizar') return <SyncIcon />
    if (text === 'Gerar relatórios') return <CloudUploadIcon />
    return null
  }
  return text
}

const ButtonComponent = ({
  text,
  type,
  onClick,
  isFullSize,
  buttonIcon,
  isWhite,
  isMarginTop,
}: ButtonSubmitProps) => (
  <ButtonSubmit
    type={type ?? 'submit'}
    isWhite={isWhite}
    onClick={onClick}
    isFullSize={isFullSize}
    data-testid="button-component"
    isMarginTop={isMarginTop}
  >
    {buttonIcon && buttonIcon}
    {addButtonIcon(text)}
    {text}
  </ButtonSubmit>
)

export default ButtonComponent
