import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import { isEmpty } from 'lodash'
import { useAppSelector } from '../../../redux/store'
import { AthletesFilterFormValues } from '../../../@types/athlete'
import { exportToExcel, filterFranchisesIds } from '../../../utils/utils'
import { useGetAllAthletes, useGetAllAthletesReport } from '../../../hooks/Athletes/useAthletes'

export const useAthletesContainer = (isOpen: boolean, deleteActivateAthleteFetching: boolean) => {
  const {
    auth: { user },
    globalFilter: { selectedFranchises },
  } = useAppSelector((state) => state)

  const [tableData, setTableData] = useState([] as any)
  const [pageSize, setPageSize] = useState(50)
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false)
  const [filters, setFilters] = useState({ name: '', gender: '', status: '' })
  const [triggerAthletesReport, setTriggerAthletesReport] = useState<boolean>(false)

  const isCoach = user?.role === 'Treinador'
  const isAthlete = user?.role === 'Atleta'
  const filtersObj = {
    ...(filters.name && { name: filters.name }),
    ...(filters.gender && { gender: filters.gender }),
    ...(filters.status && { active: filters.status === 'active' }),
  }

  const { data: allAthletesData, isFetching: isFetchingAllAthletes, reExecute } = useGetAllAthletes(
    isAthlete,
    {
      pageNumber: currentPage,
      pageSize,
      ...(!isEmpty(selectedFranchises) && {
        filterFranchisesIds: filterFranchisesIds(selectedFranchises),
      }),
      ...filtersObj,
    }
  )

  const {
    data: athletesReportData,
    isFetching: isFetchingAthletesReport,
  } = useGetAllAthletesReport(!triggerAthletesReport, {
    ...(!isEmpty(selectedFranchises) && {
      filterFranchisesIds: filterFranchisesIds(selectedFranchises),
    }),
    ...filtersObj,
  })

  const fetching =
    isFetchingAllAthletes || isFetchingAthletesReport || deleteActivateAthleteFetching

  const initialValuesFilter = {
    name: '',
    gender: '',
    status: '',
  }

  const navigate = useNavigate()

  useEffect(() => {
    if (!isFetchingAllAthletes && allAthletesData) {
      const athleteListMapped = allAthletesData?.data?.map((athlete) => ({
        id: athlete?.id,
        name: athlete?.name ?? '',
        clientParents: athlete?.clientParents,
        teams: athlete?.teams,
        franchises: athlete?.user?.franchises,
        gender: athlete?.gender ?? '',
        active: athlete?.active,
      }))
      setTableData(athleteListMapped ?? [])
      setTotal(allAthletesData?.total ?? 0)
    }
  }, [allAthletesData, isFetchingAllAthletes])

  useEffect(() => {
    if (!isOpen) reExecute({ requestPolicy: 'network-only' })
  }, [reExecute, isOpen])

  useEffect(() => {
    if (!isFetchingAthletesReport && athletesReportData) {
      const athletesReportList = athletesReportData ?? []
      exportToExcel(athletesReportList, 'atletas')
      setTriggerAthletesReport(false)
    }
  }, [athletesReportData, isFetchingAthletesReport])

  const addAthlete = () => navigate(`/geral/atletas/addAthlete`)
  const editAthlete = (athleteId: string) => navigate(`/geral/atletas/${athleteId}`)

  const handleSubmitFilter = (
    values: AthletesFilterFormValues,
    actions: FormikHelpers<AthletesFilterFormValues>
  ) => {
    setFilters({
      name: values.name,
      gender: values.gender,
      status: values.status,
    })
    actions.setSubmitting(false)
  }

  const downloadAthletes = () => setTriggerAthletesReport(true)
  const openCloseFilter = () => setIsOpenFilter(!isOpenFilter)

  return {
    fetching,
    user,
    tableData,
    pageSize,
    currentPage,
    setPageSize,
    setCurrentPage,
    total,
    addAthlete,
    editAthlete,
    initialValuesFilter,
    handleSubmitFilter,
    isOpenFilter,
    openCloseFilter,
    downloadAthletes,
    isCoach,
    setIsOpenFilter,
  }
}
