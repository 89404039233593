import styled from 'styled-components'
import Logo from '../../assets/logoID.png'
import { VERSION } from '../../utils/constants'
import Wallpaper from '../../assets/wallpaper.png'
import WallpaperHome from '../../assets/wallpaper-home.png'

const PageWrapper = styled.div`
  ${({ theme }) => `
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url(${Wallpaper}), url(${WallpaperHome}),
    linear-gradient(
      110deg,
      ${theme?.colors?.middleGreen} 40%,
      ${theme?.colors?.primary} 60%
    );
  background-repeat: no-repeat;
  background-position: left, right, center;
  background-size: contain;
  @media screen and (max-width: 769px) {
    background-image: linear-gradient(
      110deg,
      ${theme?.colors?.middleGreen} 40%,
      ${theme?.colors?.primary} 60%
    );
    background-position: center;
  }
  `}
`

const SignInWrapper = styled.div`
  ${({ theme }) => `
    box-sizing: border-box;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: ${theme.spacing.spc30} ${theme.spacing.spc30};
    border-radius: 5px;
    opacity: 0.85;
    background-color: ${theme.colors.white};
    @media screen and (max-width: ${theme.screens.md}) {
      width: 100%;
      padding: ${theme.spacing.spc30} ${theme.spacing.spc10};
    }
  `}
`

const IdLogoImage = styled.img`
  width: 12rem;
`

const SignInTitle = styled.h1`
  ${({ theme }) => `
    text-align: center;
    font-size: ${theme.fontSizes.font26};
    color: ${theme.colors.primary};
    @media screen and (max-width: ${theme.screens.md}) {
      font-size: ${theme.fontSizes.font24};
    }
  `}
`
const ButtonForgetPassword = styled.button`
  ${({ theme }) => `
    border: none;
    margin-top: ${theme.spacing.spc30};
    font-size: ${theme.fontSizes.font16};
    color: ${theme.colors.white};
    background: none;
    cursor: pointer;
    text-decoration: underline;
  `}
`

const VersionWrapper = styled.span`
  ${({ theme }) => `
    position: absolute;
    bottom: 15px;
    right: 15px;
    color: ${theme.colors.white};
    font-weight: 600;
    font-style: italic;
    font-size: ${theme.fontSizes.font14};
  `}
`

interface HomePageProps {
  children: JSX.Element | JSX.Element[]
  handleClickLink?(): void
  linkText?: string
}

function HomePage({ children, handleClickLink, linkText }: Readonly<HomePageProps>) {
  return (
    <PageWrapper>
      <SignInWrapper>
        <SignInTitle>Portal Administrativo e Técnico</SignInTitle>
        <IdLogoImage src={Logo} alt="ID Sports" />
        {children}
      </SignInWrapper>
      {handleClickLink && linkText && (
        <ButtonForgetPassword type="button" onClick={handleClickLink}>
          {linkText}
        </ButtonForgetPassword>
      )}
      <VersionWrapper data-testid="version">{VERSION}</VersionWrapper>
    </PageWrapper>
  )
}

export default HomePage
