import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const MobileBarMenu = styled.ul`
  ${({ theme }) => `
    display: flex;
    width: 100%;
    position: fixed;
    margin: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background: ${theme.colors.white};
    padding: ${theme.spacing.spc8} ${theme.spacing.spc10};
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    @media screen and (min-width: ${theme.screens.md}) {
      display: none;
    }
  `}
`

const ListItem = styled.li<{ isActive?: boolean }>`
  ${(props) => `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    background: ${props.theme.colors.white};
    margin-right: ${props.theme.spacing.spc10};
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    svg {
      font-size: 1.5rem;
      color: ${props?.isActive ? props.theme.colors.lightGreen : props.theme.colors.primary};
    }
  `}
`

interface MobileNavBarProps {
  navBarItems: {
    icon: IconDefinition
    route: string
  }[]
}

export default function MobileNavBar({ navBarItems }: Readonly<MobileNavBarProps>) {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <MobileBarMenu>
      {navBarItems.map((item, index) => (
        <ListItem
          key={`navbar-item-${index}`}
          onClick={() => navigate(item.route)}
          isActive={item.route === location.pathname}
        >
          <FontAwesomeIcon icon={item.icon} />
        </ListItem>
      ))}
    </MobileBarMenu>
  )
}
