import styled from 'styled-components'
import { Formik, Form, FormikProps } from 'formik'
import {
  ButtonComponent,
  InputComponent,
  SelectComponent,
  SliderComponent,
  TextAreaComponent,
} from '../../components'
import { OtherProps } from '../../@types'
import { validation } from './validation'
import {
  AddEditPerformanceFormValues,
  AddEditPhysicalPerformanceAthleteFormProps,
} from '../../@types/physicalPerformance'
import { OPTIONS_BEEP } from '../../utils/constants'

const FormContainer = styled(Form)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`

const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`

const AddEditPhysicalPerformanceAthleteForm = ({
  handleSubmit,
  initialValues,
  athletesList,
  teamName,
}: AddEditPhysicalPerformanceAthleteFormProps) => (
  <Formik
    initialValues={initialValues}
    onSubmit={(values, actions) => handleSubmit(values, actions)}
    validationSchema={validation}
    enableReinitialize
  >
    {(props: OtherProps & FormikProps<AddEditPerformanceFormValues>) => (
      <FormContainer>
        <InputComponent
          name="team"
          label="Turma *:"
          value={teamName}
          props={props}
          width="450px"
          disabled
        />
        <SelectComponent
          name="athleteId"
          label="Atleta *:"
          placeholderText="Selecione um atleta..."
          options={athletesList}
          value={props.values.athleteId}
          props={props}
          width="450px"
          isMulti={false}
        />
        <InputComponent
          name="speed"
          label="Velocidade (segundos) *:"
          value={props.values.speed}
          props={props}
          width="300px"
        />
        <InputComponent
          name="impulse"
          label="Salto (metros) *:"
          value={props.values.impulse}
          props={props}
          width="300px"
        />
        <SelectComponent
          name="beep"
          label="Beep (nível) *:"
          placeholderText="Escolha um nível..."
          options={OPTIONS_BEEP}
          value={props.values.beep}
          props={props}
          width="300px"
        />
        <SliderComponent
          width="250px"
          properties={props}
          fieldName="communication"
          label="Comportamento (1-5) *:"
        />
        <SliderComponent
          width="250px"
          properties={props}
          fieldName="technical"
          label="Técnico (1-5) *:"
        />
        <SliderComponent
          width="250px"
          properties={props}
          fieldName="tatical"
          label="Tático (1-5) *:"
        />
        <SliderComponent
          width="250px"
          properties={props}
          fieldName="physical"
          label="Físico (1-5) *:"
        />
        <TextAreaComponent
          name="positivePoints"
          label="Pontos positivos *:"
          value={props.values.positivePoints}
          props={props}
          width="250px"
          limitCharacters={550}
        />
        <TextAreaComponent
          name="negativePoints"
          label="Pontos à evoluir *:"
          value={props.values.negativePoints}
          props={props}
          width="250px"
          limitCharacters={550}
        />
        <ButtonWrapper>
          <ButtonComponent text="Salvar" />
        </ButtonWrapper>
      </FormContainer>
    )}
  </Formik>
)

export default AddEditPhysicalPerformanceAthleteForm
