import jwt_decode from 'jwt-decode'
import { ActionsAuthTypes } from '../types'
import {
  AthleteProps,
  AuthStateInterface,
  CoachProps,
  FranchiseProps,
  UserProps,
} from '../types/auth'

export const getTokenInfo = (): AuthStateInterface => {
  const token = localStorage.getItem('token')
  if (token) {
    const decoded: AuthStateInterface = jwt_decode(token)
    localStorage.setItem('token', token)

    return {
      user: decoded?.user,
      franchises: decoded?.franchises ?? [],
      coach: decoded?.coach,
      athlete: decoded?.athlete,
      iat: decoded?.iat ?? 0,
      exp: decoded?.exp ?? 0,
    }
  }
  return {
    user: {} as UserProps,
    franchises: [] as FranchiseProps[],
    coach: {} as CoachProps,
    athlete: {} as AthleteProps,
    iat: 0,
    exp: 0,
  }
}

const initialState: AuthStateInterface = getTokenInfo()

const authReducer = (state = initialState, action: ActionsAuthTypes): AuthStateInterface => {
  switch (action.type) {
    case 'LOGIN':
      return {
        user: action?.payload?.user,
        franchises: action?.payload?.franchises,
        coach: action?.payload?.coach,
        athlete: action?.payload?.athlete,
        exp: action?.payload?.exp,
        iat: action?.payload?.iat,
      }
    case 'LOGOUT':
      return {
        user: {} as UserProps,
        franchises: [] as FranchiseProps[],
        coach: {} as CoachProps,
        athlete: {} as AthleteProps,
        exp: 0,
        iat: 0,
      }
    default:
      return {
        ...state,
      }
  }
}

export default authReducer
