import * as Yup from 'yup'

export const validation = Yup.object({
  athleteId: Yup.number().required('Campo obrigatório'),
  speed: Yup.number()
    .typeError('Insira um número Ex: 9.99')
    .min(0, 'O valor deve ser maior ou igual zero')
    .required('Campo obrigatório'),
  impulse: Yup.number()
    .typeError('Insira um número Ex: 9.99')
    .min(0, 'O valor deve ser maior ou igual zero')
    .required('Campo obrigatório'),
  beep: Yup.string().required('Campo obrigatório'),
  communication: Yup.number().min(1, 'Deve ser maior do que zero').required('Campo obrigatório'),
  technical: Yup.number().min(1, 'Deve ser maior do que zero').required('Campo obrigatório'),
  tatical: Yup.number().min(1, 'Deve ser maior do que zero').required('Campo obrigatório'),
  physical: Yup.number().min(1, 'Deve ser maior do que zero').required('Campo obrigatório'),
  positivePoints: Yup.string()
    .min(1, 'Texto muito curto')
    .max(551, 'Texto muito longo(max:550 caracteres)')
    .required('Campo obrigatório'),
  negativePoints: Yup.string()
    .min(1, 'Texto muito curto')
    .max(551, 'Texto muito longo(max:550 caracteres)')
    .required('Campo obrigatório'),
})
