import { useMemo } from 'react'
import { Panel, TableComponent, BreadcrumbItem, TableActions, Overlay } from '../../../components'
import {
  useDeletePhysicalPerformance,
  usePhysicalPerformanceContainer,
} from './usePhysicalPerformanceContainer'

function PhysicalPerformance() {
  const {
    handleDeleteAction,
    handleDeletePhysicalPerformance,
    isOpen,
    setIsOpen,
    deleteOptions,
    isFetchingDelete,
  } = useDeletePhysicalPerformance()
  const {
    isFetching,
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    total,
    panelButtons,
    detailsPhysicalPerformance,
  } = usePhysicalPerformanceContainer(isOpen)

  const columns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name',
        width: 150,
      },
      {
        Header: 'Unidade',
        accessor: 'franchise',
        width: 150,
      },
      {
        Header: 'Avaliador',
        accessor: 'user',
        width: 150,
      },
      {
        Header: 'Turma',
        accessor: 'team',
        width: 80,
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 80,
      },
      {
        Header: 'Criação',
        accessor: 'createdAt',
        width: 140,
      },
      {
        Header: 'Atualização',
        accessor: 'updatedAt',
        width: 140,
      },
      {
        Header: 'Ações',
        accessor: 'actions',
        width: 60,
        noSort: true,
        Cell: (props: any) => {
          const {
            row: {
              original: { id, status, name },
            },
          } = props
          return (
            <TableActions
              actionsList={[
                {
                  type: status === 'Fechada' ? 'view' : 'edit',
                  title: status === 'Fechada' ? 'Visualizar' : 'Editar',
                  handleClick: () => detailsPhysicalPerformance(id),
                },
                {
                  type: 'delete',
                  title: 'Excluir',
                  handleClick: () => handleDeletePhysicalPerformance(id, name),
                },
              ]}
            />
          )
        },
      },
    ],
    [handleDeletePhysicalPerformance, detailsPhysicalPerformance]
  )

  const breadcrumbs = [
    <BreadcrumbItem key="tecnico" text="Técnico" link="/tecnico/avaliacoes" />,
    <BreadcrumbItem key="avaliacoes" text="Avaliações" />,
  ]

  return (
    <Panel
      title="Avaliações"
      buttons={panelButtons}
      isLoading={isFetching || isFetchingDelete}
      breadcrumbs={breadcrumbs}
    >
      <TableComponent
        columns={columns}
        data={tableData}
        fetching={isFetching}
        canPreviousPage={currentPage !== 1}
        canNextPage={currentPage !== Math.ceil(total / pageSize)}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={Math.ceil(total / pageSize)}
      />
      <Overlay
        overlayTitle="Excluir avaliação física"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        buttonText="Confirmar"
        handleClick={handleDeleteAction}
      >
        <p>{`Realmente deseja excluir o avaliação ${deleteOptions.name} ?`}</p>
      </Overlay>
    </Panel>
  )
}

export default PhysicalPerformance
