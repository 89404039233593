import styled from 'styled-components'
import { useDashboardLayout } from '../../hooks/useDashboardLayout'
import LogoId from '../../assets/logoID.png'
import { ListItemProps, MainMenuItemIT, MenuItemIT } from '../../@types/menu'
import { ChildrenProps } from '../../@types'
import { useMenuList } from '../../hooks/useMenuList'
import GlobalFilter from './GlobalFilter'
import { ProfileSelector } from '..'
import MobileNavBar from './MobileNavBar'

const SidebarDesktop = styled('div')`
  height: 100%;
  overflow-x: hidden;
  align-items: center;
  justify-content: center;
  width: 250px;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background: ${(props) => props?.theme?.colors?.white};
  border-right: 1px solid ${(props) => props?.theme?.colors.grey};
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const TopBarWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${(props) => props?.theme?.spacing?.spc20};
  padding-right: ${(props) => props?.theme?.spacing?.spc20};
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 20px;
  left: 0;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const LogoWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px ${(props) => props?.theme?.spacing?.spc25};
`

const LogoLogin = styled('img')`
  width: 10rem;
  @media screen and (max-width: 768px) {
    width: 8rem;
    margin-top: 0.05rem;
  }
`

const MainMenuList = styled('ul')`
  list-style: none;
  padding: 0px ${(props) => props?.theme?.spacing?.spc12};
  margin: 0;
  margin-top: ${(props) => props?.theme?.spacing?.spc10};
`

const List = styled('ul')`
  list-style: none;
  padding: 0px ${(props) => props?.theme?.spacing?.spc10};
  margin: 0;
  margin-bottom: ${(props) => props?.theme?.spacing?.spc10};
`

const ListItemText = styled('span')`
  display: flex;
  align-items: center;
  svg {
    margin-right: ${(props) => props?.theme?.spacing?.spc20};
    font-size: ${(props) => props?.theme?.fontSizes?.font18};
  }
`

const MainListItem = styled('li')`
  color: #6d6d6d;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: ${(props) => props?.theme?.spacing?.spc10} ${(props) => props?.theme?.spacing?.spc20};
  cursor: pointer;
  border-radius: ${(props) => props?.theme?.spacing?.spc8};
  font-style: italic;
  font-family: Arial, Helvetica, sans-serif;
  font-size: ${(props) => props?.theme?.fontSizes?.font16};
`

const ListItem = styled('li')<Pick<ListItemProps, 'isActive'>>`
  color: ${(props) => props?.theme?.colors?.softBlack};
  display: flex;
  align-items: center;
  padding: ${(props) => props?.theme?.spacing?.spc10} ${(props) => props?.theme?.spacing?.spc20};
  cursor: pointer;
  border-radius: ${(props) => props?.theme?.spacing?.spc6};
  span {
    font-family: Arial, Helvetica, sans-serif;
    font-size: ${(props) => props?.theme?.fontSizes?.font18};
  }
  svg {
    color: ${(props) => props?.theme.colors.softBlack};
    font-size: ${(props) => props?.theme?.fontSizes?.font22};
  }
  ${(props) =>
    props?.isActive
      ? `
            color: ${props?.theme?.colors?.white};
            font-weight: bold;
            background: ${props?.theme?.colors?.primary};
            svg{
                color: ${props?.theme?.colors?.white};
            }
            `
      : ``}
  &:hover {
    color: ${(props) => props?.theme?.colors?.primary};
    font-weight: bold;
    background: rgb(198, 198, 198, 0.2);
    svg {
      color: ${(props) => props?.theme?.colors?.primary};
    }
  }
`

const MainContent = styled.div<{
  noPaddingTop?: boolean
}>`
  ${(props) => `
    margin-left: 250px;
    padding: ${props?.noPaddingTop ? '0px 16px 0px 16px' : '60px 16px 0px 16px'};
    margin-bottom: ${props?.theme?.spacing?.spc45};
    @media screen and (max-width: 768px) {
      margin-left: 0px;
      margin-top: ${props?.theme?.spacing?.spc40};
    }
  `}
`

function DashboardLayout({ children }: Readonly<ChildrenProps>) {
  const { mainMenuList, subMenuList, navBarItems } = useMenuList()
  const {
    user,
    pathname,
    handleClickMenu,
    handleLogout,
    showTopBar,
    userFirstName,
    businessName,
    isMobileScreen,
  } = useDashboardLayout()

  const menuRender = () => (
    <div style={{ minWidth: '280px' }}>
      <LogoWrapper>
        <LogoLogin src={LogoId} alt="ID Sports" />
      </LogoWrapper>
      <MainMenuList>
        {mainMenuList.map((menuItem: MainMenuItemIT) =>
          menuItem.permissions.includes(user?.role) ? (
            <div key={menuItem.name}>
              <MainListItem>{menuItem.name}</MainListItem>
              <List>
                {subMenuList.map((subMenuItem: MenuItemIT, index: number) =>
                  subMenuItem.permissions.includes(user?.role) &&
                  menuItem.name === subMenuItem.place ? (
                    <ListItem
                      key={`sub-menu-${index}`}
                      onClick={() => handleClickMenu(subMenuItem.link)}
                      isActive={subMenuItem.link.split('/')[2] === pathname.split('/')[2]}
                    >
                      <ListItemText>
                        {subMenuItem.icon}
                        {subMenuItem.name}
                      </ListItemText>
                    </ListItem>
                  ) : null
                )}
              </List>
            </div>
          ) : null
        )}
      </MainMenuList>
    </div>
  )

  return (
    <>
      {showTopBar && (
        <TopBarWrapper>
          <GlobalFilter />
          {!isMobileScreen && (
            <ProfileSelector
              name={userFirstName}
              businessName={businessName}
              handleLogout={handleLogout}
            />
          )}
        </TopBarWrapper>
      )}

      <SidebarDesktop>{menuRender()}</SidebarDesktop>

      <MainContent noPaddingTop={!showTopBar}>{children}</MainContent>

      <MobileNavBar navBarItems={navBarItems} />
    </>
  )
}

export default DashboardLayout
