import { Page, PDFViewer, Document } from '@react-pdf/renderer'
import styled from 'styled-components'
import { Alert } from '@mui/material'
import { BreadcrumbItem, Panel } from '../../../../components'
import PdfReport from './PdfReport'
import { useReportAttendanceContainer } from './useReportAttendanceContainer'
import { useFranchisesCached } from '../../../../hooks/Franchises/useFranchises'
import ReportAttendanceListForm from '../../../../Forms/ReportAttendanceListForm/ReportAttendanceListForm'

const BodyContent = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props?.theme?.spacing?.spc40};
  iframe {
    height: 800px;
  }
`

const breadcrumbs = [
  <BreadcrumbItem key="tecnico" text="Técnico" link="/tecnico/chamadas" />,
  <BreadcrumbItem key="chamadas" text="Chamadas" link="/tecnico/chamadas" />,
  <BreadcrumbItem key="controlePresenca" text="Controle de presença mensal" />,
]

function ReportAttendance() {
  const {
    isFetching,
    initialValues,
    stateReport,
    handleSubmit,
    dataTeams,
    backButton,
    shouldDisplayPreview,
  } = useReportAttendanceContainer()
  const { optionsSelect } = useFranchisesCached()

  return (
    <Panel
      title="Relatorio - Controle de presença mensal"
      isLoading={isFetching}
      breadcrumbs={breadcrumbs}
      backButton={backButton}
    >
      <ReportAttendanceListForm
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        optionsFranchises={optionsSelect}
        optionsTeams={dataTeams}
      />
      {shouldDisplayPreview ? (
        <BodyContent>
          <PDFViewer key="controle-de-presenca">
            <Document>
              <Page
                size="A4"
                style={{
                  padding: 30,
                  width: '100%',
                  backgroundColor: '#fff',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                wrap
              >
                <PdfReport
                  period={stateReport?.period ?? ''}
                  franchiseName={stateReport?.franchiseName ?? ''}
                  ownerName={stateReport?.ownerName ?? ''}
                  teamName={stateReport?.teamName ?? ''}
                  reportsInfo={stateReport?.reportItems?.slice(0, 30) ?? []}
                />
                {stateReport?.reportItems?.length > 30 && (
                  <PdfReport
                    period={stateReport?.period ?? ''}
                    franchiseName={stateReport?.franchiseName ?? ''}
                    ownerName={stateReport?.ownerName ?? ''}
                    teamName={stateReport?.teamName ?? ''}
                    reportsInfo={
                      stateReport?.reportItems?.slice(30, stateReport?.reportItems?.length ?? 0) ??
                      []
                    }
                    noDisplayHeader
                  />
                )}
              </Page>
            </Document>
          </PDFViewer>
        </BodyContent>
      ) : (
        <Alert
          severity="warning"
          style={{
            width: '100%',
            boxSizing: 'border-box',
            marginTop: '1rem',
            fontSize: '1rem',
          }}
        >
          Sem resultados diponíveis... Pesquise para visualizar o relatório!
        </Alert>
      )}
    </Panel>
  )
}

export default ReportAttendance
