import styled from 'styled-components'
import InputMask from 'react-input-mask'
import { Tooltip } from '@mui/material'
import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material'
import { InputComponentProps, WrapperProps, InputWrapperProps } from './interface'

const Wrapper = styled.div<WrapperProps>`
  ${(props) => `
    display: flex;
    flex-direction: column;
    flex: ${props?.disableGrow ? 0 : 1} 1
      ${props?.width ? props?.width : '200px'};
    height: 5rem;
    @media screen and (max-width: 769px) {
      width: 100%;
    }
    margin-top: ${props.theme.spacing.spc10};
    label {
      font-size: ${props.theme.fontSizes.font18};
      font-weight: 600;
      color: ${props.theme.colors.primary};
    }
    @media screen and (max-width: ${props.theme.screens.md}) {
      label {
        font-size: ${props.theme.fontSizes.font22};
      }
    }
  `}
`
const InputWrapper = styled.div<InputWrapperProps>`
  ${(props) => `
    display: flex;
    align-items: center;
    background-color: ${props.theme.colors.white};
    padding: ${props.theme.spacing.spc8} ${props.theme.spacing.spc8};
    border-radius: 4px;
    border: 1px solid ${props?.isError ? props.theme.colors.red : props.theme.colors.primary};
    margin-top: ${props.theme.spacing.spc8};
    input {
      width: 100%;
      font-size: ${props.theme.fontSizes.font22};
      color: ${props.theme.colors.black};
      border: none;
      outline: none;
      background-color: ${props.theme.colors.white};
    }
    @media screen and (max-width: ${props.theme.screens.md}) {
      input {
        font-size: ${props.theme.fontSizes.font27};
      }
    }
    input: disabled {
      background-color: #f7f7f7;
      color: #aaa;
    }
    svg {
      cursor: pointer;
      color: ${props.theme.colors.white};
      background: ${props.isError ? props.theme.colors.red : props.theme.colors.primary};
      border-radius: 50%;
      font-size: ${props.theme.fontSizes.font16};
    }
  `}
`

const ErrorWrapper = styled.div`
  ${(props) => `
    color: ${props.theme.colors.red};
    font-size: ${props.theme.fontSizes.font14};
    margin-top: ${props.theme.spacing.spc6};

    @media screen and (max-width: ${props.theme.screens.md}) {
      margin-top: ${props.theme.spacing.spc4};
      font-size: ${props.theme.fontSizes.font16};
    }
  `}
`

function InputComponent({
  placeholder,
  value,
  setValue,
  props,
  label,
  name,
  disabled,
  isText,
  mask,
  fieldType,
  width,
  disableGrow,
}: Readonly<InputComponentProps>) {
  const handleClear = () => {
    if (setValue) setValue('')
    if (props?.setFieldValue) props?.setFieldValue(name, '')
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setValue) setValue(e.target.value)
    if (props?.setFieldValue) props?.setFieldValue(name, e.target.value)
  }

  return (
    <Wrapper width={width} disableGrow={disableGrow} data-testid="input-component">
      {label && <label htmlFor={name}>{label}</label>}
      {isText && <p>{value}</p>}
      {!isText && (
        <InputWrapper isError={!!props?.errors[name]}>
          {mask ? (
            <InputMask
              id={`input-id-${name}`}
              data-testid="input-component-mask"
              name={name}
              mask={mask}
              value={value}
              placeholder={placeholder ?? ''}
              onChange={handleOnChange}
              disabled={disabled}
            />
          ) : (
            <input
              id={`input-id-${name}`}
              name={name}
              type={fieldType ?? 'text'}
              value={value}
              placeholder={placeholder ?? ''}
              onChange={handleOnChange}
              disabled={disabled}
            />
          )}
          {value && !disabled && (
            <Tooltip key={`input-component-${name?.trim()}`} title="Limpar">
              <CloseRoundedIcon onClick={handleClear} />
            </Tooltip>
          )}
        </InputWrapper>
      )}
      {props?.touched && props?.errors && props?.touched[name] && props?.errors[name] && (
        <ErrorWrapper>{props?.errors[name]}</ErrorWrapper>
      )}
    </Wrapper>
  )
}

export default InputComponent
