import styled from 'styled-components'
import { Formik, Form, FormikProps } from 'formik'
import {
  ButtonComponent,
  DatePickerComponent,
  InputComponent,
  SelectComponent,
  ToggleSwitch,
} from '../../components'
import { validation, validationAthleteUser } from './validation'
import { OtherProps } from '../../@types'
import { AddEditAthleteFormProps, AddEditAthleteFormValues } from '../../@types/athlete'
import { OPTIONS_GENDER } from '../../utils/constants'
import { TeamPublic } from '../../@types/responses'

const FormContainer = styled(Form)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`

const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const AddEditAthleteForm = ({
  handleSubmit,
  initialValues,
  optionsFranchises,
  isAthlete,
  isAddMode,
  dataTeams,
}: AddEditAthleteFormProps) => {
  const teamsOptions = (franchiseId: number | string) =>
    dataTeams?.data
      ?.filter((team: TeamPublic) => team?.franchise?.id === franchiseId)
      ?.map((team: TeamPublic) => ({ label: team?.name, value: team?.id })) ?? []

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      validationSchema={!isAthlete ? validation : validationAthleteUser}
      enableReinitialize
    >
      {(props: OtherProps & FormikProps<AddEditAthleteFormValues>) => (
        <FormContainer>
          {!isAddMode && (
            <InputComponent
              name="idNumber"
              label="Matrícula *:"
              value={props.values.idNumber}
              props={props}
              width="100px"
              isText
            />
          )}
          <InputComponent
            name="name"
            label="Nome *:"
            value={props.values.name}
            props={props}
            width="350px"
          />
          <InputComponent
            name="email"
            label="E-mail *:"
            value={props.values.email}
            props={props}
            width="350px"
          />
          <SelectComponent
            name="franchise"
            label="Unidade:"
            placeholderText="Selecione uma unidade..."
            options={optionsFranchises}
            value={props.values.franchise}
            disabled={isAthlete}
            props={props}
            width="300px"
            isMulti={false}
          />
          <InputComponent
            name="cpf"
            label="CPF *:"
            isText={false}
            value={props.values.cpf}
            mask="999.999.999-99"
            props={props}
            width="300px"
          />
          <InputComponent
            name="phone"
            label="Telefone *:"
            value={props.values.phone}
            mask="+55(99)99999-9999"
            props={props}
            width="250px"
          />
          <SelectComponent
            name="gender"
            label="Categoria *:"
            placeholderText="Selecione uma categoria..."
            options={OPTIONS_GENDER}
            disabled={isAthlete}
            value={props.values.gender}
            props={props}
            width="300px"
            isMulti={false}
          />
          <DatePickerComponent
            name="birthday"
            label="Data de nascimento *:"
            value={props.values.birthday}
            props={props}
            width="200px"
          />
          <SelectComponent
            name="teams"
            label="Turmas *:"
            placeholderText={isAthlete ? '' : 'Selecione as turmas..'}
            options={teamsOptions(props.values.franchise)}
            value={props.values.teams}
            props={props}
            disabled={isAthlete}
            width="300px"
            isMulti
          />
          {isAthlete && (
            <>
              <InputComponent
                name="passwd"
                label="Senha *:"
                value={props.values?.passwd}
                props={props}
                width="250px"
                fieldType="password"
              />
              <InputComponent
                name="confirmPassword"
                label="Confirmar senha *:"
                value={props.values?.confirmPassword ?? ''}
                props={props}
                width="250px"
                fieldType="password"
              />
            </>
          )}
          <ToggleSwitch
            checked={props.values.active ?? false}
            setFieldValue={props.setFieldValue}
            name="active"
            label="Ativo *:"
            width="100%"
          />
          <ButtonWrapper>
            <ButtonComponent text="Salvar" />
          </ButtonWrapper>
        </FormContainer>
      )}
    </Formik>
  )
}

export default AddEditAthleteForm
