import { useQuery } from 'urql'
import {
  getAllAthleteByPerformanceId,
  getAllAthleteRatingByAthleteId,
  getAllAthleteRatings,
  getAllPhysicalPerformances,
  getAthleteRatingById,
  getAthleteRatingByIdReport,
  getPhysicalPerformanceById,
  getTeamInfoOnPhysicalPerformance,
} from '../../@types/responses'
import {
  GET_ALL_PHYSICAL_PERFORMANCES,
  GET_PHYSICAL_PERFORMANCE_BY_ID,
  GET_TEAM_INFO_ON_PHYSICAL_PERFORMANCE,
} from '../../queries/physicalPerformance'
import {
  GetPhysicalPerformanceQueryTypes,
  useGetAllAthleteRatingByAthleteIdQueryTypes,
} from './interface'
import {
  GET_ALL_ATHLETE_RATING,
  GET_ALL_ATHLETE_RATING_BY_ATHLETE_ID,
  GET_ATHLETE_RATING_BY_ID,
  GET_ATHLETE_RATING_BY_ID_REPORT,
} from '../../queries/atlheteRating'
import { GET_ALL_ATHLETE_BY_PERFORMANCE_ID } from '../../queries/athletes'
import { IdQueryVariableType } from '../interface'

export const usePhysicalPerformanceQuery = (
  pause: boolean,
  variables: GetPhysicalPerformanceQueryTypes
) => {
  const [getAllPhysicalPerformanceResult, reExecute] = useQuery({
    query: GET_ALL_PHYSICAL_PERFORMANCES,
    variables,
    pause,
    requestPolicy: 'network-only',
  })

  const data: getAllPhysicalPerformances =
    getAllPhysicalPerformanceResult.data?.getAllPhysicalPerformances
  const isFetching: boolean = getAllPhysicalPerformanceResult.fetching
  const { error } = getAllPhysicalPerformanceResult

  return { data, error, isFetching, reExecute }
}

export const usePhysicalPerformanceByIdQuery = (pause: boolean, variables: IdQueryVariableType) => {
  const [getPhysicalPerformanceByIdResult, reExecute] = useQuery({
    query: GET_PHYSICAL_PERFORMANCE_BY_ID,
    variables,
    pause,
    requestPolicy: 'network-only',
  })

  const data: getPhysicalPerformanceById =
    getPhysicalPerformanceByIdResult.data?.getPhysicalPerformanceById
  const isFetching: boolean = getPhysicalPerformanceByIdResult.fetching
  const { error } = getPhysicalPerformanceByIdResult

  return { data, error, isFetching, reExecute }
}

export const useGetAllAthleteRatingsQuery = (pause: boolean, variables: IdQueryVariableType) => {
  const [getAllAthleteRatingsQuery, reExecute] = useQuery({
    query: GET_ALL_ATHLETE_RATING,
    variables,
    pause,
    requestPolicy: 'network-only',
  })

  const data: getAllAthleteRatings = getAllAthleteRatingsQuery.data?.getAllAthleteRatings
  const isFetching: boolean = getAllAthleteRatingsQuery.fetching
  const { error } = getAllAthleteRatingsQuery

  return { data, error, isFetching, reExecute }
}

export const useGetAllAthleteRatingByAthleteIdQuery = (
  pause: boolean,
  variables: useGetAllAthleteRatingByAthleteIdQueryTypes
) => {
  const [getAllAthleteRatingByAthleteIdResult, reExecute] = useQuery({
    query: GET_ALL_ATHLETE_RATING_BY_ATHLETE_ID,
    variables,
    pause,
    requestPolicy: 'network-only',
  })

  const data: getAllAthleteRatingByAthleteId =
    getAllAthleteRatingByAthleteIdResult.data?.getAllAthleteRatingByAthleteId
  const isFetching: boolean = getAllAthleteRatingByAthleteIdResult.fetching
  const { error } = getAllAthleteRatingByAthleteIdResult

  return { data, error, isFetching, reExecute }
}

export const useGetAllAthleteByPerformanceIdQuery = (
  pause: boolean,
  variables: IdQueryVariableType
) => {
  const [getAllAthleteByPerformanceIdResult, reExecute] = useQuery({
    query: GET_ALL_ATHLETE_BY_PERFORMANCE_ID,
    pause,
    variables,
    requestPolicy: 'network-only',
  })

  const data: getAllAthleteByPerformanceId =
    getAllAthleteByPerformanceIdResult.data?.getAllAthleteByPerformanceId
  const isFetching: boolean = getAllAthleteByPerformanceIdResult.fetching
  const { error } = getAllAthleteByPerformanceIdResult

  return { data, error, isFetching, reExecute }
}

export const useGetAthleteRatingByIdQuery = (pause: boolean, variables: IdQueryVariableType) => {
  const [getAthleteRatingByIdResult, reExecute] = useQuery({
    query: GET_ATHLETE_RATING_BY_ID,
    variables,
    requestPolicy: 'network-only',
    pause,
  })

  const data: getAthleteRatingById = getAthleteRatingByIdResult.data?.getAthleteRatingById
  const isFetching: boolean = getAthleteRatingByIdResult.fetching
  const { error } = getAthleteRatingByIdResult

  return { data, error, isFetching, reExecute }
}

export const useGetAllAthleteRatingByIdReportQuery = (
  pause: boolean,
  variables: IdQueryVariableType
) => {
  const [getAthleteRatingByIdReportResult, reExecute] = useQuery({
    query: GET_ATHLETE_RATING_BY_ID_REPORT,
    variables,
    pause,
    requestPolicy: 'network-only',
  })

  const data: getAthleteRatingByIdReport =
    getAthleteRatingByIdReportResult.data?.getAthleteRatingByIdReport
  const isFetching: boolean = getAthleteRatingByIdReportResult.fetching
  const { error } = getAthleteRatingByIdReportResult

  return { data, error, isFetching, reExecute }
}

export const useGetTeamInfoOnPhysicalPerformanceQuery = (
  pause: boolean,
  variables: IdQueryVariableType
) => {
  const [getTeamInfoOnPhysicalPerformanceResult, reExecute] = useQuery({
    query: GET_TEAM_INFO_ON_PHYSICAL_PERFORMANCE,
    variables,
    pause,
    requestPolicy: 'network-only',
  })

  const data: getTeamInfoOnPhysicalPerformance =
    getTeamInfoOnPhysicalPerformanceResult.data?.getTeamInfoOnPhysicalPerformance
  const isFetching: boolean = getTeamInfoOnPhysicalPerformanceResult.fetching
  const { error } = getTeamInfoOnPhysicalPerformanceResult

  return { data, error, isFetching, reExecute }
}
