import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { DashboardLayout } from '../components'
import { useAppSelector } from '../redux/store'
import RoutesList from './RoutesList'

const AppRouter = () => {
  const {
    auth: { user },
  } = useAppSelector((state) => state)

  const renderComponent = (component: JSX.Element, isPublic?: boolean) => {
    if (isPublic) {
      return component
    }
    return <DashboardLayout>{component}</DashboardLayout>
  }

  return (
    <Router>
      <Routes>
        {RoutesList.map((item, index: number) =>
          item.permissions.includes(user?.role) || item.permissions.includes('*') ? (
            <Route
              path={item.path}
              element={renderComponent(item.component, item?.isPublic)}
              key={`route-item-${index}`}
            />
          ) : null
        )}
      </Routes>
    </Router>
  )
}

export default AppRouter
