import { createClient } from 'urql'

export const client = createClient({
  url: `${process.env.REACT_APP_API}/graphql`,
  maskTypename: true,
  fetchOptions: () => {
    const token = localStorage.getItem('token')
    return {
      headers: { authorization: token ? `Bearer ${token}` : '' },
    }
  },
})
