import styled from 'styled-components'
import { Formik, Form, FormikProps } from 'formik'
import { ButtonComponent, InputComponent, SelectComponent } from '../../components'
import { ValidationFranchiseForm } from './validation'
import { AddEditFranchiseProps, FranchiseFormValues } from '../../@types/franchises'
import { OtherProps } from '../../@types'
import { STATES } from '../../utils/constants'

const FormContainer = styled(Form)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`

const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const AddEditFranchise = ({ handleSubmit, initialValues }: AddEditFranchiseProps) => (
  <Formik
    initialValues={initialValues}
    onSubmit={(values, actions) => handleSubmit(values, actions)}
    validationSchema={ValidationFranchiseForm}
    enableReinitialize
  >
    {(props: OtherProps & FormikProps<FranchiseFormValues>) => (
      <FormContainer>
        <InputComponent
          name="name"
          label="Nome *:"
          value={props.values.name}
          props={props}
          width="350px"
        />
        <InputComponent
          name="email"
          label="E-mail *:"
          value={props.values.email}
          props={props}
          width="300px"
        />
        <InputComponent
          name="cnpj"
          label="CNPJ *:"
          value={props.values.cnpj}
          mask="99.999.999/9999-99"
          props={props}
          width="200px"
        />
        <InputComponent
          name="street"
          label="Logradouro *:"
          value={props.values.street}
          props={props}
          width="350px"
        />
        <InputComponent
          name="streetNumber"
          label="Número *:"
          value={props.values.streetNumber}
          props={props}
          width="150px"
        />
        <InputComponent
          name="complement"
          label="Complemento:"
          value={props.values.complement}
          props={props}
          width="300px"
        />
        <InputComponent
          name="neighborhood"
          label="Bairro *:"
          value={props.values.neighborhood}
          props={props}
          width="250px"
        />
        <InputComponent
          name="cep"
          label="CEP *:"
          props={props}
          value={props.values.cep}
          mask="99999-999"
          width="150px"
        />
        <InputComponent
          name="city"
          label="Cidade *:"
          value={props.values.city}
          props={props}
          width="250px"
        />
        <SelectComponent
          name="state"
          label="Estado *:"
          placeholderText="Selecione um estado..."
          options={STATES}
          value={props.values.state}
          props={props}
          width="300px"
        />
        <ButtonWrapper>
          <ButtonComponent text="Salvar" />
        </ButtonWrapper>
      </FormContainer>
    )}
  </Formik>
)

export default AddEditFranchise
