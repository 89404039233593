import styled from 'styled-components'
import Alert from '@mui/material/Alert'
import { Formik, Form, FormikProps } from 'formik'
import { ButtonComponent, HomePage, InputComponent } from '../../components'
import { useRecoveryPassword, validationRecoveryPassword } from './useRecoveryPassword'
import { RecoveryPasswordFormValues } from '../../@types/recoveryPassword'
import { OtherProps } from '../../@types'

const Title = styled('h3')`
  color: ${(props) => props?.theme?.colors?.primary};
`

const WrapperSubmitted = styled('div')`
  padding: ${(props) => props?.theme?.spacing?.spc20} ${(props) => props?.theme?.spacing?.spc20};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

function RecoveryPassword() {
  const { isSubmitted, initialValues, handleSubmit, backToLogin } = useRecoveryPassword()

  return (
    <HomePage linkText="Voltar ao login" handleClickLink={backToLogin}>
      {isSubmitted.isSubmitted ? (
        <WrapperSubmitted>
          {isSubmitted.status === 'success' ? (
            <>
              <Alert severity="success" style={{ width: '100%' }}>
                O e-mail foi enviado com sucesso!
              </Alert>
              <Title>Confira sua caixa de entrada</Title>
              <span>
                Enviamos um link para <b>{isSubmitted.email}</b>.
              </span>
            </>
          ) : (
            <Alert severity="error" style={{ width: '100%' }}>
              Erro ao enviar o email, tente novamente mais tarde!
            </Alert>
          )}
        </WrapperSubmitted>
      ) : (
        <>
          <Title>Esqueci minha senha</Title>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
            validationSchema={validationRecoveryPassword}
          >
            {(props: OtherProps & FormikProps<RecoveryPasswordFormValues>) => (
              <Form style={{ width: '100%' }}>
                <InputComponent
                  name="email"
                  label="Seu e-mail *:"
                  value={props.values.email}
                  props={props}
                />
                <ButtonComponent isMarginTop isFullSize text="Recuperar senha" />
              </Form>
            )}
          </Formik>
        </>
      )}
    </HomePage>
  )
}
export default RecoveryPassword
