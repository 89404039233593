import styled from 'styled-components'
import { Formik, Form, FormikProps } from 'formik'
import { ButtonComponent, InputComponent, SelectComponent, ToggleSwitch } from '../../components'
import { validation, validationCoach } from './validation'
import { OtherProps } from '../../@types'
import { AddEditCoachFormProps, AddEditCoachFormValues } from '../../@types/coach'
import { POSITIONS_COACHES } from '../../utils/constants'

const FormContainer = styled(Form)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`

const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const AddEditCoachForm = ({
  handleSubmit,
  initialValues,
  optionsFranchises,
  isCoach,
}: AddEditCoachFormProps) => (
  <Formik
    initialValues={initialValues}
    onSubmit={(values, actions) => handleSubmit(values, actions)}
    validationSchema={!isCoach ? validation : validationCoach}
    enableReinitialize
  >
    {(props: OtherProps & FormikProps<AddEditCoachFormValues>) => (
      <FormContainer>
        <InputComponent
          name="name"
          label="Nome *:"
          value={props.values.name}
          props={props}
          width="350px"
        />
        <InputComponent
          name="email"
          label="E-mail *:"
          value={props.values.email}
          props={props}
          width="350px"
        />
        <SelectComponent
          name="franchise"
          label="Unidade *:"
          placeholderText="Selecione uma unidade..."
          options={optionsFranchises}
          value={props.values.franchise}
          props={props}
          width="300px"
          isMulti={false}
        />
        <InputComponent
          name="cpf"
          label="CPF *:"
          isText={false}
          value={props.values.cpf}
          mask="999.999.999-99"
          props={props}
          width="300px"
        />
        <InputComponent
          name="phone"
          label="Telefone *:"
          value={props.values.phone}
          mask="+55(99)99999-9999"
          props={props}
          width="250px"
        />
        <SelectComponent
          name="position"
          label="Cargo *:"
          placeholderText="Selecione um cargo..."
          options={POSITIONS_COACHES}
          value={props.values.position}
          props={props}
          width="300px"
          isMulti={false}
        />
        {isCoach && (
          <>
            <InputComponent
              name="passwd"
              label="Senha *:"
              value={props.values?.passwd}
              props={props}
              width="250px"
              fieldType="password"
            />
            <InputComponent
              name="confirmPassword"
              label="Confirmar senha *:"
              value={props.values?.confirmPassword ?? ''}
              props={props}
              width="250px"
              fieldType="password"
            />
          </>
        )}
        <ToggleSwitch
          checked={props.values.active ?? false}
          setFieldValue={props.setFieldValue}
          name="active"
          label="Ativo *:"
          width="100%"
        />
        <ButtonWrapper>
          <ButtonComponent text="Salvar" />
        </ButtonWrapper>
      </FormContainer>
    )}
  </Formik>
)

export default AddEditCoachForm
