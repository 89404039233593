import styled from 'styled-components'
import { Tooltip } from '@mui/material'
import TimePicker from 'rc-time-picker'
import moment from 'moment'
import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material'
import { TimePickerComponentProps, TimePickerWrapperProps, WrapperProps } from './interface'

const Wrapper = styled('div')<WrapperProps>`
  display: flex;
  flex-direction: column;
  flex: 1 1 ${(props) => (props?.width ? props?.width : '200px')};
  height: 5rem;
  @media screen and (max-width: 769px) {
    width: 100%;
  }
  margin-top: ${(props) => props?.theme?.spacing?.spc10};
  label {
    font-size: ${(props) => props?.theme?.fontSizes?.font18};
    font-weight: 600;
    color: ${(props) => props?.theme?.colors?.primary};
  }
`

const TimePickerWrapper = styled('div')<TimePickerWrapperProps>`
  display: flex;
  align-items: center;
  background-color: ${(props) => props?.theme?.colors?.white};
  padding: ${(props) => props?.theme?.spacing?.spc8} ${(props) => props?.theme?.spacing?.spc8};
  border-radius: 4px;
  border: 1px solid
    ${(props) => (props.isError ? props?.theme?.colors?.red : props?.theme?.colors?.primary)};
  margin-top: ${(props) => props?.theme?.spacing?.spc8};
  input {
    width: 90%;
    font-size: ${(props) => props?.theme?.fontSizes?.font20};
    color: ${(props) => props?.theme?.colors?.black};
    border: none;
    outline: none;
    background-color: ${(props) => props?.theme?.colors?.white};
  }
  .rc-time-picker-clear {
    display: none;
  }
  svg {
    cursor: pointer;
    color: ${(props) => props?.theme?.colors?.white};
    background: ${(props) =>
      props.isError ? props?.theme?.colors?.red : props?.theme?.colors?.primary};
    border-radius: 50%;
    font-size: ${(props) => props?.theme?.fontSizes?.font16};
  }
`

const ErrorDiv = styled('div')`
  color: ${(props) => props?.theme?.colors?.red};
  font-size: ${(props) => props?.theme?.fontSizes?.font14};
  margin-top: ${(props) => props?.theme?.spacing?.spc6};
`

function TimePickerComponent({
  name,
  label,
  props,
  setValue,
  value,
  isText,
  width,
  disabled,
}: TimePickerComponentProps) {
  const handleClear = () => {
    if (setValue) setValue(undefined)
    if (props?.setFieldValue) props?.setFieldValue(name, undefined)
  }

  const handleOnChange = (e?: moment.Moment) => {
    props?.setFieldValue(name, e)
  }

  return (
    <Wrapper width={width} data-testid="time-picker-component">
      {label && <label htmlFor={name}>{label}</label>}
      {isText && <p>{value}</p>}
      {!isText && (
        <TimePickerWrapper isError={!!props?.errors[name]}>
          <TimePicker
            defaultValue={moment()}
            className="picker-time"
            showHour
            showMinute
            showSecond={false}
            onChange={handleOnChange}
            value={value || undefined}
          />
          {value && !disabled && (
            <Tooltip key={`time-picker-component-${name?.trim()}`} title="Limpar">
              <CloseRoundedIcon onClick={handleClear} />
            </Tooltip>
          )}
        </TimePickerWrapper>
      )}
      {props?.touched && props?.errors && props?.touched[name] && props?.errors[name] && (
        <ErrorDiv>{props?.errors[name]}</ErrorDiv>
      )}
    </Wrapper>
  )
}

export default TimePickerComponent
