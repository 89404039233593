import jwt_decode from 'jwt-decode'
import { AuthStateInterface, LoginActionInterface, LogoutActionInterface } from '../types/auth'

export const LoginAction = (token: string): LoginActionInterface => {
  const decoded: AuthStateInterface = jwt_decode(token)
  localStorage.setItem('token', token)

  return { type: 'LOGIN', payload: decoded }
}

export const LogoutAction = (): LogoutActionInterface => {
  localStorage.removeItem('token')
  return { type: 'LOGOUT' }
}
