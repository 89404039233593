import styled from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'
import { IconBoxProps, DisplayNumberChartProps } from './interface'

const ChartWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    background: ${theme.colors.white};
    padding: ${theme.spacing.spc10} ${theme.spacing.spc10};
    margin-right: ${theme.spacing.spc20};
    min-height: 4rem;
    min-width: 8rem;
    border-radius: ${theme.spacing.spc8};
    @media screen and (max-width: 425px) {
      width: 100%;
      margin-right: 0;
    }
  `}
`

const IconBox = styled.div<IconBoxProps>`
  ${(props) => `
    color: ${props.theme.colors.primary};
    background: ${props.theme.colors[props.color]};
    padding: ${props.theme.spacing.spc6} ${props.theme.spacing.spc6};
    border-radius: 100%;
    svg {
      color: ${props.theme.colors.white};
      font-size: 2.5rem;
    }
  `}
`

const ValueText = styled.span`
  ${({ theme }) => `
    color: ${theme.colors.primary};
    font-size: ${theme.fontSizes.font30};
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
`}
`

const ValuesWrapper = styled.span`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 ${theme.spacing.spc20} 0 ${theme.spacing.spc20};
  `}
`

const TextWrapper = styled.span`
  ${({ theme }) => `
    color: ${theme.colors.black};
    font-size: ${theme.fontSizes.font16};
    font-family: Arial, Helvetica, sans-serif;
  `}
`

function DisplayNumberChart({ value, icon, text, color, isLoading }: DisplayNumberChartProps) {
  return (
    <ChartWrapper>
      {!isLoading ? (
        <>
          <IconBox color={color}>{icon}</IconBox>
          <ValuesWrapper>
            <TextWrapper>{text}</TextWrapper>
            <ValueText>{value}</ValueText>
          </ValuesWrapper>
        </>
      ) : (
        <CircularProgress data-testid="circular-progress" color="primary" />
      )}
    </ChartWrapper>
  )
}

export default DisplayNumberChart
