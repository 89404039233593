import styled from 'styled-components'
import { HeaderGroup, Row, TableBodyPropGetter, TableBodyProps } from 'react-table'

const TableLine = styled.tr`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${theme.colors.greyLight};
    padding: ${theme.spacing.spc8} 0;
  `}
`

const TableColumn = styled.td`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const CellContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const HeaderText = styled.div`
  ${({ theme }) => `
    font-size: ${theme.fontSizes.font16};
    color: ${theme.colors.black};
`}
`

interface TableMobileProps {
  getTableBodyProps: (propGetter?: TableBodyPropGetter<any> | undefined) => TableBodyProps
  prepareRow: (row: Row<any>) => void
  dynamically?: boolean
  headerGroups: HeaderGroup<{ hideMobile?: boolean; mobileName?: string }>[]
  rows: Row<any>[]
  currentPage: number
  pageSize: number
}

const TableMobile = ({
  getTableBodyProps,
  prepareRow,
  dynamically,
  headerGroups,
  rows,
  currentPage,
  pageSize,
}: TableMobileProps) => {
  const tableList = dynamically
    ? rows
    : rows.slice((currentPage - 1) * pageSize, currentPage * pageSize)

  const isHeaderVisible = (index: number) =>
    !(headerGroups[0].headers[index] as { hideMobile?: boolean })?.hideMobile

  const textContent = (index: number) =>
    (headerGroups[0].headers[index] as { mobileName?: boolean })?.mobileName

  return (
    <tbody {...getTableBodyProps()}>
      {tableList.map((row, i) => {
        prepareRow(row)
        return (
          <TableLine {...row.getRowProps()} key={`tbody-mobile-${i}`}>
            <TableColumn>
              {row.cells.map((cell, index: number) => (
                <CellContent {...cell.getCellProps()} key={`tbody-mobile-cells-${index}`}>
                  <HeaderText>{textContent(index)}</HeaderText>
                  {isHeaderVisible(index) ? cell.render('Cell') : null}
                </CellContent>
              ))}
            </TableColumn>
          </TableLine>
        )
      })}
    </tbody>
  )
}

export default TableMobile
