import { Formik, Form, FormikProps } from 'formik'
import { HomePage, InputComponent, ButtonComponent } from '../components'
import { useLogin, validationLogin } from './useLogin'
import { OtherProps } from '../@types'
import { LoginFormValues } from '../@types/login'

function Login() {
  const { initialValues, handleForget, handleSubmit } = useLogin()

  return (
    <HomePage handleClickLink={handleForget} linkText="Esqueceu sua senha ?">
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        validationSchema={validationLogin}
      >
        {(props: OtherProps & FormikProps<LoginFormValues>) => (
          <Form style={{ width: '100%' }}>
            <InputComponent
              name="email"
              label="E-mail *:"
              value={props.values.email}
              props={props}
            />
            <InputComponent
              name="passwd"
              label="Senha *:"
              fieldType="password"
              value={props.values.passwd}
              props={props}
            />
            <ButtonComponent isMarginTop isFullSize text="Entrar" />
          </Form>
        )}
      </Formik>
    </HomePage>
  )
}
export default Login
