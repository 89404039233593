import styled from 'styled-components'
import { Formik, Form, FormikProps } from 'formik'
import { validation } from './validation'
import {
  ReportAttendanceListFormProps,
  AttendanceListFormValues,
} from '../../@types/attendanceList'
import { OtherProps } from '../../@types'
import { TeamPublic } from '../../@types/responses'
import { ButtonComponent, DatePickerComponent, SelectComponent } from '../../components'

const FormContainer = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`

const FormItemsWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
`

const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

function ReportAttendanceListForm({
  handleSubmit,
  initialValues,
  optionsFranchises,
  optionsTeams,
}: ReportAttendanceListFormProps) {
  const teamsOptions = (franchiseId: number | string) =>
    optionsTeams?.data
      ?.filter((team: TeamPublic) => team?.franchise?.id === franchiseId)
      ?.map((team: TeamPublic) => ({ label: team?.name, value: team?.id })) ?? []

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      validationSchema={validation}
      enableReinitialize
    >
      {(props: OtherProps & FormikProps<AttendanceListFormValues>) => (
        <FormContainer>
          <FormItemsWrapper>
            <SelectComponent
              name="franchise"
              label="Unidade:"
              placeholderText="Selecione uma unidade..."
              options={optionsFranchises}
              value={props.values.franchise}
              props={props}
              width="200px"
              isMulti={false}
            />
            <SelectComponent
              name="team"
              label="Turmas *:"
              placeholderText="Selecione uma turma.."
              options={teamsOptions(props.values.franchise)}
              value={props.values.team}
              props={props}
              width="200px"
              isMulti={false}
            />
            <DatePickerComponent
              name="attendanceDay"
              label="Mês da chamada *"
              dateFormat="MMMM yyyy"
              value={props.values.attendanceDay}
              props={props}
              width="200px"
            />
          </FormItemsWrapper>
          <ButtonWrapper>
            <ButtonComponent text="Salvar" />
          </ButtonWrapper>
        </FormContainer>
      )}
    </Formik>
  )
}

export default ReportAttendanceListForm
