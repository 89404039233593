import { useEffect, useState } from 'react'
import { useQuery } from 'urql'
import { isEmpty } from 'lodash'
import { useAppSelector } from '../../../redux/store'
import {
  DASH_ATHLETE_ATTENDANCE_PERC,
  GET_ACTIVE_INACTIVE_ATHLETES,
} from '../../../queries/dashboard'
import { filterFranchisesIds } from '../../../utils/utils'

export const useDashboard = () => {
  const {
    auth: { user },
    globalFilter: { selectedFranchises },
  } = useAppSelector((state) => state)

  const [getActiveInactiveAthletes] = useQuery({
    query: GET_ACTIVE_INACTIVE_ATHLETES,
    requestPolicy: 'network-only',
    pause: !['Administrador', 'Admin Unidade'].includes(user?.role),
    variables: {
      ...(!isEmpty(selectedFranchises) && {
        filterFranchisesIds: filterFranchisesIds(selectedFranchises),
      }),
    },
  })

  const data = getActiveInactiveAthletes.data?.getActiveInactiveAthletes
  const isAthlete = user?.role === 'Atleta'
  const isAdminOrAdminFranchise = ['Administrador', 'Admin Unidade'].includes(user?.role)

  return {
    fetching: getActiveInactiveAthletes.fetching,
    active: data?.active,
    inactive: data?.inactive,
    isAthlete,
    isAdminOrAdminFranchise,
  }
}

export const useDashboardAthletes = () => {
  const {
    auth: { user },
  } = useAppSelector((state) => state)

  const [search, setSearch] = useState<string>('D30')
  const [total, setTotal] = useState<number>(0)
  const [attendanceHistoricData, setAttendanceHistoricData] = useState<number[]>([0, 0, 0])

  const [dashAthleteAttendancePerc] = useQuery({
    query: DASH_ATHLETE_ATTENDANCE_PERC,
    requestPolicy: 'network-only',
    variables: {
      search,
    },
    pause: user?.role !== 'Atleta',
  })

  const attendanceHistoricOptions = [
    { name: 'Últimos 30 dias', value: 'D30' },
    { name: 'Últimos 60 dias', value: 'D60' },
    { name: 'Geral', value: '*' },
  ]
  const attendanceHistoricLabels: string[] = ['Faltas', 'Faltas Justificadas', 'Presenças']

  useEffect(() => {
    if (dashAthleteAttendancePerc?.data) {
      const data = dashAthleteAttendancePerc?.data?.dashAthleteAttendancePerc
      const fault = data?.f ?? 0
      const justifiedFault = data?.fj ?? 0
      const present = data?.p ?? 0
      setAttendanceHistoricData([fault, justifiedFault, present])
      const sumTotal = data?.sumTotal ?? 0
      setTotal(sumTotal)
    }
  }, [dashAthleteAttendancePerc?.data])

  return {
    fetching: dashAthleteAttendancePerc.fetching,
    total,
    search,
    setSearch,
    attendanceHistoricOptions,
    attendanceHistoricLabels,
    attendanceHistoricData,
  }
}
