import Modal from 'react-modal'
import styled from 'styled-components'
import ClearIcon from '@mui/icons-material/Clear'
import { useEffect } from 'react'
import { Tooltip } from '@mui/material'
import { OverlayProps } from './interface'
import Loader from '../Loading/Loading'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxHeight: 'calc(100vh - 6rem)',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 1000,
  },
}

const ModalContent = styled('div')`
  display: flex;
  min-height: 8rem;
  min-width: 15rem;
  gap: 15px;
  align-items: center;
  flex-direction: column;
  background: ${(props) => props?.theme.colors.white};
  border-radius: ${(props) => props?.theme?.spacing?.spc10};
`

const LoadingWrapper = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  span {
    height: 60px !important;
    width: 60px !important;
    color: ${(props) => props?.theme.colors.middleGreen};
  }
`

const ModalHeader = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid ${(props) => props?.theme?.colors?.greyLight};
  h2 {
    color: ${(props) => props?.theme?.colors?.primary};
    padding: 0;
    margin: 0;
  }
  svg {
    cursor: pointer;
  }
`

const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

function Overlay({
  isOpen,
  setIsOpen,
  children,
  isLoading = false,
  overlayTitle,
  buttonText,
  handleClick,
}: OverlayProps) {
  useEffect(() => {
    if (isOpen) document.body.style.setProperty('overflow', 'hidden')
    else document.body.style.setProperty('overflow', 'auto')
  }, [isOpen])

  const onClose = () => setIsOpen(false)

  return (
    <div>
      <Modal isOpen={isOpen} onRequestClose={onClose} ariaHideApp={false} style={customStyles}>
        <ModalContent>
          <ModalHeader>
            <h2>{overlayTitle}</h2>
            <Tooltip title="Fechar">
              <ClearIcon onClick={onClose} />
            </Tooltip>
          </ModalHeader>
          {!isLoading ? (
            <div style={{ display: 'flex', width: '100%' }}>{children}</div>
          ) : (
            <LoadingWrapper>
              <Loader />
            </LoadingWrapper>
          )}
        </ModalContent>
        {buttonText && handleClick && (
          <ButtonWrapper>
            <ButtonComponent text={buttonText} type="button" onClick={handleClick} />
          </ButtonWrapper>
        )}
      </Modal>
    </div>
  )
}

export default Overlay
