import styled from 'styled-components'
import { Formik, Form, FormikProps } from 'formik'
import {
  ButtonComponent,
  InputComponent,
  SelectComponent,
  TimePickerComponent,
  ToggleSwitch,
} from '../../components'
import { validation } from './validation'
import { OtherProps } from '../../@types'
import { OPTIONS_DAYS, OPTIONS_GENDER } from '../../utils/constants'
import { AddEditTeamFormProps, AddEditTeamFormValues } from '../../@types/team'

const FormContainer = styled(Form)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`

const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const AddEditTeamForm = ({
  handleSubmit,
  initialValues,
  optionsFranchises,
  isEditMode,
  children,
  getFieldValue,
}: AddEditTeamFormProps) => (
  <Formik
    initialValues={initialValues}
    onSubmit={(values, actions) => handleSubmit(values, actions)}
    validationSchema={validation}
    enableReinitialize
  >
    {(props: OtherProps & FormikProps<AddEditTeamFormValues>) => (
      <FormContainer>
        <InputComponent
          name="name"
          label="Nome *:"
          value={props.values.name}
          props={props}
          width="350px"
        />
        <SelectComponent
          name="franchise"
          label="Unidade:"
          placeholderText="Selecione uma unidade..."
          options={optionsFranchises}
          value={props.values.franchise}
          props={props}
          width="300px"
          disabled={isEditMode}
          isMulti={false}
          getFieldValue={getFieldValue}
        />
        <SelectComponent
          name="gender"
          label="Categoria *:"
          placeholderText="Selecione uma categoria..."
          options={OPTIONS_GENDER}
          value={props.values.gender}
          props={props}
          width="300px"
          isMulti={false}
          disabled={isEditMode}
          getFieldValue={getFieldValue}
        />
        <SelectComponent
          name="days"
          label="Dias*:"
          placeholderText="Selecione as dias da semana.."
          options={OPTIONS_DAYS}
          value={props.values.days}
          props={props}
          width="350px"
          isMulti
          noSortOptions
        />
        <TimePickerComponent
          name="startTime"
          label="
            Horário de início *:"
          value={props.values.startTime}
          props={props}
          width="100px"
        />
        <TimePickerComponent
          name="endTime"
          label="Horário de fim *:"
          value={props.values.endTime}
          props={props}
          width="100px"
        />
        <ToggleSwitch
          checked={props.values.active ?? false}
          setFieldValue={props.setFieldValue}
          name="active"
          label="Ativo *:"
          width="100%"
        />
        {children}
        <ButtonWrapper>
          <ButtonComponent text="Salvar" />
        </ButtonWrapper>
      </FormContainer>
    )}
  </Formik>
)

export default AddEditTeamForm
