import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { BreadcrumbItem, Panel } from '../../../../../components'
import { useAppSelector } from '../../../../../redux/store'
import { useGetAllAthleteRatingByIdReportQuery } from '../../../../../hooks/PhysicalPerformances/usePhysicalPerformances'

const PdfPreview = styled('iframe')`
  width: 100%;
  height: 100vh;
`

function AddEditPhysicalPerformanceReport() {
  const { physicalId, id } = useParams()
  const {
    auth: { user },
  } = useAppSelector((state) => state)

  const navigate = useNavigate()
  const { data, isFetching } = useGetAllAthleteRatingByIdReportQuery(false, {
    id: parseInt(id || '0', 10),
  })

  const isAthlete = user?.role === 'Atleta'
  const reportLink = data?.reportInfo?.reportLink ?? ''

  const breadcrumbs = [
    <BreadcrumbItem text="Técnico" link="/tecnico/avaliacoes" />,
    <BreadcrumbItem text="Avaliações" link="/tecnico/avaliacoes" />,
    <BreadcrumbItem text="Editar avaliação" link={`/tecnico/avaliacoes/${physicalId}`} />,
    <BreadcrumbItem text="Avaliação individual do atleta" />,
  ]

  const breadcrumbsAthlete = [
    <BreadcrumbItem text="Avaliações realizadas" link={`/tecnico/avaliacoes/${physicalId}`} />,
    <BreadcrumbItem text="Avaliação individual do atleta" />,
  ]

  return (
    <Panel
      title="Avaliação individual do atleta"
      isLoading={isFetching}
      breadcrumbs={!isAthlete ? breadcrumbs : breadcrumbsAthlete}
      backButton={{
        onClick: () => navigate(`/tecnico/avaliacoes/${physicalId}`),
        name: 'Voltar',
      }}
    >
      <PdfPreview src={reportLink} title="pdf-preview" />
    </Panel>
  )
}

export default AddEditPhysicalPerformanceReport
