import { useMemo } from 'react'
import styled from 'styled-components'
import { Alert, Checkbox } from '@mui/material'
import { TeamAthletesTableProps } from './interface'
import TableComponent from '../TableComponent'
import { useAthletesTable } from './useAthletesTable'
import { concatTableTexts } from '../../utils/utils'
import TableActions from '../TableActions/TableActions'
import ToggleSwitch from '../ToogleSwitch/ToggleSwitch'
import Overlay from '../Overlay/Overlay'
import { useActivateOrDeleteAthlete } from '../../hooks/Athletes/useAthletes'

const AthletesTableWrapper = styled('div')`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
`

const CheckboxWrapper = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const SelectedWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`

const TitleSelectedWrapper = styled('h2')`
  color: ${(props) => props?.theme?.colors?.primary};
`

const CheckedSelectedWrapper = styled('span')`
  width: fit-content;
  background: ${(props) => props?.theme?.colors?.primary};
  color: ${(props) => props?.theme?.colors?.white};
  padding: ${(props) => props?.theme?.spacing?.spc6} ${(props) => props?.theme?.spacing?.spc6};
  border-radius: ${(props) => props?.theme?.spacing?.spc10};
  font-weight: bold;
  font-size: ${(props) => props?.theme?.fontSizes?.font16};
  margin-bottom: ${(props) => props?.theme?.spacing?.spc20};
`

function AthletesTable({
  fetching,
  editMode,
  checkedIds,
  setCheckedIds,
  teamId,
  franchiseId,
  genderName,
  isAddRemaining,
}: TeamAthletesTableProps) {
  const {
    handleModalConfirmation,
    isOpen,
    setIsOpen,
    handleOnChangeSwitch,
    modalTitle,
    modalMessage,
  } = useActivateOrDeleteAthlete()

  const {
    isFetching,
    pageSize,
    setPageSize,
    tableData,
    currentPage,
    setCurrentPage,
    total,
    isAthleteChecked,
    handleCheck,
    handleRemoveAthleteOfTeam,
    displayAlert,
    editAthlete,
  } = useAthletesTable({
    isAddRemaining,
    checkedIds,
    setCheckedIds,
    editMode,
    teamId,
    franchiseId,
    genderName,
    isOpen,
  })

  const columns = useMemo(() => {
    const columnsList = [] as any
    if (!editMode) {
      columnsList.push({
        Header: 'Marcar',
        accessor: 'checked',
        width: 80,
        noSort: true,
        Cell: (props: any) => {
          const {
            row: {
              original: { id: athleteId },
            },
          } = props
          return (
            <CheckboxWrapper>
              <Checkbox
                style={{ color: '#173f37' }}
                checked={isAthleteChecked(athleteId)}
                onChange={() => handleCheck(athleteId, tableData)}
              />
            </CheckboxWrapper>
          )
        },
      })
    }
    columnsList.push({
      Header: 'Nome',
      accessor: 'name',
      width: 250,
      noSort: true,
    })
    columnsList.push({
      Header: 'Unidades',
      accessor: 'franchises',
      noSort: true,
      width: 120,
      Cell: (props: any) => {
        const franchises = props.row.original?.franchises
        return concatTableTexts(franchises)
      },
    })
    if (editMode) {
      columnsList.push({
        Header: 'Status',
        accessor: 'active',
        noSort: true,
        width: 50,
        Cell: (props: any) => {
          const {
            row: {
              original: { id: athleteId, active, name },
            },
          } = props
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ToggleSwitch
                label={active ? 'Ativo' : 'Inativo'}
                checked={!!active}
                handleOnChange={(e) => handleOnChangeSwitch(e, athleteId, name)}
                name="active"
              />
            </div>
          )
        },
      })
      columnsList.push({
        Header: 'Ações',
        accessor: 'action',
        width: 20,
        noSort: true,
        Cell: (props: any) => {
          const {
            row: {
              original: { id: athleteId },
            },
          } = props
          return (
            <TableActions
              actionsList={[
                {
                  type: 'edit',
                  title: 'Editar atleta',
                  handleClick: () => editAthlete(athleteId),
                },
                {
                  type: 'remove',
                  title: 'Remover da turma',
                  handleClick: () => handleRemoveAthleteOfTeam(athleteId),
                },
              ]}
            />
          )
        },
      })
    }
    return columnsList
  }, [
    editMode,
    handleCheck,
    handleOnChangeSwitch,
    handleRemoveAthleteOfTeam,
    isAthleteChecked,
    tableData,
    editAthlete,
  ])

  if (!editMode && !isAddRemaining && (!franchiseId || !genderName)) return null

  return (
    <>
      <AthletesTableWrapper>
        <SelectedWrapper>
          {!editMode && !isAddRemaining && (
            <>
              <TitleSelectedWrapper>Marque os atletas dessa turma</TitleSelectedWrapper>
              {checkedIds?.length ? (
                <CheckedSelectedWrapper>{`${checkedIds?.length} selecionados`}</CheckedSelectedWrapper>
              ) : null}
            </>
          )}
        </SelectedWrapper>
        <TableComponent
          columns={columns}
          data={tableData}
          canPreviousPage={currentPage + 1 !== 1}
          canNextPage={currentPage + 1 !== Math.ceil(total / pageSize)}
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={Math.ceil(total / pageSize)}
          total={total}
          dynamically
          fetching={fetching || isFetching}
          messageNoData="Turma sem atletas..."
        />
      </AthletesTableWrapper>
      <Overlay
        overlayTitle={modalTitle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        buttonText="Confirmar"
        handleClick={handleModalConfirmation}
      >
        <p>{modalMessage}</p>
      </Overlay>
      {displayAlert && (
        <Alert severity="warning" style={{ width: '100%' }}>
          Para efetuar as alterações, clique no botão salvar!
        </Alert>
      )}
    </>
  )
}

export default AthletesTable
