import * as Yup from 'yup'

export const validation = Yup.object({
  name: Yup.string().max(45, 'Nome muito longo').required('Campo obrigatório'),
  franchise: Yup.string().required('Campo obrigatório'),
  gender: Yup.string().required('Campo obrigatório'),
  days: Yup.array().min(1, 'Campo obrigatório'),
  startTime: Yup.object().typeError('Campo obrigatório').required('Campo obrigatório'),
  endTime: Yup.object().typeError('Campo obrigatório').required('Campo obrigatório'),
})
