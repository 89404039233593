import { gql } from 'urql'

export const ADD_TEAM = gql`
  mutation(
    $name: String!
    $franchiseId: Int!
    $days: String!
    $gender: String!
    $startTime: DateTime!
    $endTime: DateTime!
    $athletesId: [Int!]!
  ) {
    createTeam(
      input: {
        name: $name
        franchiseId: $franchiseId
        days: $days
        gender: $gender
        startTime: $startTime
        endTime: $endTime
        athletesId: $athletesId
      }
    ) {
      id
      name
    }
  }
`

export const UPDATE_TEAM = gql`
  mutation(
    $id: Int!
    $name: String!
    $franchiseId: Int!
    $days: String!
    $gender: String!
    $startTime: DateTime
    $endTime: DateTime
    $athletesId: [Int!]!
    $active: Boolean!
  ) {
    updateTeam(
      input: {
        id: $id
        name: $name
        franchiseId: $franchiseId
        days: $days
        gender: $gender
        startTime: $startTime
        endTime: $endTime
        athletesId: $athletesId
        active: $active
      }
    ) {
      id
      name
    }
  }
`

export const GET_TEAM_BY_ID = gql`
  query($id: Int!, $isAthleteActive: Boolean) {
    getTeamById(id: $id, isAthleteActive: $isAthleteActive) {
      id
      name
      days
      gender
      startTime
      endTime
      active
      franchise {
        id
        name
      }
      athletes {
        id
        name
        user {
          franchises {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_ALL_ATHLETES_BY_TEAM_ID = gql`
  query($id: Int!, $isAthleteActive: Boolean) {
    getAllAthletesByTeamId(id: $id, isAthleteActive: $isAthleteActive) {
      teamAthletes {
        id
        name
        active
        franchises {
          id
          name
        }
      }
      remainingAthletes {
        id
        name
        franchises {
          id
          name
        }
      }
    }
  }
`

export const DELETE_TEAM = gql`
  mutation($id: Int!) {
    deleteTeam(id: $id)
  }
`

export const GET_ALL_TEAMS = gql`
  query($filterFranchisesIds: [Int!], $isActive: Boolean, $isAthleteActive: Boolean) {
    getAllTeams(
      filterFranchisesIds: $filterFranchisesIds
      isActive: $isActive
      isAthleteActive: $isAthleteActive
    ) {
      data {
        id
        name
        gender
        days
        startTime
        endTime
        active
        franchise {
          id
          name
        }
        athletes {
          id
          name
          active
        }
      }
      total
    }
  }
`
