import styled from 'styled-components'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  PowerSettingsNew as PowerIcon,
} from '@mui/icons-material'
import { useState } from 'react'

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props?.theme?.spacing?.spc10};
  background-color: ${(props) => props?.theme.colors?.white};
  border-radius: ${(props) => props?.theme?.spacing?.spc8};
  padding: ${(props) => props?.theme?.spacing?.spc4} ${(props) => props?.theme?.spacing?.spc6};
  cursor: pointer;
  border: 1px solid ${(props) => props?.theme?.colors?.greyBase};
  max-width: 200px;
  max-height: 50px;
`

const PictureRounded = styled('div')`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: ${(props) => props?.theme?.colors?.greyBase};
`

const ProfileSelectorWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100px;
  span {
    overflow: hidden;
    white-space: nowrap;
    font-overflow: ellipsis;
  }
`

const ButtonOption = styled('button')`
  position: absolute;
  bottom: -35px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: ${(props) => props?.theme?.spacing?.spc10};
  background-color: ${(props) => props?.theme?.colors?.white};
  border-radius: ${(props) => props?.theme?.spacing?.spc8};
  border: none;
  font-size: ${(props) => props?.theme?.fontSizes?.font16};
  padding: ${(props) => props?.theme?.spacing?.spc6} ${(props) => props?.theme?.spacing?.spc10};
  font-weight: bold;
  cursor: pointer;
  border: 1px solid ${(props) => props?.theme?.colors?.greyBase};
  svg {
    font-size: ${(props) => props?.theme?.fontSizes?.font22};
  }
`

const NameText = styled('span')`
  color: ${(props) => props?.theme?.colors?.primary};
  font-size: ${(props) => props?.theme?.fontSizes?.font16};
  font-weight: bold;
`

const BusinessNameText = styled('span')`
  color: ${(props) => props?.theme.colors?.primary};
  font-size: ${(props) => props?.theme?.fontSizes?.font14};
`

export default function ProfileSelector({
  name,
  businessName,
  handleLogout,
}: {
  name: string
  businessName: string
  handleLogout: () => void
}) {
  const [isOpened, setIsOpened] = useState(false)

  return (
    <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
      <Wrapper onClick={() => setIsOpened(!isOpened)}>
        <PictureRounded />
        <ProfileSelectorWrapper>
          <NameText>{name}</NameText>
          <BusinessNameText>{businessName}</BusinessNameText>
        </ProfileSelectorWrapper>
        <div>
          <KeyboardArrowDownIcon />
        </div>
      </Wrapper>
      {isOpened ? (
        <ButtonOption onClick={handleLogout} type="button">
          <PowerIcon />
          <span>Sair</span>
        </ButtonOption>
      ) : null}
    </div>
  )
}
