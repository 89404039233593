import styled from 'styled-components'
import { Formik, Form, FormikProps } from 'formik'
import { Search as SearchIcon } from '@mui/icons-material'
import { OtherProps } from '../../../@types'
import { AthletesFilterFormValues, AthletesFilterFormValuesProps } from '../../../@types/athlete'
import { ButtonComponent, InputComponent, SelectComponent } from '../../../components'
import { OPTIONS_GENDER, OPTIONS_STATUS } from '../../../utils/constants'

const FormContainer = styled(Form)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border-bottom: 1px solid ${(props) => props?.theme?.colors?.greyLight};
  padding-bottom: ${(props) => props?.theme?.spacing?.spc20};
  margin-bottom: ${(props) => props?.theme?.spacing?.spc20};
`

const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

function AthletesFilterForm({ handleSubmit, initialValues }: AthletesFilterFormValuesProps) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      enableReinitialize
    >
      {(props: OtherProps & FormikProps<AthletesFilterFormValues>) => (
        <FormContainer>
          <InputComponent
            name="name"
            label="Nome:"
            value={props.values.name}
            props={props}
            width="350px"
          />
          <SelectComponent
            name="gender"
            label="Categoria:"
            placeholderText="Selecione uma categoria..."
            options={OPTIONS_GENDER}
            value={props.values.gender}
            props={props}
            width="300px"
            isMulti={false}
            disableGrow
          />
          <SelectComponent
            name="status"
            label="Status:"
            placeholderText="Selecione um status..."
            options={OPTIONS_STATUS}
            value={props.values.status}
            props={props}
            width="300px"
            isMulti={false}
            disableGrow
          />
          <ButtonWrapper>
            <ButtonComponent buttonIcon={<SearchIcon />} text="Pequisar" />
          </ButtonWrapper>
        </FormContainer>
      )}
    </Formik>
  )
}

export default AthletesFilterForm
