import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useGetAllAthleteRatingByAthleteIdQuery,
  useGetAllAthleteRatingsQuery,
} from '../../hooks/PhysicalPerformances/usePhysicalPerformances'
import { usePhysicalPerformanceTableProps } from './interface'

export const usePhysicalPerformanceTable = ({
  isAddPhysicalPerformance,
  isOpen,
  isAthlete,
  physicalIdNumber,
}: usePhysicalPerformanceTableProps) => {
  const [tableData, setTableData] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(0)

  const {
    data: getAllRatingsByAthleteId,
    isFetching: isFetchingGetAllRatingsByAthleteId,
  } = useGetAllAthleteRatingByAthleteIdQuery(!isAthlete, { athleteId: physicalIdNumber })

  const {
    data: getAllAthleteRatingsQuery,
    isFetching: isFetchingGetAllAthleteRatings,
    reExecute,
  } = useGetAllAthleteRatingsQuery(!physicalIdNumber || isAddPhysicalPerformance || isAthlete, {
    id: physicalIdNumber,
  })
  const isFetching = isFetchingGetAllRatingsByAthleteId || isFetchingGetAllAthleteRatings
  const navigate = useNavigate()

  const showReport = (physicalPerformanceId: number, ratingId: string | number) => {
    navigate(`/tecnico/avaliacoes/${physicalPerformanceId || ''}/relatorio-atleta/${ratingId}`)
  }
  const editRating = (physicalPerformanceId: number, ratingId: string | number) => {
    navigate(`/tecnico/avaliacoes/${physicalPerformanceId || ''}/avaliacao-atleta/${ratingId}`)
  }

  useEffect(() => {
    if (!isAthlete && getAllAthleteRatingsQuery && !isFetchingGetAllAthleteRatings) {
      setTableData(getAllAthleteRatingsQuery?.data)
      setTotal(getAllAthleteRatingsQuery?.total)
    }
  }, [getAllAthleteRatingsQuery, isAthlete, isFetchingGetAllAthleteRatings])

  useEffect(() => {
    if (!isOpen) reExecute({ requestPolicy: 'network-only' })
  }, [reExecute, isOpen])

  useEffect(() => {
    if (isAthlete) {
      const tableMapped = getAllRatingsByAthleteId?.data?.map((item: any) => ({
        id: item?.id,
        athleteName: item?.athlete?.name,
        speed: item?.speed,
        beep: item?.beep,
        impulse: item?.impulse,
        communication: item?.communication,
        technical: item?.technical,
        tatical: item?.tatical,
        physical: item?.physical,
        reportLink: item?.reportLink,
        updatedAt: item?.updatedAt,
      }))
      setTableData(tableMapped)
      setTotal(getAllRatingsByAthleteId?.total)
    }
  }, [getAllRatingsByAthleteId, isAthlete])

  return {
    isFetching,
    tableData,
    total,
    isAthlete,
    currentPage,
    setCurrentPage,
    showReport,
    editRating,
  }
}
