import { Formik, Form, FormikProps } from 'formik'
import styled from 'styled-components'
import { ButtonComponent, InputComponent, SelectComponent } from '../../components'
import { validation } from './validations'
import { AddEditUserFormValues, AddEditUserProps } from '../../@types/user'
import { OtherProps } from '../../@types'
import { OPTIONS_ROLES_ADMIN } from '../../utils/constants'

const FormContainer = styled(Form)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`

const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const AddEditUser = ({
  handleSubmit,
  initialValues,
  optionsFranchises,
  isAdmin,
}: AddEditUserProps) => (
  <Formik
    initialValues={initialValues}
    onSubmit={(values, actions) => handleSubmit(values, actions)}
    validationSchema={validation}
    enableReinitialize
  >
    {(props: OtherProps & FormikProps<AddEditUserFormValues>) => (
      <FormContainer>
        <InputComponent
          name="name"
          label="Nome *:"
          value={props.values.name}
          props={props}
          width="350px"
        />
        <InputComponent
          name="email"
          label="E-mail *:"
          value={props.values.email}
          props={props}
          width="350px"
        />
        <SelectComponent
          name="franchise"
          label="Unidade:"
          placeholderText="Selecione uma unidade..."
          options={optionsFranchises}
          value={props.values.franchises}
          props={props}
          width="300px"
          isMulti
        />
        <SelectComponent
          name="role"
          label="Função *:"
          placeholderText="Selecione uma função..."
          options={OPTIONS_ROLES_ADMIN}
          value={props.values.role}
          props={props}
          width="300px"
          isMulti={false}
          disabled={!isAdmin}
        />
        <InputComponent
          name="passwd"
          label="Senha *:"
          value={props.values?.passwd}
          props={props}
          width="250px"
          fieldType="password"
        />
        <InputComponent
          name="confirmPassword"
          label="Confirmar senha *:"
          value={props.values?.confirmPassword ?? ''}
          props={props}
          width="250px"
          fieldType="password"
        />

        <ButtonWrapper>
          <ButtonComponent text="Salvar" />
        </ButtonWrapper>
      </FormContainer>
    )}
  </Formik>
)

export default AddEditUser
