import styled from 'styled-components'
import { Formik, Form, FormikProps } from 'formik'
import { useCreateNewPassword, validationCreateNewPassword } from './useCreateNewPassword'
import { ButtonComponent, HomePage, InputComponent } from '../../components'
import { OtherProps } from '../../@types'
import { CreateNewPasswordFormValues } from '../../@types/createNewPassword'

const Title = styled('h3')`
  color: ${(props) => props?.theme?.colors?.primary};
`

function CreateNewPassword() {
  const { initialValues, handleSubmit } = useCreateNewPassword()

  return (
    <HomePage>
      <Title>Criar nova senha</Title>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => handleSubmit(values, actions)}
        validationSchema={validationCreateNewPassword}
      >
        {(props: OtherProps & FormikProps<CreateNewPasswordFormValues>) => (
          <Form style={{ width: '100%' }}>
            <InputComponent
              name="passwd"
              fieldType="password"
              label="Nova senha *:"
              value={props.values.passwd}
              props={props}
            />
            <InputComponent
              name="confirmPassword"
              label="Confirmar senha *:"
              fieldType="password"
              value={props.values.confirmPassword}
              props={props}
            />
            <ButtonComponent isMarginTop isFullSize text="Criar senha" />
          </Form>
        )}
      </Formik>
    </HomePage>
  )
}
export default CreateNewPassword
