import * as Yup from 'yup'

export const validation = Yup.object({
  name: Yup.string().max(45, 'Nome muito longo').required('Campo obrigatório'),
  email: Yup.string()
    .max(45, 'E-mail muito longo')
    .email('Insira um e-mail válido')
    .required('Campo obrigatório'),
  gender: Yup.string().required('Campo obrigatório'),
  franchise: Yup.string().required('Campo obrigatório'),
  cpf: Yup.string()
    .matches(
      /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2})$/,
      'CPF inválido'
    )
    .required('Campo obrigatório'),
  phone: Yup.string()
    .matches(
      /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{4})-?(\d{4}))$/,
      'Telefone inválido'
    )
    .required('Campo obrigatório'),
  birthday: Yup.string().typeError('Campo obrigatório').required('Campo obrigatório'),
  team: Yup.array().min(1, 'Campo obrigatório'),
})

export const validationAthleteUser = Yup.object({
  name: Yup.string().max(45, 'Nome muito longo').required('Campo obrigatório'),
  email: Yup.string()
    .max(45, 'E-mail muito longo')
    .email('Insira um e-mail válido')
    .required('Campo obrigatório'),
  phone: Yup.string()
    .matches(
      /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{4})-?(\d{4}))$/,
      'Telefone inválido'
    )
    .required('Campo obrigatório'),
  birthday: Yup.string().typeError('Campo obrigatório').required('Campo obrigatório'),
  passwd: Yup.string()
    .min(3, 'Senha muito curta')
    .max(45, 'Senha muito longa')
    .required('Campo obrigatório'),
  confirmPassword: Yup.string()
    .min(3, 'Senha muito curta')
    .oneOf([Yup.ref('passwd'), null], 'As senhas não coincidem')
    .required('Campo obrigatório'),
})
