import { useEffect, useState } from 'react'
import { FormikHelpers } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from 'urql'
import { useDispatch } from 'react-redux'
import { toastDanger, toastSuccess } from '../../../../../redux/toast/actions'
import { ADD_ATHLETE_RATING, UPDATE_ATHLETE_RATING } from '../../../../../queries/atlheteRating'
import { AddEditPerformanceFormValues } from '../../../../../@types/physicalPerformance'
import { GenericSelectProps } from '../../../../../@types'
import {
  useGetAllAthleteByPerformanceIdQuery,
  useGetAthleteRatingByIdQuery,
} from '../../../../../hooks/PhysicalPerformances/usePhysicalPerformances'

export const useAddEditPhysicalPerformanceAthlete = () => {
  const [addAthleteRatingResult, addAthleteRating] = useMutation(ADD_ATHLETE_RATING)
  const [updateAthleteRatingResult, updateAthleteRating] = useMutation(UPDATE_ATHLETE_RATING)

  const { physicalId, id } = useParams()
  const isAddMode = id === 'addRating'
  const physicalIdNumber = parseInt(physicalId || '0', 10)
  const IdNumber = parseInt(id || '0', 10)

  const {
    data: dataAllAthletes,
    isFetching: isFetchingAllAthletes,
  } = useGetAllAthleteByPerformanceIdQuery(false, { id: physicalIdNumber })

  const {
    data: dataAthleteRating,
    isFetching: isFetchingAthleteRating,
  } = useGetAthleteRatingByIdQuery(isAddMode, { id: IdNumber })

  const isFetching =
    isFetchingAllAthletes ||
    isFetchingAthleteRating ||
    addAthleteRatingResult?.fetching ||
    updateAthleteRatingResult?.fetching

  const [initialValues, setInitialValues] = useState<AddEditPerformanceFormValues>({
    athleteId: 0,
    speed: '',
    impulse: '',
    beep: '',
    communication: 0,
    technical: 0,
    tatical: 0,
    physical: 0,
    positivePoints: '',
    negativePoints: '',
  })
  const [optionsAthletes, setOptionsAthletes] = useState<GenericSelectProps[]>([])
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [teamName, setTeamName] = useState<string>('')

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleSubmit = (
    values: AddEditPerformanceFormValues,
    actions: FormikHelpers<AddEditPerformanceFormValues>
  ) => {
    setIsOpen(true)
    setInitialValues(values)
    actions.setSubmitting(false)
  }

  const handleSaveAction = () => {
    const saveData = {
      athleteId: initialValues.athleteId,
      speed: parseFloat(initialValues.speed.toString()),
      impulse: parseFloat(initialValues.impulse.toString()),
      beep: initialValues.beep,
      communication: initialValues.communication,
      technical: initialValues.technical,
      tatical: initialValues.tatical,
      physical: initialValues.physical,
      physicalPerformanceId: physicalIdNumber,
      positivePoints: initialValues.positivePoints,
      negativePoints: initialValues.negativePoints,
    }
    if (isAddMode) {
      addAthleteRating(saveData)
        .then((result: any) => {
          if (result?.error) {
            dispatch(toastDanger(result?.error?.message.substring(10)))
          } else {
            dispatch(toastSuccess('Avaliação criada com sucesso!'))
            navigate(`/tecnico/avaliacoes/${physicalId}`)
          }
        })
        .catch(() => {
          dispatch(toastDanger('Erro ao criar avaliação!'))
        })
    } else {
      updateAthleteRating({
        ...saveData,
        id: IdNumber,
      })
        .then((result: any) => {
          if (result?.error) {
            dispatch(toastDanger(result?.error?.message.substring(10)))
          } else {
            dispatch(toastSuccess('Avaliação atualizada com sucesso!'))
            navigate(`/tecnico/avaliacoes/${physicalId}`)
          }
        })
        .catch(() => dispatch(toastDanger('Erro ao atualizar avaliação!')))
    }
  }

  const confirmValues = {
    ...initialValues,
    label:
      optionsAthletes?.find((athlete) => athlete?.value === initialValues.athleteId)?.label ?? '',
  }

  const handleGoBack = () => navigate(`/tecnico/avaliacoes/${physicalId}`)
  const getTitle = isAddMode ? 'Avaliar atleta' : 'Editar avaliação do atleta'

  useEffect(() => {
    if (!isFetchingAllAthletes && dataAllAthletes) {
      const athletesDataMapped =
        dataAllAthletes?.data?.map((item) => ({
          label: item?.name,
          value: item.id,
        })) ?? []
      setOptionsAthletes(athletesDataMapped)

      const newTeamName = dataAllAthletes?.team?.name ?? ''
      setTeamName(newTeamName)
    }
  }, [dataAllAthletes, isFetchingAllAthletes])

  useEffect(() => {
    if (!isAddMode && !isFetchingAthleteRating) {
      setInitialValues({
        athleteId: dataAthleteRating?.athlete?.id,
        speed: dataAthleteRating?.speed,
        impulse: dataAthleteRating?.impulse,
        beep: dataAthleteRating?.beep,
        communication: dataAthleteRating?.communication,
        technical: dataAthleteRating?.technical,
        tatical: dataAthleteRating?.tatical,
        physical: dataAthleteRating?.physical,
        positivePoints: dataAthleteRating?.positivePoints,
        negativePoints: dataAthleteRating?.negativePoints,
      })
    }
  }, [dataAthleteRating, id, isAddMode, isFetchingAthleteRating])

  return {
    isFetching,
    optionsAthletes,
    isOpen,
    initialValues,
    handleSubmit,
    teamName,
    physicalId,
    setIsOpen,
    navigate,
    getTitle,
    handleGoBack,
    confirmValues,
    handleSaveAction,
  }
}
