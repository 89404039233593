import { useMemo } from 'react'
import {
  Panel,
  TableComponent,
  BreadcrumbItem,
  TableActions,
  Overlay,
  ActiveLabel,
} from '../../../components'
import { useCoachesContainer, useDeleteCoaches } from './useCoachesContainer'
import { concatTableTexts } from '../../../utils/utils'

function Coaches() {
  const {
    handleDeleteAction,
    handleDeleteCoach,
    isOpen,
    setIsOpen,
    deleteOptions,
    isFetchingDelete,
  } = useDeleteCoaches()
  const {
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    total,
    panelButtons,
    editCoach,
    isFetching,
  } = useCoachesContainer(isOpen)

  const columns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name',
        width: 180,
      },
      {
        Header: 'Unidades',
        accessor: 'franchises',
        width: 180,
        Cell: (props: any) => {
          const franchises = props.row.original?.franchises
          return concatTableTexts(franchises)
        },
      },
      {
        Header: 'Cargo',
        accessor: 'position',
        width: 100,
      },
      {
        Header: 'Ativo',
        accessor: 'active',
        width: 40,
        Cell: (props: any) => {
          const {
            row: {
              original: { active },
            },
          } = props
          return <ActiveLabel isActive={!!active} />
        },
      },

      {
        Header: 'Ações',
        accessor: 'actions',
        width: 50,
        noSort: true,
        Cell: (props: any) => {
          const {
            row: {
              original: { id: coachId, name: coachName },
            },
          } = props
          return (
            <TableActions
              actionsList={[
                {
                  type: 'edit',
                  title: 'Editar',
                  handleClick: () => editCoach(coachId),
                },
                {
                  type: 'delete',
                  title: 'Excluir',
                  handleClick: () => handleDeleteCoach(coachId, coachName),
                },
              ]}
            />
          )
        },
      },
    ],
    [editCoach, handleDeleteCoach]
  )

  const breadcrumbs = [
    <BreadcrumbItem key="tecnico" text="Técnico" link="/tecnico/treinadores" />,
    <BreadcrumbItem key="treinadores" text="Treinadores" />,
  ]

  return (
    <Panel title="Treinadores" buttons={panelButtons} isLoading={false} breadcrumbs={breadcrumbs}>
      <TableComponent
        fetching={isFetching || isFetchingDelete}
        columns={columns}
        data={tableData}
        canPreviousPage={currentPage !== 1}
        canNextPage={currentPage !== Math.ceil(total / pageSize)}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={Math.ceil(total / pageSize)}
      />
      <Overlay
        overlayTitle="Excluir treinador"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        buttonText="Confirmar"
        handleClick={handleDeleteAction}
      >
        <p>{`Realmente deseja excluir o treinador ${deleteOptions.name} ?`}</p>
      </Overlay>
    </Panel>
  )
}

export default Coaches
