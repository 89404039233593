import { BreadcrumbItem, Panel } from '../../../../components'
import AddEditFranchiseForm from '../../../../Forms/AddEditFranchiseForm/AddEditFranchiseForm'
import { useAddEditFranchise } from './useAddEditFranchise'

function AddEditFranchise() {
  const { isFetching, initialValues, handleSubmit, backButton, title } = useAddEditFranchise()

  const breadcrumbs = [
    <BreadcrumbItem key="geral" text="Geral" link="/geral/unidades" />,
    <BreadcrumbItem key="unidades" text="Unidades" link="/geral/unidades" />,
    <BreadcrumbItem key="addEditUnidade" text={title} />,
  ]

  return (
    <Panel title={title} isLoading={isFetching} breadcrumbs={breadcrumbs} backButton={backButton}>
      <AddEditFranchiseForm handleSubmit={handleSubmit} initialValues={initialValues} />
    </Panel>
  )
}

export default AddEditFranchise
