import { useMutation, useQuery } from 'urql'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import {
  DELETE_ATHLETE,
  GET_ALL_ATHLETES,
  GET_ALL_ATHLETES_REPORT,
  GET_ATHLETE_BY_ID,
  UPDATE_ATHLETE,
} from '../../queries/athletes'
import {
  getAllAthletes,
  getAllAthletesByTeamId,
  getAllAthletesReport,
  getAthleteById,
} from '../../@types/responses'
import {
  variablesUseGetAllAthletesByTeamIdQuery,
  variablesUseGetAllAthletesQuery,
  variablesUseGetAllAthletesReportQuery,
} from './interface'
import { GET_ALL_ATHLETES_BY_TEAM_ID } from '../../queries/teams'
import { handleErrors } from '../../utils/handleGraphQLErrors'
import { toastDanger, toastSuccess } from '../../redux/toast/actions'
import { IdQueryVariableType } from '../interface'

export const useGetAthleteByIdQuery = (pause: boolean, variables: IdQueryVariableType) => {
  const [getAthleteByIdResult] = useQuery({
    query: GET_ATHLETE_BY_ID,
    variables,
    pause,
    requestPolicy: 'network-only',
  })

  const isFetching: boolean = getAthleteByIdResult.fetching
  const { error } = getAthleteByIdResult
  const data: getAthleteById = getAthleteByIdResult.data?.getAthleteById

  return { data, error, isFetching }
}

export const useGetAllAthletes = (pause: boolean, variables: variablesUseGetAllAthletesQuery) => {
  const [getAllAthletesResult, reExecute] = useQuery({
    query: GET_ALL_ATHLETES,
    requestPolicy: 'network-only',
    variables,
    pause,
  })

  const data: getAllAthletes = getAllAthletesResult.data?.getAllAthletes
  const isFetching: boolean = getAllAthletesResult.fetching
  const { error } = getAllAthletesResult

  return {
    data,
    error,
    isFetching,
    reExecute,
  }
}

export const useGetAllAthletesReport = (
  pause: boolean,
  variables: variablesUseGetAllAthletesReportQuery
) => {
  const [getAllAthletesReportResult] = useQuery({
    query: GET_ALL_ATHLETES_REPORT,
    requestPolicy: 'network-only',
    variables,
    pause,
  })

  const data: getAllAthletesReport = getAllAthletesReportResult.data?.getAllAthletesReport
  const isFetching: boolean = getAllAthletesReportResult.fetching
  const { error } = getAllAthletesReportResult

  return {
    data,
    error,
    isFetching,
  }
}

export const useGetAllAthletesByTeam = (
  pause: boolean,
  variables: variablesUseGetAllAthletesByTeamIdQuery
) => {
  const [getAllAthletesByTeamIdResult, reExecute] = useQuery({
    query: GET_ALL_ATHLETES_BY_TEAM_ID,
    requestPolicy: 'network-only',
    variables,
    pause,
  })

  const data: getAllAthletesByTeamId = getAllAthletesByTeamIdResult.data?.getAllAthletesByTeamId
  const isFetching: boolean = getAllAthletesByTeamIdResult.fetching
  const { error } = getAllAthletesByTeamIdResult

  return {
    data,
    isFetching,
    error,
    reExecute,
  }
}

export const useActivateOrDeleteAthlete = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [deleteOptions, setDeleteOptions] = useState({ id: '', name: '' })
  const [activateOptions, setActivateOptions] = useState({ id: '', name: '', checked: false })

  const [deleteAthleteResult, deleteAthlete] = useMutation(DELETE_ATHLETE)
  const [updateAthleteResult, updateAthlete] = useMutation(UPDATE_ATHLETE)

  const dispatch = useDispatch()

  const handleDeleteAthlete = (athleteId: string, athleteName: string) => {
    setDeleteOptions({ id: athleteId ?? '0', name: athleteName })
    setIsOpen(true)
  }

  const handleOnChangeSwitch = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
    name: string
  ) => {
    setActivateOptions({ id: id ?? '0', name, checked: e.target.checked })
    setIsOpen(true)
  }

  const handleOnClickLabel = (id: string, name: string, isChecked: boolean) => {
    setActivateOptions({ id: id ?? '0', name, checked: !isChecked })
    setIsOpen(true)
  }

  const handleDeleteAction = () => {
    deleteAthlete({
      id: parseInt(deleteOptions.id, 10),
    })
      .then((result: any) => {
        if (result?.error) {
          const [error] = handleErrors(result?.error)
          dispatch(toastDanger(error))
        } else {
          dispatch(toastSuccess('Atleta removido com sucesso!'))
          setIsOpen(false)
          setDeleteOptions({ id: '', name: '' })
        }
      })
      .catch(() => {
        dispatch(toastDanger('Erro ao remover atleta'))
      })
  }

  const handleActivateDeactivate = () => {
    updateAthlete({
      id: activateOptions.id,
      active: activateOptions.checked,
    })
      .then((result: any) => {
        if (result?.error) {
          const [error] = handleErrors(result?.error)
          dispatch(toastDanger(error))
        } else {
          dispatch(toastSuccess('Atleta atualizado com sucesso!'))
          setIsOpen(false)
          setActivateOptions({ id: '', name: '', checked: false })
        }
      })
      .catch(() => dispatch(toastDanger('Erro ao atualizar atleta!')))
  }

  useEffect(() => {
    if (!isOpen) {
      setActivateOptions({ id: '', name: '', checked: false })
      setDeleteOptions({ id: '', name: '' })
    }
  }, [isOpen])

  const deleteActivateAthleteFetching = deleteAthleteResult.fetching || updateAthleteResult.fetching

  const deleteMessage = `Realmente deseja excluir o(a) atleta ${deleteOptions.name} ?`
  const activateMessage = `Realmente deseja ${
    activateOptions.checked ? 'ativar' : 'desativar'
  } o(a) atleta ${activateOptions.name} ?`
  const modalMessage = deleteOptions.name ? deleteMessage : activateMessage

  const modalTitle = deleteOptions.name ? 'Excluir atleta' : 'Ativar/Desativar atleta'

  const handleModalConfirmation = deleteOptions.name ? handleDeleteAction : handleActivateDeactivate

  return {
    handleDeleteAthlete,
    handleDeleteAction,
    isOpen,
    setIsOpen,
    deleteOptions,
    deleteActivateAthleteFetching,
    handleActivateDeactivate,
    handleOnChangeSwitch,
    handleModalConfirmation,
    modalTitle,
    modalMessage,
    handleOnClickLabel,
  }
}
