// Geral
import AddEditFranchise from '../pages/Geral/Franchises/AddEditFranchise/AddEditFranchise'
import AddEditUser from '../pages/Geral/Users/AddEditUser/AddEditUser'
import Franchises from '../pages/Geral/Franchises/Franchises'
import Dashboard from '../pages/Geral/Dashboard/Dashboard'
import Users from '../pages/Geral/Users/Users'
import Athletes from '../pages/Geral/Athletes/Athletes'
import AddEditTeam from '../pages/Geral/Teams/AddEditTeam/AddEditTeam'
import AddEditAthlete from '../pages/Geral/Athletes/AddEditAthlete/AddEditAthlete'
import Teams from '../pages/Geral/Teams/Teams'

// Técnico
import Coaches from '../pages/Tecnico/Coaches/Coaches'
import AddEditCoach from '../pages/Tecnico/Coaches/AddEditCoach/AddEditCoach'
import PhysicalPerformace from '../pages/Tecnico/PhysicalPerformance/PhysicalPerformance'
import AddEditPhysicalPerformance from '../pages/Tecnico/PhysicalPerformance/AddEditPhysicalPerformance/AddEditPhysicalPerformance'
import AddEditPhysicalPerformanceAthlete from '../pages/Tecnico/PhysicalPerformance/AddEditPhysicalPerformance/AddEditPhysicalPerformanceAthlete/AddEditPhysicalPerformanceAthlete'
import AddEditPhysicalPerformanceReport from '../pages/Tecnico/PhysicalPerformance/AddEditPhysicalPerformance/PdfPreview/PdfPreview'
import AddEditAttendanceList from '../pages/Tecnico/AttendanceList/AddEditAttendanceList/AddEditAttendanceList'
import AttendanceList from '../pages/Tecnico/AttendanceList/AttendanceList'
import ReportAttendance from '../pages/Tecnico/AttendanceList/ReportAttendance/ReportAttendance'

// Public
import Login from '../pages'
import PageNotFound from '../pages/PageNotFound/PageNotFound'
import RecoveryPassword from '../pages/RecoveryPassword/RecoveryPassword'
import CreateNewPassword from '../pages/CreateNewPassword/CreateNewPassword'
import AddTeamAthletes from '../pages/Geral/Teams/AddEditTeam/AddTeamAthletes/AddTeamAthletes'
import ExperimentalTrainings from '../pages/Geral/ExperimentalTrainings/ExperimentalTrainings'
import AddEditExperimentalTraining from '../pages/Geral/ExperimentalTrainings/AddEditExperimentalTraining/AddEditExperimentalTraining'

import Config from '../pages/config'

export interface RouteProps {
  path: string
  component: JSX.Element
  permissions: string[]
  isPublic?: boolean
}

const routesList: RouteProps[] = [
  {
    path: '/config',
    component: <Config />,
    permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
  },
  // Publico
  {
    path: '/',
    component: <Login />,
    permissions: ['*'],
    isPublic: true,
  },
  {
    path: '/recuperar-senha',
    component: <RecoveryPassword />,
    permissions: ['*'],
    isPublic: true,
  },
  {
    path: '/criar-nova-senha/:token',
    component: <CreateNewPassword />,
    permissions: ['*'],
    isPublic: true,
  },
  // Geral
  {
    path: '/geral/dashboard',
    component: <Dashboard />,
    permissions: ['Administrador', 'Admin Unidade'],
  },
  {
    path: '/geral/administradores',
    component: <Users />,
    permissions: ['Administrador', 'Admin Unidade'],
  },
  {
    path: '/geral/administradores/:id',
    component: <AddEditUser />,
    permissions: ['Administrador', 'Admin Unidade'],
  },
  {
    path: '/geral/experimental-trainings',
    component: <ExperimentalTrainings />,
    permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
  },
  {
    path: '/geral/experimental-trainings/:id',
    component: <AddEditExperimentalTraining />,
    permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
  },
  {
    path: '/geral/unidades',
    component: <Franchises />,
    permissions: ['Administrador', 'Admin Unidade'],
  },
  {
    path: '/geral/unidades/:id',
    component: <AddEditFranchise />,
    permissions: ['Administrador', 'Admin Unidade'],
  },
  // Tecnico
  {
    path: '/tecnico/treinadores',
    component: <Coaches />,
    permissions: ['Administrador', 'Admin Unidade'],
  },
  {
    path: '/tecnico/treinadores',
    component: <Coaches />,
    permissions: ['Administrador', 'Admin Unidade'],
  },
  {
    path: '/tecnico/treinadores/:id',
    component: <AddEditCoach />,
    permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
  },
  {
    path: '/geral/atletas',
    component: <Athletes />,
    permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
  },
  {
    path: '/geral/atletas/:id',
    component: <AddEditAthlete />,
    permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
  },
  {
    path: '/tecnico/avaliacoes',
    component: <PhysicalPerformace />,
    permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
  },
  {
    path: '/tecnico/avaliacoes/:id',
    component: <AddEditPhysicalPerformance />,
    permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
  },
  {
    path: '/tecnico/avaliacoes/:physicalId/avaliacao-atleta/:id',
    component: <AddEditPhysicalPerformanceAthlete />,
    permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
  },
  {
    path: '/tecnico/avaliacoes/:physicalId/relatorio-atleta/:id',
    component: <AddEditPhysicalPerformanceReport />,
    permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
  },
  {
    path: '/geral/turmas',
    component: <Teams />,
    permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
  },
  {
    path: '/geral/turmas/:id',
    component: <AddEditTeam />,
    permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
  },
  {
    path: '/geral/turmas/:teamId/atletas',
    component: <AddTeamAthletes />,
    permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
  },
  {
    path: '/tecnico/chamadas',
    component: <AttendanceList />,
    permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
  },
  {
    path: '/tecnico/chamadas/:id',
    component: <AddEditAttendanceList />,
    permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
  },
  {
    path: '/tecnico/chamadas/filtrar/relatorio',
    component: <ReportAttendance />,
    permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
  },
  { path: '*', component: <PageNotFound />, permissions: ['*'] },
]

export default routesList
