import { ActionsMenuTypes } from '../types'
import { MenuInterface } from '../types/menu'

const getMenuStatus = () => {
  const windowSize: number = window.innerWidth
  if (windowSize > 768) {
    return true
  }
  return false
}

const initialState: MenuInterface = {
  isOpen: getMenuStatus(),
}

const menuReducer = (state = initialState, action: ActionsMenuTypes): MenuInterface => {
  switch (action.type) {
    case 'MENU_CHANGE':
      return {
        isOpen: !state.isOpen,
      }
    default:
      return {
        ...state,
      }
  }
}

export default menuReducer
