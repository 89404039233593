import styled from 'styled-components'
import { Formik, Form, FormikProps } from 'formik'
import { ButtonComponent, InputComponent, SelectComponent } from '../../components'
import { validation } from './validation'
import { OtherProps } from '../../@types'
import {
  AddEditPsychicalPerformanceFormProps,
  PhysicalPerformanceFormValues,
} from '../../@types/physicalPerformance'
import { TeamPublic } from '../../@types/responses'

const FormContainer = styled(Form)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`

const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const AddEditPyshicalPerformanceForm = ({
  handleSubmit,
  initialValues,
  optionsFranchises,
  editMode,
  dataTeams,
}: AddEditPsychicalPerformanceFormProps) => {
  const teamsOptions = (franchiseId: number | string) =>
    dataTeams?.data
      ?.filter((team: TeamPublic) => team?.franchise?.id === franchiseId)
      ?.map((team: TeamPublic) => ({ label: team?.name, value: team?.id })) ?? []

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      validationSchema={validation}
      enableReinitialize
    >
      {(props: OtherProps & FormikProps<PhysicalPerformanceFormValues>) => (
        <FormContainer>
          {editMode && (
            <InputComponent
              name="name"
              label="Nome *:"
              value={props.values.name}
              props={props}
              width="250px"
              disabled
            />
          )}
          <SelectComponent
            name="franchise"
            label="Unidade:"
            placeholderText="Selecione uma unidade..."
            options={optionsFranchises}
            value={props.values.franchise}
            props={props}
            width="250px"
            disabled={editMode}
            isMulti={false}
          />
          <SelectComponent
            name="team"
            label="Turmas *:"
            placeholderText="Selecione as turmas.."
            options={teamsOptions(props.values.franchise)}
            value={props.values.team}
            props={props}
            width="250px"
            disabled={editMode}
            isMulti={false}
          />
          <InputComponent
            name="coach"
            label="Avaliador:"
            value={initialValues.coach}
            width="250px"
            disabled
          />
          {initialValues.status === 'Aberta' && (
            <ButtonWrapper>
              <ButtonComponent text={!editMode ? 'Salvar' : 'Gerar relatórios'} />
            </ButtonWrapper>
          )}
        </FormContainer>
      )}
    </Formik>
  )
}

export default AddEditPyshicalPerformanceForm
