import { useMemo } from 'react'
import {
  Panel,
  TableComponent,
  BreadcrumbItem,
  TableActions,
  ActiveLabel,
} from '../../../components'
import { useFranchisesContainer } from './useFranchisesContainer'

function Franchises() {
  const {
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    total,
    setCurrentPage,
    editFranchise,
    isFetching,
    panelButtons,
  } = useFranchisesContainer()

  const columns = useMemo(
    () => [
      {
        Header: 'Unidade',
        accessor: 'name',
        width: 100,
      },
      {
        Header: 'CNPJ',
        accessor: 'cnpj',
        width: 80,
      },
      {
        Header: 'Bairro',
        accessor: 'neighborhood',
        width: 120,
      },
      {
        Header: 'Cidade',
        accessor: 'city',
        width: 120,
      },
      {
        Header: 'Ativo',
        accessor: 'active',
        width: 40,
        Cell: (props: any) => {
          const {
            row: {
              original: { active },
            },
          } = props
          return <ActiveLabel isActive={!!active} />
        },
      },
      {
        Header: 'Ações',
        accessor: 'actions',
        width: 50,
        noSort: true,
        Cell: (props: any) => {
          const {
            row: {
              original: { id: userId },
            },
          } = props
          return (
            <TableActions
              actionsList={[
                {
                  type: 'edit',
                  title: 'Editar',
                  handleClick: () => editFranchise(userId),
                },
              ]}
            />
          )
        },
      },
    ],
    [editFranchise]
  )

  const breadcrumbs = [
    <BreadcrumbItem key="geral" text="Geral" link="/geral/unidades" />,
    <BreadcrumbItem key="unidades" text="Unidades" />,
  ]

  return (
    <Panel title="Unidades" buttons={panelButtons} isLoading={isFetching} breadcrumbs={breadcrumbs}>
      <TableComponent
        columns={columns}
        data={tableData}
        canPreviousPage={currentPage !== 1}
        canNextPage={currentPage !== Math.ceil(total / pageSize)}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        fetching={isFetching}
        setCurrentPage={setCurrentPage}
        totalPages={Math.ceil(total / pageSize)}
      />
    </Panel>
  )
}

export default Franchises
