import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMutation } from 'urql'
import { useTeamByIdQuery } from '../../../../../hooks/Teams/useTeams'
import { handleErrors } from '../../../../../utils/handleGraphQLErrors'
import { UPDATE_TEAM } from '../../../../../queries/teams'
import { toastDanger, toastSuccess } from '../../../../../redux/toast/actions'

export const useAddTeamAthletes = () => {
  const [checkedIds, setCheckedIds] = useState<number[]>([])

  const navigate = useNavigate()
  const [updateTeamResult, updateTeam] = useMutation(UPDATE_TEAM)

  const { teamId } = useParams()
  const dispatch = useDispatch()
  const { data, isFetching: isFetchingTeam } = useTeamByIdQuery(false, {
    id: parseInt(teamId || '0', 10),
    isAthleteActive: true,
  })

  const isFetching = updateTeamResult.fetching

  const handleSubmit = async () => {
    const result = await updateTeam({
      id: parseInt(teamId || '0', 10),
      name: data.name,
      franchiseId: data.franchise.id,
      days: data.days,
      gender: data.gender,
      athletesId: checkedIds,
      active: data.active,
    })

    if (result?.error) {
      const [error] = handleErrors(result?.error)
      dispatch(toastDanger(error))
    } else {
      dispatch(toastSuccess('Atletas inseridos na turma com sucesso!'))
      navigate(`/geral/turmas/${teamId}`)
    }
  }
  const backButton = { name: 'Voltar', onClick: () => navigate(`/geral/turmas/${teamId}`) }

  useEffect(() => {
    if (data && !isFetchingTeam) {
      const athletesIds = data.athletes.map((item) => item.id)
      setCheckedIds(athletesIds)
    }
  }, [data, isFetchingTeam])

  return {
    teamId,
    data,
    isFetching,
    checkedIds,
    setCheckedIds,
    handleSubmit,
    backButton,
  }
}
