import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetAllAthletes, useGetAllAthletesByTeam } from '../../hooks/Athletes/useAthletes'
import { useAthletesTableProps } from './interface'

export const useAthletesTable = ({
  editMode,
  isAddRemaining,
  teamId,
  franchiseId,
  genderName,
  checkedIds,
  setCheckedIds,
  isOpen,
}: useAthletesTableProps) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(100)
  const [tableData, setTableData] = useState([] as any)
  const [total, setTotal] = useState(0)
  const [displayAlert, setDisplayAlert] = useState<boolean>(false)

  const { data: dataGetAllAthletes, isFetching: isFetchingAllAthletes } = useGetAllAthletes(
    !franchiseId || !genderName || !!editMode,
    {
      ...(franchiseId && { filterFranchisesIds: [franchiseId] }),
      ...(genderName && { gender: genderName }),
      status: true,
      pageSize,
      pageNumber: currentPage,
    }
  )

  const navigate = useNavigate()

  const teamIdParsed = typeof teamId === 'string' ? parseInt(teamId, 10) : 0
  const {
    data: dataGetAthletesByTeamId,
    isFetching: isFetchingAthletesByTeamId,
    reExecute,
  } = useGetAllAthletesByTeam(!editMode || !teamIdParsed, {
    id: teamIdParsed,
    isAthleteActive: true,
  })

  const isAthleteChecked = (athleteId: number) => !!checkedIds.includes(athleteId)

  const handleCheck = useCallback(
    (athleteId: number, tableList: any[]) => {
      let listCheckedIds: number[] = []
      if (!checkedIds.includes(athleteId)) listCheckedIds = [...checkedIds, athleteId]
      else listCheckedIds = checkedIds.filter((idAthlete) => idAthlete !== athleteId)

      const newTableData = tableList.map((item: any) => {
        if (item?.id === athleteId) {
          const newItem = {
            ...item,
            checked: !!listCheckedIds.includes(athleteId),
          }
          return newItem
        }
        return item
      })

      setTableData(newTableData)
      setCheckedIds(listCheckedIds)
    },
    [checkedIds, setCheckedIds]
  )

  const handleRemoveAthleteOfTeam = (athleteId: number) => {
    const checkedList = [...checkedIds]
    const newCheckedList = checkedList.filter((idNumber) => idNumber !== athleteId)
    setCheckedIds(newCheckedList)

    const tableList = [...tableData]
    const newTableList = tableList.filter((tableItem) => tableItem.id !== athleteId)
    setTableData(newTableList ?? [])
    setTotal(newTableList?.length ?? 0)
    setDisplayAlert(true)
  }

  const editAthlete = (athleteId: string | number) => navigate(`/geral/atletas/${athleteId}`)

  useEffect(() => {
    if (!isFetchingAllAthletes && dataGetAllAthletes) {
      const dataMapped = dataGetAllAthletes?.data
        .sort((a, b) => (a?.name < b?.name ? -1 : 1))
        .map((item) => ({
          checked: false,
          id: item?.id,
          name: item?.name,
          franchises: item?.user?.franchises || '',
        }))
      setTableData(dataMapped ?? [])
      setTotal(dataGetAllAthletes?.total ?? 0)
    }
  }, [dataGetAllAthletes, isFetchingAllAthletes])

  useEffect(() => {
    if (editMode && !isFetchingAthletesByTeamId && dataGetAthletesByTeamId) {
      const data = dataGetAthletesByTeamId?.teamAthletes
      const dataSorted = data?.sort((a, b) => (a?.name < b?.name ? -1 : 1))
      setTableData(dataSorted ?? [])
      setTotal(data?.length ?? 0)
      const newAthleteIds = data?.map((item) => item?.id) ?? []
      setCheckedIds(newAthleteIds)
    }
  }, [dataGetAthletesByTeamId, editMode, isFetchingAthletesByTeamId, setCheckedIds])

  useEffect(() => {
    if (isAddRemaining && !isFetchingAthletesByTeamId && dataGetAthletesByTeamId) {
      const data = dataGetAthletesByTeamId?.remainingAthletes
      const dataSorted = data?.sort((a, b) => (a?.name < b?.name ? -1 : 1))
      setTableData(dataSorted ?? [])
      setTotal(data?.length ?? 0)
    }
  }, [dataGetAthletesByTeamId, isAddRemaining, isFetchingAthletesByTeamId])

  useEffect(() => {
    if (!franchiseId || !genderName) {
      setTableData([])
      setTotal(0)
    }
  }, [franchiseId, genderName])

  useEffect(() => {
    if (!isOpen) reExecute({ requestPolicy: 'network-only' })
  }, [reExecute, isOpen])

  return {
    isFetching: isFetchingAllAthletes || isFetchingAthletesByTeamId,
    tableData,
    currentPage,
    setCurrentPage,
    total,
    isAthleteChecked,
    handleCheck,
    pageSize,
    setPageSize,
    handleRemoveAthleteOfTeam,
    displayAlert,
    editAthlete,
  }
}
