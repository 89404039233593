import { Text, View, Image, StyleSheet } from '@react-pdf/renderer'
import { PdfReportProps } from '../../../../@types/attendanceList'
import IDSports from '../../../../assets/logoID.png'

export const styles = StyleSheet.create({
  logo: {
    width: 'auto',
    height: 60,
    textAlign: 'center',
    marginBottom: 20,
  },
  titleWhite: {
    fontFamily: 'Times-Bold',
    textAlign: 'center',
    fontSize: 14,
    color: '#fff',
  },

  titleBlack: {
    fontFamily: 'Times-Bold',
    textAlign: 'center',
    fontSize: 18,
    color: '#000',
    marginBottom: 8,
  },

  boxGolden: {
    backgroundColor: '#c9b37d',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '3px',
    width: '100%',
    padding: 4,
  },
  boxGreen: {
    backgroundColor: '#2D7275',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '3px',
    width: '100%',
    padding: 4,
  },
  boxWhite: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 6,
  },
  boxPresenceHeaderResults: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  boxPresenceResults: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  boxTable: {
    width: '100%',
    flexDirection: 'row',
    textAlign: 'center',
    justifyContent: 'center',
    margin: 0,
    padding: 0,
  },
  tableTitle: {
    width: '100%',
    fontSize: 11,
    color: 'white',
    backgroundColor: '#2D7275',
    borderCollapse: 'collapse',
    fontFamily: 'Times-Bold',
    borderBottom: '1px solid #707070',
  },
  gridBoxTitle: {
    width: '100%',
    textAlign: 'center',
    fontSize: 14,
    fontFamily: 'Times-Bold',
  },
  tableDescription: {
    width: '100%',
    fontSize: 12,
    fontFamily: 'Times-Roman',
    margin: 0,
    padding: 0,
  },
  gridDescription: {
    width: '100%',
    fontSize: 14,
    fontFamily: 'Times-Roman',
    margin: 0,
    padding: 0,
  },
  boxTableItemText: {
    width: 310,
    border: '1px solid #707070',
    margin: 0,
    padding: 0,
  },
  boxTablePse: {
    width: 40,
    border: '1px solid #707070',
    margin: 0,
    padding: 0,
  },
  boxTableItemNumber: {
    width: 40,
    border: '1px solid #707070',
    margin: 0,
    padding: 0,
  },
  boxTableDividedByTree: {
    width: '50%',
    margin: 0,
    padding: 0,
  },
  space: {
    marginTop: 10,
  },
  boxTableDividedByFor: {
    width: 150,
    display: 'flex',
    alignItems: 'flex-start',
  },
})

function PageHeader(period: string, franchiseName: string, ownerName: string, teamName: string) {
  return (
    <>
      <View style={styles.boxWhite}>
        <Image src={IDSports} style={styles.logo} />
        <Text style={styles.titleBlack}>CONTROLE DE PRESENÇA</Text>
        <View style={styles.boxTableDividedByTree}>
          <Text style={styles.gridBoxTitle}>{period}</Text>
        </View>
        <View style={styles.boxTableDividedByTree}>
          <Text style={styles.gridBoxTitle}>
            Unidade:
            {franchiseName}
          </Text>
        </View>
      </View>
      <View style={styles.space} />
      <View style={styles.boxGreen}>
        <Text style={styles.titleWhite}>LEGENDAS</Text>
      </View>
      <View style={styles.space} />
      <View style={styles.boxTable}>
        <View style={styles.boxTableDividedByFor}>
          <Text style={styles.gridBoxTitle}>P : Presença</Text>
        </View>
        <View style={styles.boxTableDividedByFor}>
          <Text style={styles.gridBoxTitle}>F : Falta</Text>
        </View>
        <View style={styles.boxTableDividedByFor}>
          <Text style={styles.gridBoxTitle}>FJ : Falta Justificada</Text>
        </View>
        <View style={styles.boxTableDividedByFor}>
          <Text style={styles.gridBoxTitle}>T : Total de treinos</Text>
        </View>
      </View>
      <View style={styles.space} />

      <View style={styles.boxGolden}>
        <Text style={styles.titleWhite}>INFORMAÇÕES</Text>
      </View>
      <View style={styles.space} />
      <View style={styles.boxTable}>
        <View style={styles.boxTableDividedByTree}>
          <Text style={styles.gridBoxTitle}>RESPONSÁVEL:</Text>
          <Text style={styles.gridDescription}>{ownerName}</Text>
        </View>

        <View style={styles.boxTableDividedByTree}>
          <Text style={styles.gridBoxTitle}>TURMA:</Text>
          <Text style={styles.gridDescription}>{`${teamName} (PSE: 0)`}</Text>
        </View>
      </View>
    </>
  )
}

function TableHeader() {
  return (
    <View style={styles.boxPresenceHeaderResults}>
      <View style={styles.boxTable}>
        <View style={styles.boxTableItemText}>
          <Text style={styles.tableTitle}>Atleta</Text>
        </View>
        <View style={styles.boxTablePse}>
          <Text style={styles.tableTitle}>PSE</Text>
        </View>
        <View style={styles.boxTableItemNumber}>
          <Text style={styles.tableTitle}>P (%)</Text>
        </View>
        <View style={styles.boxTableItemNumber}>
          <Text style={styles.tableTitle}>P</Text>
        </View>
        <View style={styles.boxTableItemNumber}>
          <Text style={styles.tableTitle}>F</Text>
        </View>
        <View style={styles.boxTableItemNumber}>
          <Text style={styles.tableTitle}>F.J</Text>
        </View>
        <View style={styles.boxTableItemNumber}>
          <Text style={styles.tableTitle}>T</Text>
        </View>
      </View>
    </View>
  )
}

function PdfReport({
  period,
  franchiseName,
  ownerName,
  teamName,
  reportsInfo,
  noDisplayHeader,
}: Readonly<PdfReportProps>) {
  return (
    <View style={{ height: 780 }}>
      {!noDisplayHeader ? PageHeader(period, franchiseName, ownerName, teamName) : null}
      <View style={styles.space} />
      <TableHeader />
      <View style={styles.boxPresenceResults}>
        {reportsInfo?.map((item, key) => (
          <View style={styles.boxTable} key={key}>
            <View style={styles.boxTableItemText}>
              <Text style={styles.tableDescription}>{item?.athleteName}</Text>
            </View>
            <View style={styles.boxTablePse}>
              <Text style={styles.tableDescription}>{item?.pseAvg ?? '-'}</Text>
            </View>
            <View style={styles.boxTableItemNumber}>
              <Text style={styles.tableDescription}>{`${item?.pPercentage.toFixed(2)}`}</Text>
            </View>
            <View style={styles.boxTableItemNumber}>
              <Text style={styles.tableDescription}>{item?.pCount}</Text>
            </View>
            <View style={styles.boxTableItemNumber}>
              <Text style={styles.tableDescription}>{item?.fCount}</Text>
            </View>
            <View style={styles.boxTableItemNumber}>
              <Text style={styles.tableDescription}>{item?.fjCount}</Text>
            </View>
            <View style={styles.boxTableItemNumber}>
              <Text style={styles.tableDescription}>{item?.total}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  )
}

export default PdfReport
