import styled from 'styled-components'
import { useDashboardLayout } from '../../hooks/useDashboardLayout'
import { useMenuList } from '../../hooks/useMenuList'
import { MainMenuItemIT, MenuItemIT } from '../../@types/menu'

const PageWrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  padding: ${(props) => props.theme.spacing.spc12};
  border-radius: ${(props) => props.theme.spacing.spc12};
  box-sizing: border-box;
`

const MenuText = styled.h3`
  font-weight: bold;
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${(props) => props.theme.spacing.spc35};
`

const ListItem = styled.li`
  ${({ theme }) => `
    color: ${theme.colors.primary};
    display: flex;
    width: 4.5rem;
    align-items: center;
    flex-direction: column;
    padding: ${theme.spacing.spc10} ${theme.spacing.spc20};
    cursor: pointer;
    border-radius: ${theme.spacing.spc6};
    span {
        font-family: Arial, Helvetica, sans-serif;
        font-size: ${theme.fontSizes.font18};
    }
    svg {
        color: ${theme.colors.primary};
        font-size: ${theme.fontSizes.font30};
    }
    &:hover {
        color: ${theme.colors.primary};
        font-weight: bold;
        background: rgb(198, 198, 198, 0.2);
        svg {
        color: ${theme.colors.primary};
        }
    }
  `}
`

const ItemText = styled.p`
  text-align: center;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export default function Config() {
  const { mainMenuList, subMenuList } = useMenuList()
  const { user, handleClickMenu } = useDashboardLayout()

  const shouldDisplaySubMenu = (subMenuItem: MenuItemIT, menuItem: MainMenuItemIT) =>
    subMenuItem.permissions.includes(user?.role) &&
    menuItem.name === subMenuItem.place &&
    !['Dashboard', 'Atletas', 'Turmas', 'Chamadas'].includes(subMenuItem.name)

  return (
    <PageWrapper>
      {mainMenuList.map((menuItem) =>
        menuItem.permissions.includes(user?.role) ? (
          <div key={menuItem.name}>
            <MenuText>{menuItem.name}</MenuText>
            <List>
              {subMenuList.map((subMenuItem, index) =>
                shouldDisplaySubMenu(subMenuItem, menuItem) ? (
                  <ListItem
                    key={`sub-menu-${index}`}
                    onClick={() => handleClickMenu(subMenuItem.link)}
                  >
                    {subMenuItem.icon}
                    <ItemText>{subMenuItem.name}</ItemText>
                  </ListItem>
                ) : null
              )}
            </List>
          </div>
        ) : null
      )}
    </PageWrapper>
  )
}
