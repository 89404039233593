import {
  ChangeFilterFranchiseActionInterface,
  ChangeFilterFranchiseResetActionInterface,
  SelectedFranchiseOption,
} from '../types/globalFilter'

export const changeFilterFranchiseAction = (
  options: SelectedFranchiseOption[]
): ChangeFilterFranchiseActionInterface => ({
  payload: { selectedFranchises: options },
  type: 'CHANGE_FILTER_FRANCHISE',
})

export const changeFilterFranchiseResetAction = (): ChangeFilterFranchiseResetActionInterface => ({
  type: 'CHANGE_FILTER_FRANCHISE_RESET',
})
