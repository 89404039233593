import { useEffect, useState } from 'react'
import { FormikHelpers } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from 'urql'
import { useDispatch } from 'react-redux'
import { toastDanger, toastSuccess } from '../../../../redux/toast/actions'
import { handleErrors } from '../../../../utils/handleGraphQLErrors'
import { ADD_EXP_TRAINING, UPDATE_EXP_TRAINING } from '../../../../queries/expTraining'
import { ExpTrainingFormValues } from '../../../../@types/expTrainings'
import { useGetExpTrainingById } from '../../../../hooks/ExperimentalTrainings/useExpTrainings'
import { useTeamsQuery } from '../../../../hooks/Teams/useTeams'

export const useAddEditExpTraining = () => {
  const { id: expTrainingId } = useParams()
  const isEditMode = expTrainingId !== 'addExpTraining'

  const [addExpTrainingResult, addExpTraining] = useMutation(ADD_EXP_TRAINING)
  const [updateExpTrainingResult, updateExpTraining] = useMutation(UPDATE_EXP_TRAINING)
  const [dataQuery, errorQuery, isFetchingQuery] = useGetExpTrainingById(!isEditMode, {
    id: parseInt(expTrainingId ?? '0', 10),
  })

  const { data: dataTeams, isFetching: isFetchingTeams } = useTeamsQuery(false, {})

  const isFetching =
    addExpTrainingResult?.fetching ||
    updateExpTrainingResult?.fetching ||
    isFetchingQuery ||
    isFetchingTeams

  const [initialValues, setInitialValues] = useState<ExpTrainingFormValues>({
    name: '',
    email: '',
    phone: '',
    franchiseId: 0,
    teamId: 0,
    birthday: null,
  })

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isEditMode) {
      if (errorQuery) dispatch(toastDanger('Erro ao buscar treino exp!'))

      setInitialValues({
        name: dataQuery?.name,
        email: dataQuery?.email,
        phone: dataQuery?.phone,
        franchiseId: dataQuery?.franchise?.id ?? 0,
        teamId: dataQuery?.team?.id ?? 0,
        birthday: dataQuery?.birthday ? Date.parse(dataQuery.birthday) : null,
      })
    }
  }, [dispatch, isEditMode, dataQuery, errorQuery])

  const handleSubmit = async (
    values: ExpTrainingFormValues,
    actions: FormikHelpers<ExpTrainingFormValues>
  ) => {
    const variablesAddEditFranchise = {
      ...(isEditMode && { id: parseInt(expTrainingId || '0', 10) }),
      name: values.name,
      email: values.email,
      phone: values.phone,
      franchiseId: values.franchiseId,
      teamId: values.teamId,
      birthday: values.birthday,
    }
    if (!isEditMode) {
      const response = await addExpTraining(variablesAddEditFranchise)
      if (response?.error) {
        const [error] = handleErrors(response?.error)
        dispatch(toastDanger(error))
      } else {
        dispatch(toastSuccess('Treino experimental criado com sucesso!'))
        navigate('/geral/experimental-trainings')
      }
    } else {
      const response = await updateExpTraining(variablesAddEditFranchise)
      if (response?.error) {
        const [error] = handleErrors(response?.error)
        dispatch(toastDanger(error))
      } else {
        dispatch(toastSuccess('Treino experimental atualizado com sucesso!'))
        navigate('/geral/experimental-trainings')
      }
    }
    actions.setSubmitting(false)
  }

  const handleGoBack = () => navigate('/geral/experimental-trainings')
  const backButton = { name: 'Voltar', onClick: handleGoBack }
  const title = isEditMode ? 'Editar treino exp.' : 'Adicionar treino exp.'

  return {
    isFetching,
    initialValues,
    handleSubmit,
    handleGoBack,
    isEditMode,
    backButton,
    title,
    dataTeams,
  }
}
