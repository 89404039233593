import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Add as AddIcon } from '@mui/icons-material'
import { isEmpty } from 'lodash'
import { useMutation } from 'urql'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../redux/store'
import { useTeamsQuery } from '../../../hooks/Teams/useTeams'
import { filterFranchisesIds } from '../../../utils/utils'
import { toastDanger, toastSuccess } from '../../../redux/toast/actions'
import { handleErrors } from '../../../utils/handleGraphQLErrors'
import { DELETE_TEAM } from '../../../queries/teams'

export const useTeamsContainer = (isOpen: boolean) => {
  const {
    auth: { user },
    globalFilter: { selectedFranchises },
  } = useAppSelector((state) => state)

  const isAthlete = user?.role === 'Atleta'
  const { data, isFetching, reExecute } = useTeamsQuery(isAthlete, {
    ...(!isEmpty(selectedFranchises) && {
      filterFranchisesIds: filterFranchisesIds(selectedFranchises),
    }),
  })

  const [tableData, setTableData] = useState([] as any)
  const [pageSize, setPageSize] = useState(100)
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(0)

  const navigate = useNavigate()
  const addTeam = () => navigate(`/geral/turmas/addTeam`)
  const editTeam = (teamId: any) => navigate(`/geral/turmas/${teamId}`)

  const panelButtons = [
    {
      onClick: addTeam,
      buttonIcon: <AddIcon />,
      name: 'Criar turma',
    },
  ]

  useEffect(() => {
    if (!isFetching && data?.data) {
      const teamList = data?.data?.map((team: any) => {
        const newStartTime = `${team?.startTime.split(':')[0]}:${team?.startTime.split(':')[1]}`
        const newEndTime = `${team?.endTime.split(':')[0]}:${team?.endTime.split(':')[1]}`
        return {
          id: team?.id,
          name: team?.name,
          gender: team?.gender,
          days: team?.days,
          franchise: team?.franchise?.name,
          schedule: `${newStartTime} - ${newEndTime}`,
          active: team?.active,
        }
      })
      setTableData(teamList ?? [])
      setTotal(data?.total ?? 0)
    }
  }, [data, isFetching])

  useEffect(() => {
    if (!isOpen) reExecute({ requestPolicy: 'network-only' })
  }, [isOpen, reExecute])

  return {
    isFetching,
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    total,
    addTeam,
    editTeam,
    panelButtons,
  }
}

export const useDeleteTeam = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [deleteOptions, setDeleteOptions] = useState({ id: '', name: '' })

  const [deleteTeamResult, deleteTeam] = useMutation(DELETE_TEAM)

  const dispatch = useDispatch()

  const handleDeleteTeam = (teamId: string, teamName: string) => {
    setDeleteOptions({ id: teamId ?? '0', name: teamName })
    setIsOpen(true)
  }

  const handleDeleteAction = () => {
    deleteTeam({
      id: parseInt(deleteOptions.id, 10),
    })
      .then((result: any) => {
        if (result?.error) {
          const [error] = handleErrors(result?.error)
          dispatch(toastDanger(error))
        } else {
          dispatch(toastSuccess('Turma removida com sucesso!'))
          setIsOpen(false)
          setDeleteOptions({ id: '', name: '' })
        }
      })
      .catch(() => {
        dispatch(toastDanger('Erro ao remover turma'))
      })
  }

  useEffect(() => {
    if (!isOpen) setDeleteOptions({ id: '', name: '' })
  }, [isOpen])

  const isFetchingDelete = deleteTeamResult.fetching

  return {
    handleDeleteTeam,
    handleDeleteAction,
    isOpen,
    setIsOpen,
    deleteOptions,
    isFetchingDelete,
  }
}
