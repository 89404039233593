import styled from 'styled-components'
import { Formik, Form, FormikProps } from 'formik'
import { Search as SearchIcon } from '@mui/icons-material'
import { isEmpty } from 'lodash'
import { OtherProps } from '../../../@types'
import {
  AttendanceListFilterFormValues,
  AttendanceListFilterFormValuesProps,
} from '../../../@types/attendanceList'
import { ButtonComponent, DatePickerComponent, SelectComponent } from '../../../components'
import { TeamPublic } from '../../../@types/responses'

const FormContainer = styled(Form)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border-bottom: 1px solid ${(props) => props?.theme?.colors?.greyLight};
  padding-bottom: ${(props) => props?.theme?.spacing?.spc20};
  margin-bottom: ${(props) => props?.theme?.spacing?.spc20};
`

const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

function ReportAttendanceFilterForm({
  handleSubmit,
  initialValues,
  dataTeams,
  franchisesIds,
}: AttendanceListFilterFormValuesProps) {
  const teamsOptions = () => {
    if (isEmpty(franchisesIds)) {
      return (
        dataTeams?.data?.map((team: TeamPublic) => ({ label: team?.name, value: team?.id })) ?? []
      )
    }

    return (
      dataTeams?.data
        ?.filter((team: TeamPublic) => franchisesIds?.includes(team?.franchise?.id))
        ?.map((team: TeamPublic) => ({ label: team?.name, value: team?.id })) ?? []
    )
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      enableReinitialize
    >
      {(props: OtherProps & FormikProps<AttendanceListFilterFormValues>) => (
        <FormContainer>
          <DatePickerComponent
            name="attendanceMonth"
            label="Mês da chamada:"
            dateFormat="MMMM yyyy"
            value={props.values.attendanceMonth}
            props={props}
            width="250px"
            disableGrow
          />
          <SelectComponent
            name="team"
            label="Turma:"
            placeholderText="Selecione a turma.."
            options={teamsOptions()}
            value={props.values.team}
            props={props}
            width="350px"
            isMulti={false}
            disableGrow
          />
          <ButtonWrapper>
            <ButtonComponent buttonIcon={<SearchIcon />} text="Pequisar" />
          </ButtonWrapper>
        </FormContainer>
      )}
    </Formik>
  )
}

export default ReportAttendanceFilterForm
