import styled from 'styled-components'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { HeaderGroup, Row, TableBodyPropGetter, TableBodyProps } from 'react-table'
import { ColumnHeaderProps } from './interface'

const ColumnHeader = styled('div')<Pick<ColumnHeaderProps, 'width'>>`
  display: flex;
  svg {
    font-size: ${(props) => props?.theme?.fontSizes?.font18};
  }
  ${(props) => (props?.width ? `width: ${props?.width}px;` : '')}
  min-width: 20px;
`

interface TableDesktopProps {
  getTableBodyProps: (propGetter?: TableBodyPropGetter<any> | undefined) => TableBodyProps
  headerGroups: HeaderGroup<{ hideMobile?: boolean; mobileName?: string }>[]
  prepareRow: (row: Row<any>) => void
  dynamically?: boolean
  rows: Row<any>[]
  currentPage: number
  pageSize: number
}

const TableDesktop = ({
  getTableBodyProps,
  headerGroups,
  prepareRow,
  dynamically,
  rows,
  currentPage,
  pageSize,
}: TableDesktopProps) => {
  const tableList = dynamically
    ? rows
    : rows.slice((currentPage - 1) * pageSize, currentPage * pageSize)

  return (
    <>
      <thead>
        {headerGroups.map((headerGroup: any) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any, index: number) => (
              <th key={`thead-desktop-${index}`}>
                <ColumnHeader width={column?.width}>
                  {column?.Header !== 'Ações' &&
                    !column?.noSort &&
                    (column.isSortedDesc ? (
                      <ArrowDownwardIcon {...column.getSortByToggleProps()} />
                    ) : (
                      <ArrowUpwardIcon {...column.getSortByToggleProps()} />
                    ))}
                  {column.render('Header')}
                </ColumnHeader>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {tableList.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()} key={`tbody-desktop-${i}`}>
              {row.cells.map((cell, index: number) => (
                <td {...cell.getCellProps()} key={`tbody-desktop-cells-${index}`}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </>
  )
}

export default TableDesktop
