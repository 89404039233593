import { useQuery } from 'urql'
import {
  GetAllAttendanceListTypes,
  GetAllUnavailableDatesTypes,
  ReportAttendanceListQueryTypes,
} from './interface'
import {
  getAllAttendanceList,
  getAllChildrenByAttendanceListById,
  getAllUnavailableDates,
  reportAttendanceListQuery,
} from '../../@types/responses'
import {
  GET_ALL_ATTENDANCE_LIST,
  GET_ALL_CHILDREN_BY_ATTENDANCE_LIST_ID,
  GET_ALL_UNAVAILABLE_DATES,
  REPORT_ATTENDANCE_LIST,
} from '../../queries/attendanceList'
import { IdQueryVariableType } from '../interface'

export const useGetAllAttendanceListQuery = (
  pause: boolean,
  variables: GetAllAttendanceListTypes
) => {
  const [getAllAttendanceListResult, reExecute] = useQuery({
    query: GET_ALL_ATTENDANCE_LIST,
    requestPolicy: 'network-only',
    variables,
    pause,
  })

  const data: getAllAttendanceList = getAllAttendanceListResult.data?.getAllAttendanceList
  const isFetching: boolean = getAllAttendanceListResult.fetching
  const { error } = getAllAttendanceListResult

  return { data, error, isFetching, reExecute }
}

export const useGetAllUnavailableDatesQuery = (
  pause: boolean,
  variables: GetAllUnavailableDatesTypes
) => {
  const [getAllUnavailableDatesResult, reExecute] = useQuery({
    query: GET_ALL_UNAVAILABLE_DATES,
    variables,
    pause,
    requestPolicy: 'network-only',
  })

  const data: getAllUnavailableDates = getAllUnavailableDatesResult.data?.getAllUnavailableDates
  const isFetching: boolean = getAllUnavailableDatesResult.fetching
  const { error } = getAllUnavailableDatesResult

  return { data, error, isFetching, reExecute }
}

export const useGetAllAttendanceListValuesQuery = (
  pause: boolean,
  variables: IdQueryVariableType
) => {
  const [getAllAttendanceListValuesResult, reExecute] = useQuery({
    query: GET_ALL_CHILDREN_BY_ATTENDANCE_LIST_ID,
    variables,
    pause,
    requestPolicy: 'network-only',
  })

  const data: getAllChildrenByAttendanceListById =
    getAllAttendanceListValuesResult.data?.getAllChildrenByAttendanceListById
  const isFetching: boolean = getAllAttendanceListValuesResult.fetching
  const { error } = getAllAttendanceListValuesResult

  return { data, error, isFetching, reExecute }
}

export const useReportAttendanceListQuery = (
  pause: boolean,
  variables: ReportAttendanceListQueryTypes
) => {
  const [reportAttendanceListQueryResult, reExecute] = useQuery({
    query: REPORT_ATTENDANCE_LIST,
    variables,
    pause,
    requestPolicy: 'network-only',
  })

  const data: reportAttendanceListQuery =
    reportAttendanceListQueryResult.data?.reportAttendanceListQuery
  const isFetching: boolean = reportAttendanceListQueryResult.fetching
  const { error } = reportAttendanceListQueryResult

  return { data, error, isFetching, reExecute }
}
