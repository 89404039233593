import styled from 'styled-components'
import { SwitchWrapperProps, ToggleSwitchProps } from './interface'

const SwitchWrapper = styled('div')<SwitchWrapperProps>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  ${(props) => props?.width && `width: ${props?.width};`};

  .switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 25px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #173f37;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #173f37;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`

const LabelSwitch = styled('label')`
  font-size: ${(props) => props?.theme?.fontSizes?.font18};
  font-weight: 600;
  color: ${(props) => props?.theme?.colors?.primary};
`

function ToggleSwitch({
  checked,
  setFieldValue,
  name,
  setToggleSwitch,
  handleOnChange,
  label,
  width,
}: ToggleSwitchProps) {
  const handleOnChangeInternal = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setFieldValue) setFieldValue(name, e.target.checked)
    if (setToggleSwitch) setToggleSwitch(e.target.checked)
  }

  return (
    <SwitchWrapper width={width}>
      {label && <LabelSwitch htmlFor={name}>{label}</LabelSwitch>}
      <label className="switch">
        <input
          type="checkbox"
          name={name}
          checked={checked}
          onChange={handleOnChange ?? handleOnChangeInternal}
        />
        <span className="slider round" />
      </label>
    </SwitchWrapper>
  )
}

export default ToggleSwitch
