import { gql } from 'urql'

export const GET_ALL_COACHES = gql`
  query($filterFranchisesIds: [Int!]) {
    getAllCoaches(filterFranchisesIds: $filterFranchisesIds) {
      data {
        id
        name
        email
        position
        active
        user {
          franchises {
            name
          }
        }
      }
      total
    }
  }
`

export const ADD_COACH = gql`
  mutation(
    $name: String!
    $email: String!
    $phone: String!
    $cpf: String!
    $position: String!
    $franchiseId: Int!
    $active: Boolean!
  ) {
    createCoach(
      input: {
        name: $name
        email: $email
        phone: $phone
        cpf: $cpf
        position: $position
        franchiseId: $franchiseId
        active: $active
      }
    ) {
      id
      name
      email
      active
    }
  }
`

export const UPDATE_COACH = gql`
  mutation(
    $id: Int!
    $name: String!
    $email: String!
    $phone: String!
    $cpf: String!
    $position: String!
    $franchiseId: Int!
    $passwd: String
    $active: Boolean
  ) {
    updateCoach(
      input: {
        id: $id
        name: $name
        email: $email
        passwd: $passwd
        phone: $phone
        cpf: $cpf
        position: $position
        franchiseId: $franchiseId
        active: $active
      }
    ) {
      id
      name
      email
    }
  }
`

export const GET_COACH_BY_ID = gql`
  query($id: Int!) {
    getCoachById(id: $id) {
      id
      name
      email
      cpf
      phone
      position
      active
      user {
        franchises {
          id
        }
      }
    }
  }
`

export const DELETE_COACH = gql`
  mutation($id: Int!, $franchiseId: Int) {
    deleteCoach(id: $id, franchiseId: $franchiseId)
  }
`
