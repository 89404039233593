import { Slider } from '@mui/material'
import styled from 'styled-components'
import { SliderComponentProps, WrapperProps } from './interface'

const FormControlWrapper = styled('div')<WrapperProps>`
  flex: 1 1 ${(props) => (props?.width ? props?.width : '200px')};
  display: flex;
  flex-direction: column;
  margin-bottom: 0.3rem;
  @media screen and (max-width: 769px) {
    width: 100%;
  }
  div {
    display: flex;
    align-items: center;
    width: 90%;
    margin-left: 5%;
  }
  div > span {
    color: ${(props) => props?.theme?.colors?.primary} !important;
  }
  margin-top: ${(props) => props?.theme?.spacing?.spc10};
  label {
    font-size: ${(props) => props?.theme?.fontSizes?.font18};
    font-weight: 600;
    color: ${(props) => props?.theme?.colors?.primary};
    margin-bottom: 8px;
  }
`

const ErrorDiv = styled('div')`
  color: ${(props) => props?.theme?.colors?.red};
  font-size: ${(props) => props?.theme?.fontSizes?.font14};
  font-family: Arial, Helvetica, sans-serif;
  margin-top: ${(props) => props?.theme?.spacing?.spc6};
`

function SliderComponent({ properties, fieldName, label, width }: SliderComponentProps) {
  const onChange = (e: any) => {
    properties.setFieldValue(fieldName, e?.target?.value)
  }
  return (
    <FormControlWrapper width={width}>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <div>
        <Slider
          value={properties.values[fieldName]}
          valueLabelDisplay="auto"
          step={1}
          marks
          min={0}
          max={5}
          color="primary"
          scale={undefined}
          translate="no"
          onChange={onChange}
        />
      </div>
      {properties.touched[fieldName] && properties.errors[fieldName] && (
        <ErrorDiv>{properties.errors[fieldName]}</ErrorDiv>
      )}
    </FormControlWrapper>
  )
}

export default SliderComponent
