import { combineReducers } from 'redux'

import authReducer from './auth/reducers'
import globalFilterReducer from './globalFilter/reducers'
import menuReducer from './menu/reducers'
import toastReducer from './toast/reducers'

const rootReducer = combineReducers({
  toast: toastReducer,
  auth: authReducer,
  menu: menuReducer,
  globalFilter: globalFilterReducer,
})

export type RootReducer = ReturnType<typeof rootReducer>
export default rootReducer
