import { gql } from 'urql'

export const GET_ALL_ATHLETE_RATING = gql`
  query($id: Int!) {
    getAllAthleteRatings(id: $id) {
      data {
        id
        speed
        impulse
        beep
        communication
        technical
        tatical
        physical
        negativePoints
        positivePoints
        reportLink
        createdAt
        athleteId
        athleteName
      }
      total
    }
  }
`

export const ADD_ATHLETE_RATING = gql`
  mutation(
    $speed: Float!
    $impulse: Float!
    $beep: String!
    $communication: Int!
    $technical: Int!
    $tatical: Int!
    $physical: Int!
    $positivePoints: String!
    $negativePoints: String!
    $athleteId: Int!
    $physicalPerformanceId: Int!
  ) {
    createAthleteRating(
      input: {
        speed: $speed
        impulse: $impulse
        beep: $beep
        communication: $communication
        technical: $technical
        tatical: $tatical
        physical: $physical
        positivePoints: $positivePoints
        negativePoints: $negativePoints
        athleteId: $athleteId
        physicalPerformanceId: $physicalPerformanceId
      }
    ) {
      id
    }
  }
`

export const UPDATE_ATHLETE_RATING = gql`
  mutation(
    $id: Int!
    $speed: Float!
    $impulse: Float!
    $beep: String!
    $communication: Int!
    $technical: Int!
    $tatical: Int!
    $physical: Int!
    $positivePoints: String!
    $negativePoints: String!
    $athleteId: Int!
    $physicalPerformanceId: Int!
  ) {
    updateAthleteRating(
      input: {
        id: $id
        speed: $speed
        impulse: $impulse
        beep: $beep
        communication: $communication
        technical: $technical
        tatical: $tatical
        physical: $physical
        positivePoints: $positivePoints
        negativePoints: $negativePoints
        athleteId: $athleteId
        physicalPerformanceId: $physicalPerformanceId
      }
    ) {
      id
    }
  }
`

export const GET_ATHLETE_RATING_BY_ID = gql`
  query($id: Int!) {
    getAthleteRatingById(id: $id) {
      id
      speed
      impulse
      beep
      communication
      technical
      tatical
      physical
      athlete {
        id
        name
        gender
      }
      negativePoints
      positivePoints
      createdAt
    }
  }
`

export const GET_ATHLETE_RATING_BY_ID_REPORT = gql`
  query($id: Int!) {
    getAthleteRatingByIdReport(id: $id) {
      reportInfo {
        id
        speed
        impulse
        beep
        communication
        technical
        tatical
        physical
        negativePoints
        positivePoints
        reportLink
        createdAt
        athleteId
        athleteName
        athleteGender
        userId
        userName
      }
      avg {
        avgSpeed
        avgImpulse
      }
      historyRating {
        year
        month
        speed
        impulse
      }
      historyFrequency {
        month
        year
        pPercentage
      }
    }
  }
`

export const DELETE_ATHLETE_RATING = gql`
  mutation($id: Int!) {
    deleteAthleteRating(id: $id)
  }
`

export const GET_ALL_ATHLETE_RATING_BY_ATHLETE_ID = gql`
  query($athleteId: Int!) {
    getAllAthleteRatingByAthleteId(athleteId: $athleteId) {
      data {
        id
        speed
        impulse
        beep
        communication
        technical
        tatical
        physical
        negativePoints
        positivePoints
        reportLink
        createdAt
        updatedAt
      }
      total
    }
  }
`
