import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import styled from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'
import { PieChartProps, SelectOptionProps } from './interface'

ChartJS.register(ArcElement, Tooltip, Legend)

const ChartWrapper = styled('div')`
  ${({ theme }) => `
      width: 100%;
      max-width: 320px;
      display: flex;
      flex-direction: column;
      background: ${theme.colors.white};
      padding: ${theme?.spacing?.spc10} ${theme?.spacing?.spc10};
      border-radius: ${theme?.spacing?.spc10};
    `}
`

const ChartHeader = styled('div')`
  ${({ theme }) => `
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid ${theme.colors.grey};
      padding: ${theme?.spacing?.spc10} ${theme?.spacing?.spc10};
      font-size: ${theme?.fontSizes?.font18};
      font-weight: bold;
    `}
`

const ChartBody = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
`

const ChartTitle = styled('span')`
  ${({ theme }) => `
      color: ${theme.colors.primary};
      font-size: ${theme?.fontSizes?.font18};
      font-weight: bold;
    `}
`

const SelectWrapper = styled('div')`
  ${({ theme }) => `
      display: flex;
      align-items: center;
      select{
        border: 1px solid ${theme.colors.grey};
        background: ${theme.colors.grey};
        border-radius: ${theme?.spacing?.spc2};
        padding: ${theme?.spacing?.spc2} ${theme?.spacing?.spc2};
        cursor: pointer;
      }
    `}
`

const NoResultsWrapper = styled('div')`
  ${({ theme }) => `
    font-size:${theme?.fontSizes?.font18};
    font-style: italic;
    font-family:Arial, Helvetica, sans-serif;
    color: ${theme.colors.primary};
  `}
`

function PieChart({
  chartTitle,
  selectOptions,
  chartLabels,
  chartData,
  isLoading,
  totalResults,
  searchValue,
  setSearch,
}: PieChartProps) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
  }

  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: chartTitle,
        data: chartData,
        backgroundColor: ['#ff6385d6', '#ffcf56dd', '#6fff56aa'],
        borderColor: ['#f9446b', '#fac641', '#5efc42'],
        borderWidth: 1,
      },
    ],
  }

  const renderBody = () => {
    if (isLoading) {
      return <CircularProgress color="primary" />
    }
    if (chartData?.length > 0) {
      return (
        <>
          <Pie data={data} options={options} />
          <NoResultsWrapper style={{ marginTop: '1rem' }}>Total: {totalResults}</NoResultsWrapper>
        </>
      )
    }
    return <NoResultsWrapper>Sem resultados disponíveis</NoResultsWrapper>
  }

  const handleOnChange = (newValue: string) => setSearch(newValue)

  return (
    <ChartWrapper>
      <ChartHeader>
        <ChartTitle>{chartTitle}</ChartTitle>
        <SelectWrapper>
          {!isLoading && chartData?.length > 0 && (
            <select value={searchValue} onChange={(e) => handleOnChange(e?.target?.value)}>
              {selectOptions?.map((item: SelectOptionProps) => (
                <option value={item?.value}>{item?.name}</option>
              ))}
            </select>
          )}
        </SelectWrapper>
      </ChartHeader>
      <ChartBody>{renderBody()}</ChartBody>
    </ChartWrapper>
  )
}

export default PieChart
