import styled from 'styled-components'
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'
import { PieChart, DisplayNumberChart } from '../../../components'
import { useDashboard, useDashboardAthletes } from './useDashboard'

const DashboardWrapper = styled('div')`
  padding: ${(props) => props?.theme?.spacing?.spc20} ${(props) => props?.theme?.spacing?.spc20};
`

const DisplayNumbersWrapper = styled('div')`
  display: flex;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    & > div {
      margin-top: 0.5rem;
    }
  }
`

function Dashboard() {
  const { active, inactive, isAthlete, isAdminOrAdminFranchise, fetching } = useDashboard()
  const {
    attendanceHistoricOptions,
    attendanceHistoricLabels,
    attendanceHistoricData,
    fetching: fetchingAthletes,
    total: totalAttendanceHistoric,
    search: searchAttendanceHistoric,
    setSearch: setSearchAttendanceHistoric,
  } = useDashboardAthletes()

  return (
    <DashboardWrapper>
      <DisplayNumbersWrapper>
        {isAdminOrAdminFranchise && (
          <>
            <DisplayNumberChart
              color="primary"
              value={active}
              icon={<DirectionsRunIcon />}
              text="Atletas ativos"
              isLoading={fetching}
            />
            <DisplayNumberChart
              color="red"
              value={inactive}
              icon={<DirectionsRunIcon />}
              text="Atletas inativos"
              isLoading={fetching}
            />
          </>
        )}
      </DisplayNumbersWrapper>
      <div>
        {isAthlete && (
          <PieChart
            chartTitle="Histórico de presença"
            selectOptions={attendanceHistoricOptions}
            chartLabels={attendanceHistoricLabels}
            chartData={attendanceHistoricData}
            isLoading={fetchingAthletes}
            totalResults={totalAttendanceHistoric}
            searchValue={searchAttendanceHistoric}
            setSearch={setSearchAttendanceHistoric}
          />
        )}
      </div>
    </DashboardWrapper>
  )
}

export default Dashboard
