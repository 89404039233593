import styled from 'styled-components'
import { AthletesTable, BreadcrumbItem, ButtonComponent, Panel } from '../../../../../components'
import { useAddTeamAthletes } from './useAddTeamAthletes'

const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

function AddTeamAthletes() {
  const {
    teamId,
    data,
    isFetching,
    checkedIds,
    setCheckedIds,
    handleSubmit,
    backButton,
  } = useAddTeamAthletes()

  const breadcrumbs = [
    <BreadcrumbItem key="tecnico" text="Técnico" link="/geral/turmas" />,
    <BreadcrumbItem key="turmas" text="Turmas" link="/geral/turmas" />,
    <BreadcrumbItem key="addEditTeam" text={data?.name ?? ''} link={`/geral/turmas/${teamId}`} />,
    <BreadcrumbItem key="addAthletes" text="Adicionar atletas" />,
  ]

  return (
    <Panel
      title={`Turma ${data?.name ?? ''} - Adicionar atletas`}
      isLoading={isFetching}
      breadcrumbs={breadcrumbs}
      backButton={backButton}
    >
      <AthletesTable
        fetching={isFetching}
        editMode={false}
        checkedIds={checkedIds}
        setCheckedIds={setCheckedIds}
        teamId={teamId}
        isAddRemaining
      />
      <ButtonWrapper>
        <ButtonComponent text="Salvar" onClick={handleSubmit} />
      </ButtonWrapper>
    </Panel>
  )
}

export default AddTeamAthletes
