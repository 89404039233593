import { Close as CloseIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material'
import styled from 'styled-components'
import { Panel, BreadcrumbItem, Overlay } from '../../../../components'
import AddEditAttendList from '../../../../Forms/AddEditAttendanceListForm/AddEditAttendanceList'
import AttendanceListTable from '../../../../components/AttendanceListTable/AttendanceListTable'
import {
  useAddEditAttendanceListContainer,
  useAddUpdateAttendanceList,
} from './useAddEditAttendanceListContainer'
import { useFranchisesCached } from '../../../../hooks/Franchises/useFranchises'
import { AttendanceListAthleteTableInterface } from '../../../../@types/attendanceList'

const OverlayListWrapper = styled('ul')`
  list-style: none;
  padding: 0;
  min-width: 350px;
  li {
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
`

const getItemFormatted = (item: AttendanceListAthleteTableInterface, key: number) => {
  const getIcon = () => {
    if (item?.f || item?.fj) return <CloseIcon style={{ color: 'red' }} />
    return <CheckCircleIcon style={{ color: 'green' }} />
  }

  const getStatus = () => {
    if (item?.fj) return `${item.name} - Falta por ${item.reason}`
    if (item?.f) return `${item.name} - Falta`
    return `${item.name} - Presente`
  }

  return (
    <li key={`confirm-modal-item${key}`}>
      {getIcon()}
      {getStatus()}
    </li>
  )
}

function AddEditAttendanceList() {
  const {
    isFetching,
    tableData,
    setTableData,
    initialValues,
    setInitialValues,
    getLabelValue,
    backButton,
    editMode,
    excludeDates,
    getFieldValue,
    dataTeams,
  } = useAddEditAttendanceListContainer()
  const {
    isFetching: isFetchingAddEdit,
    handleAction,
    isOpen,
    setIsOpen,
    handleSubmit,
  } = useAddUpdateAttendanceList({
    initialValues,
    setInitialValues,
    tableData,
  })

  const { optionsSelect } = useFranchisesCached()

  const breadcrumbs = [
    <BreadcrumbItem key="tecnico" text="Técnico" link="/tecnico/chamadas" />,
    <BreadcrumbItem key="chamadas" text="Chamadas" link="/tecnico/chamadas" />,
    <BreadcrumbItem key="addEditAttendanceList" text={getLabelValue} />,
  ]

  return (
    <Panel
      title={getLabelValue}
      isLoading={isFetching || isFetchingAddEdit}
      breadcrumbs={breadcrumbs}
      backButton={backButton}
    >
      <AddEditAttendList
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        optionsFranchises={optionsSelect}
        optionsTeams={dataTeams}
        editMode={editMode}
        excludeDates={excludeDates}
        getFieldValue={getFieldValue}
      >
        <AttendanceListTable
          fetching={isFetching || isFetchingAddEdit}
          tableData={tableData}
          editMode={editMode}
          setTableData={setTableData}
        />
      </AddEditAttendList>
      <Overlay
        overlayTitle="Confirmação de chamada"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        buttonText="Confirmar"
        handleClick={handleAction}
      >
        <OverlayListWrapper>
          {tableData?.map((rowItem, key) => getItemFormatted(rowItem, key))}
        </OverlayListWrapper>
      </Overlay>
    </Panel>
  )
}

export default AddEditAttendanceList
