import { CSSProperties } from 'react'
import styled from 'styled-components'
import { Tooltip } from '@mui/material'
import Select, { ClearIndicatorProps, MultiValue, SingleValue } from 'react-select'
import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material'
import { isArray } from 'lodash'
import { SelectComponentProps, WrapperProps } from './interface'

const Wrapper = styled('div')<WrapperProps>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props?.disableGrow ? 0 : 1)} 1
    ${(props) => (props?.width ? props?.width : '200px')};
  height: ${(props) => (props?.isLabelled ? '4rem' : 'auto')};
  margin-top: ${(props) => (props?.isLabelled ? props?.theme?.spacing?.spc10 : '0px')};
  @media screen and (max-width: 769px) {
    width: 100%;
    height: 3rem;
  }
  label {
    font-size: ${(props) => props?.theme?.fontSizes?.font18};
    font-weight: 600;
    color: ${(props) => props?.theme?.colors?.primary};
  }
  .clear-icon svg {
    font-size: ${(props) => props?.theme?.fontSizes?.font16};
  }
`

const SelectWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 100%;
  }

  & > svg {
    margin-right: ${(props) => props?.theme?.spacing?.spc6};
    color: ${(props) => props?.theme?.colors?.primary};
  }
`

const ErrorDiv = styled('div')`
  color: ${(props) => props?.theme?.colors?.red};
  font-size: ${(props) => props?.theme?.fontSizes?.font14};
  margin-top: ${(props) => props?.theme?.spacing?.spc6};
`

const ClearIndicator = (props: ClearIndicatorProps<any, true>) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props
  return (
    <div
      className="clear-icon"
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props) as CSSProperties}
    >
      <Tooltip title="Limpar">
        <CloseRoundedIcon />
      </Tooltip>
    </div>
  )
}

function SelectComponent({
  options,
  value,
  setValue,
  props,
  label,
  name,
  disabled,
  isText,
  placeholderText,
  width,
  isMulti,
  icon,
  noSortOptions,
  getFieldValue,
  error,
  disableGrow,
}: SelectComponentProps) {
  const isLabelled = !!label
  const styles = {
    control: (baseStyles: any) => ({
      ...baseStyles,
      background: '#fff',
      border: `1px solid ${props?.errors[name] ? '#cc2f2c' : '#173f37'}`,
      borderRadius: '4px',
      color: '#000',
      ...(isLabelled && { marginTop: '0.4rem' }),
      width: '100%',
      boxShadow: 'none',
    }),
    clearIndicator: (base: any) => ({
      ...base,
      cursor: 'pointer',
      color: '#fff',
      background: '#fff',
      borderRadius: '50%',
      padding: 0,
      marginRight: '5px',
    }),
  }

  const handleOnChangeSingle = (newValue: SingleValue<any>) => {
    if (setValue) setValue(newValue?.value ?? '')
    if (props?.setFieldValue) props?.setFieldValue(name, newValue?.value)
    if (getFieldValue) getFieldValue(name, newValue?.value)
  }

  const handleOnChangeMulti = (newValue: MultiValue<any>) => {
    const newValueMapped = newValue?.map((newOption) => newOption?.value) ?? []
    if (setValue) setValue(newValueMapped)
    if (props?.setFieldValue) props?.setFieldValue(name, newValueMapped)
    if (getFieldValue) getFieldValue(name, newValueMapped)
  }

  const sortedOptions = options?.sort((a, b) => (a?.label > b?.label ? 1 : -1))
  const valueSingle = options?.find((option: any) => option?.value === value)
  const valueMulti = isArray(value)
    ? options?.filter((option: any) => value?.includes(option?.value))
    : []

  return (
    <Wrapper
      width={width}
      disableGrow={disableGrow}
      data-testid="select-component"
      isLabelled={isLabelled}
    >
      {label ? <label htmlFor={name}>{label}</label> : ''}
      {isText && <p>{value}</p>}
      {!isText && (
        <SelectWrapper data-testid="select-component-field">
          {icon && icon}
          {isMulti ? (
            <Select
              components={{ ClearIndicator }}
              styles={styles}
              isSearchable
              isMulti
              isClearable
              placeholder={placeholderText}
              value={valueMulti}
              noOptionsMessage={() => 'Sem opções...'}
              name={name}
              options={noSortOptions ? options : sortedOptions}
              isDisabled={disabled}
              onChange={handleOnChangeMulti}
            />
          ) : (
            <Select
              components={{ ClearIndicator }}
              styles={styles}
              isSearchable
              isClearable
              placeholder={placeholderText}
              value={valueSingle}
              noOptionsMessage={() => 'Sem opções...'}
              name={name}
              options={noSortOptions ? options : sortedOptions}
              isDisabled={disabled}
              onChange={handleOnChangeSingle}
            />
          )}
        </SelectWrapper>
      )}
      {(props?.touched && props?.errors && props?.touched[name] && props?.errors[name]) ||
        (error && <ErrorDiv>{error ?? props?.errors[name]}</ErrorDiv>)}
    </Wrapper>
  )
}

export default SelectComponent
