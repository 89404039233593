import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { Breadcrumbs } from '@mui/material'
import { NavigateNext as NavigateNextIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import { HeaderButtonPanelProps, PanelProps } from './interface'
import Loader from '../Loading/Loading'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

const PanelWrapper = styled.div<{ isMobile: boolean }>`
  margin-bottom: ${(props) => (props.isMobile ? props.theme.spacing.spc60 : '0')};
`

const PanelContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${(props) => props?.theme?.colors?.white};
  border-radius: ${(props) => props?.theme?.spacing?.spc10};
  margin-top: ${(props) => props?.theme?.spacing?.spc20};
  padding: ${(props) => props?.theme?.spacing?.spc20} ${(props) => props?.theme?.spacing?.spc20};
  @media screen and (max-width: 375px) {
    overflow-x: auto;
  }
`

const PanelHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props?.theme?.colors?.greyLight};
  margin-bottom: ${(props) => props?.theme?.spacing?.spc20};
  padding: 0 0 ${(props) => props?.theme?.spacing?.spc10} 0;
`

const PanelTitle = styled('h2')`
  color: ${(props) => props?.theme?.colors?.primary};
  margin: 0;
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
`

const LoadingWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100000;
  overflow: none;
  background: rgb(244, 244, 244, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    height: 60px !important;
    width: 60px !important;
    color: ${(props) => props?.theme?.colors?.middleGreen};
  }
`

const Panel = ({
  title,
  buttons,
  children,
  isLoading = false,
  breadcrumbs,
  backButton,
}: PanelProps) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const isMobileScreen = windowWidth < 769
  const updateDimensions = () => setWindowWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  useEffect(() => {
    if (isLoading) window?.scrollTo(0, 0)
  }, [isLoading])

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Loader />
      </LoadingWrapper>
    )
  }

  return (
    <PanelWrapper data-testid="panel-component" isMobile={isMobileScreen}>
      {breadcrumbs && !isMobileScreen ? (
        <Breadcrumbs
          style={{ marginTop: '1rem' }}
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      ) : null}
      <PanelContentWrapper>
        <PanelHeader>
          <PanelTitle>{title}</PanelTitle>
          <ButtonWrapper>
            {buttons
              ? buttons.map((button: HeaderButtonPanelProps, index: number) => (
                  <ButtonComponent
                    key={`panel-button-${button?.name}-${index}`}
                    type="button"
                    onClick={button.onClick}
                    buttonIcon={button?.buttonIcon}
                    isWhite={button?.isWhite}
                    text={isMobileScreen ? '' : button.name}
                  />
                ))
              : null}
          </ButtonWrapper>
        </PanelHeader>
        {children}
      </PanelContentWrapper>
      {backButton && !isMobileScreen ? (
        <div style={{ marginTop: '1rem' }}>
          <ButtonComponent
            key={`panel-back-button-${backButton?.name}`}
            type="button"
            onClick={backButton?.onClick}
            isWhite
            text={backButton?.name}
            buttonIcon={<ArrowBackIcon />}
          />
        </div>
      ) : null}
    </PanelWrapper>
  )
}

export default Panel
