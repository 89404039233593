// eslint-disable-next-line import/no-extraneous-dependencies
import ptBr from 'date-fns/locale/pt-BR'
import 'react-toastify/dist/ReactToastify.css'
import 'rc-time-picker/assets/index.css'
import 'moment/locale/pt-br'
import 'react-datepicker/dist/react-datepicker.css'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'urql'
import { ToastContainer } from 'react-toastify'
import { registerLocale } from 'react-datepicker'
import { theme, GlobalStyle } from './styles/theme'
import { client } from './graphql'
import AppRouter from './routes'

registerLocale('pt-BR', ptBr)

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer limit={4} />
      <GlobalStyle />
      <Provider value={client}>
        <AppRouter />
      </Provider>
    </ThemeProvider>
  )
}

export default App
