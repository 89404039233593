import { useMemo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { Business as BusinesIcon } from '@mui/icons-material'
import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../redux/store'
import { useFranchisesCached } from '../../hooks/Franchises/useFranchises'
import { FILTER_ALLOWED_PATHS } from '../../utils/constants'
import { SelectComponent } from '..'
import { changeFilterFranchiseAction } from '../../redux/globalFilter/actions'

const FranchiseSelectWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`

function GlobalFilter({ isMobile = false }: { isMobile?: boolean }) {
  const { optionsSelect } = useFranchisesCached()
  const {
    auth: {
      user: { role: userRole },
    },
  } = useAppSelector((state) => state)
  const { options } = useFranchisesCached()
  const [selectValue, setSelectValue] = useState<string[]>([])

  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    const selectedValuesInt = selectValue.map((optionValue) => parseInt(optionValue, 10))
    const franchisesSelected = options
      .filter((franchise) => selectedValuesInt.includes(franchise.id))
      .map((franchise) => ({ id: franchise.id, name: franchise.name }))
    dispatch(changeFilterFranchiseAction(franchisesSelected ?? []))
  }, [dispatch, options, selectValue])

  const shouldDisplayGlobalFilter = useMemo(
    () => FILTER_ALLOWED_PATHS.includes(location.pathname),
    [location.pathname]
  )

  if (
    !shouldDisplayGlobalFilter ||
    (!['Administrador', 'Admin Unidade', 'Treinador'].includes(userRole) && !isEmpty(optionsSelect))
  )
    return null

  return (
    <FranchiseSelectWrapper>
      <SelectComponent
        name="franchise"
        icon={isMobile ? undefined : <BusinesIcon />}
        value={selectValue}
        placeholderText="Escolha as unidades..."
        options={optionsSelect}
        setValue={setSelectValue}
        width="300px"
        isMulti
      />
    </FranchiseSelectWrapper>
  )
}

export default GlobalFilter
