import { useMemo } from 'react'
import { Panel, TableComponent, BreadcrumbItem, TableActions, Overlay } from '../../../components'
import {
  useDeleteExpTrainingList,
  useExperimentalTrainingsContainer,
} from './useExperimentalTrainingsContainer'

function ExperimentalTrainings() {
  const {
    handleDeleteAction,
    handleDeleteExpTraining,
    isOpen,
    setIsOpen,
    deleteOptions,
    isFetchingDelete,
  } = useDeleteExpTrainingList()

  const {
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    total,
    setCurrentPage,
    editExpTraining,
    isFetching,
    panelButtons,
  } = useExperimentalTrainingsContainer(isOpen)

  const columns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name',
        width: 100,
      },
      {
        Header: 'Unidade',
        accessor: 'franchise',
        width: 80,
      },
      {
        Header: 'Turma',
        accessor: 'team',
        width: 100,
      },
      {
        Header: 'Data de treino',
        accessor: 'createdAt',
        width: 150,
        noSort: true,
      },
      {
        Header: 'Ações',
        accessor: 'actions',
        width: 50,
        noSort: true,
        Cell: (props: any) => {
          const {
            row: {
              original: { id: expTrainingId, name },
            },
          } = props
          return (
            <TableActions
              actionsList={[
                {
                  type: 'edit',
                  title: 'Editar',
                  handleClick: () => editExpTraining(expTrainingId),
                },
                {
                  type: 'delete',
                  title: 'Excluir',
                  handleClick: () => handleDeleteExpTraining(expTrainingId, name),
                },
              ]}
            />
          )
        },
      },
    ],
    [editExpTraining, handleDeleteExpTraining]
  )

  const breadcrumbs = [
    <BreadcrumbItem key="geral" text="Geral" link="/geral/experimental-trainings" />,
    <BreadcrumbItem key="treinos-experimentais" text="Treinos experimentais" />,
  ]

  return (
    <Panel
      title="Treinos experimentais"
      buttons={panelButtons}
      isLoading={isFetching || isFetchingDelete}
      breadcrumbs={breadcrumbs}
    >
      <TableComponent
        columns={columns}
        data={tableData}
        canPreviousPage={currentPage !== 1}
        canNextPage={currentPage !== Math.ceil(total / pageSize)}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        fetching={isFetching}
        setCurrentPage={setCurrentPage}
        totalPages={Math.ceil(total / pageSize)}
      />
      <Overlay
        overlayTitle="Excluir treino experimental"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        buttonText="Confirmar"
        handleClick={handleDeleteAction}
      >
        <p>{`Realmente deseja excluir o treino experimental ${deleteOptions.name} ?`}</p>
      </Overlay>
    </Panel>
  )
}

export default ExperimentalTrainings
