import { useMemo } from 'react'
import {
  Add as AddIcon,
  Plagiarism as PlagiarismIcon,
  FilterAltOutlined as FilterAltOffIcon,
  FilterAlt as FilterAltIcon,
} from '@mui/icons-material'
import { Panel, TableComponent, BreadcrumbItem, TableActions, Overlay } from '../../../components'
import {
  useAttendanceListContainer,
  useDeleteAttendanceList,
  useFiltersAttendanceList,
} from './useAttendanceListContainer'
import ReportAttendanceFilterForm from '../../../Forms/Filters/ReportAttendanceFilterForm/ReportAttendanceFilterForm'
import { useTeamsQuery } from '../../../hooks/Teams/useTeams'

function AttendanceList() {
  const {
    handleDeleteAction,
    handleDeleteAttendanceList,
    isOpen,
    setIsOpen,
    deleteOptions,
    isFetchingDelete,
  } = useDeleteAttendanceList()
  const {
    fetching,
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    total,
    editAttendanceList,
    isOpenFilter,
    setIsOpenFilter,
    navigate,
    setFilters,
    franchisesIds,
  } = useAttendanceListContainer(false)

  const { data: dataTeams, isFetching: isFetchingTeams } = useTeamsQuery(false, {})

  const { handleSearch, initialValuesFilter } = useFiltersAttendanceList({
    setFilters,
    setCurrentPage,
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Realização',
        accessor: 'attendanceDay',
        width: 100,
        noSort: true,
        mobileName: 'Realização:',
      },
      {
        Header: 'Unidade',
        accessor: 'franchise',
        width: 150,
        mobileName: 'Unidade:',
        noSort: true,
      },
      {
        Header: 'Turma',
        accessor: 'team',
        width: 150,
        noSort: true,
        mobileName: 'Turma:',
      },
      {
        Header: 'Atualização',
        accessor: 'updatedAt',
        width: 100,
        noSort: true,
        hideMobile: true,
      },
      {
        Header: 'Ações',
        accessor: 'actions',
        width: 20,
        noSort: true,
        Cell: (props: any) => {
          const {
            row: {
              original: { id: attendanceListId, attendanceDay, franchise, team },
            },
          } = props
          const textDeleteAttendance = `${attendanceDay} - ${team}(${franchise})`
          return (
            <TableActions
              actionsList={[
                {
                  type: 'edit',
                  title: 'Editar',
                  handleClick: () => editAttendanceList(attendanceListId),
                },
                {
                  type: 'delete',
                  title: 'Excluir',
                  handleClick: () =>
                    handleDeleteAttendanceList(attendanceListId, textDeleteAttendance),
                },
              ]}
            />
          )
        },
      },
    ],
    [editAttendanceList, handleDeleteAttendanceList]
  )

  const breadcrumbs = [
    <BreadcrumbItem key="tecnico" text="Técnico" link="/tecnico/chamadas" />,
    <BreadcrumbItem key="chamadas" text="Chamadas" />,
  ]

  return (
    <Panel
      title="Chamadas"
      buttons={[
        {
          onClick: () => setIsOpenFilter(!isOpenFilter),
          buttonIcon: isOpenFilter ? <FilterAltOffIcon /> : <FilterAltIcon />,
          name: 'Fitros',
        },
        {
          onClick: () => navigate(`/tecnico/chamadas/filtrar/relatorio`),
          buttonIcon: <PlagiarismIcon />,
          name: 'Relatório',
        },
        {
          onClick: () => navigate(`/tecnico/chamadas/addAttendanceList`),
          buttonIcon: <AddIcon />,
          name: 'Criar chamada',
        },
      ]}
      isLoading={fetching || isFetchingDelete || isFetchingTeams}
      breadcrumbs={breadcrumbs}
    >
      {isOpenFilter ? (
        <ReportAttendanceFilterForm
          handleSubmit={handleSearch}
          initialValues={initialValuesFilter}
          dataTeams={dataTeams}
          franchisesIds={franchisesIds}
        />
      ) : (
        <></>
      )}
      <TableComponent
        columns={columns}
        data={tableData}
        canPreviousPage={currentPage + 1 !== 1}
        canNextPage={currentPage + 1 !== Math.ceil(total / pageSize)}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={Math.ceil(total / pageSize)}
        total={total}
        dynamically
        fetching={fetching}
      />
      <Overlay
        overlayTitle="Excluir chamada"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        buttonText="Confirmar"
        handleClick={handleDeleteAction}
      >
        <p>{`Realmente deseja excluir a chamada ${deleteOptions.name} ?`}</p>
      </Overlay>
    </Panel>
  )
}

export default AttendanceList
