import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTable, useSortBy } from 'react-table'

import Paginator from './Paginator'
import Loader from '../Loading/Loading'
import { TableProps } from './interface'
import TableDesktop from './TableDesktop'
import TableMobile from './TableMobile'

const TableWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  overflow-x: auto;
`

const LoadingWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100000;
  overflow: none;
  background: rgb(244, 244, 244, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    height: 60px !important;
    width: 60px !important;
    color: ${(props) => props?.theme?.colors?.middleGreen};
  }
`

const TableStyled = styled('table')`
  table-layout: auto;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  font-size: ${(props) => props?.theme?.fontSizes?.font18};
  th {
    text-align: left;
    background-color: ${(props) => props?.theme?.colors?.primary};
    color: ${(props) => props?.theme?.colors?.white};
    height: 2rem;
    font-size: ${(props) => props?.theme?.fontSizes?.font18};
  }
  td,
  th {
    padding: 6px;
  }
  tr {
    border-bottom: 1px solid ${(props) => props?.theme?.colors?.greyLight};
  }
  tr:nth-child(even) {
    background-color: #f7f7f7;
  }
  tr:hover {
    background-color: #efefef;
  }

  th:first-of-type {
    border-top-left-radius: 8px;
  }
  th:last-of-type {
    border-top-right-radius: 8px;
  }
  tr:last-of-type td:first-of-type {
    border-bottom-left-radius: 8px;
  }
  tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 8px;
  }
`

const TableResults = styled('div')`
  color: ${(props) => props?.theme?.colors?.black};
  margin-bottom: ${(props) => props?.theme?.spacing?.spc20};
`

const NoResultsWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6rem;
  font-size: ${(props) => props?.theme?.fontSizes?.font18};
  font-style: italic;
  font-family: Arial, Helvetica, sans-serif;
  color: ${(props) => props?.theme?.colors?.primary};
`

const TableComponent = ({
  columns,
  data,
  canPreviousPage,
  canNextPage,
  setPageSize,
  currentPage,
  setCurrentPage,
  pageSize,
  totalPages,
  dynamically,
  total,
  fetching,
  messageNoData,
}: TableProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  )
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const isMobileScreen = windowWidth < 769
  const updateDimensions = () => setWindowWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  useEffect(() => {
    if (dynamically) setCurrentPage(0)
    else setCurrentPage(1)
  }, [dynamically, pageSize, setCurrentPage])

  const previousPage = () => setCurrentPage((currentPageOpt) => currentPageOpt - 1)
  const nextPage = () => setCurrentPage((currentPageOpt) => currentPageOpt + 1)
  const isTableEmpty = rows?.length > 0 || total

  const noDataOutcome = !fetching ? (
    <NoResultsWrapper>{messageNoData ?? 'Sem dados para exibir...'}</NoResultsWrapper>
  ) : (
    <LoadingWrapper>
      <Loader />
    </LoadingWrapper>
  )

  return isTableEmpty && !fetching ? (
    <>
      <TableWrapper>
        <TableResults> Total de {total ?? rows?.length} resultados</TableResults>
        <TableStyled {...getTableProps()}>
          {isMobileScreen ? (
            <TableMobile
              getTableBodyProps={getTableBodyProps}
              prepareRow={prepareRow}
              dynamically={dynamically}
              headerGroups={headerGroups}
              rows={rows}
              currentPage={currentPage}
              pageSize={pageSize}
            />
          ) : (
            <TableDesktop
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              prepareRow={prepareRow}
              dynamically={dynamically}
              rows={rows}
              currentPage={currentPage}
              pageSize={pageSize}
            />
          )}
        </TableStyled>
      </TableWrapper>
      <Paginator
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageSize={pageSize}
        currentPage={currentPage}
        totalPages={totalPages}
        dynamically={dynamically}
        isMobile={isMobileScreen}
      />
    </>
  ) : (
    noDataOutcome
  )
}

export default TableComponent
