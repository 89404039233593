import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'urql'
import { toastDanger } from '../redux/toast/actions'
import { LOGIN_USER } from '../queries/users'
import { useAppSelector } from '../redux/store'
import { LoginFormValues } from '../@types/login'
import { LoginAction } from '../redux/auth/actions'
import { CONSTANTS } from '../utils/constants'

export const validationLogin = Yup.object({
  email: Yup.string()
    .max(45, 'E-mail muito longo')
    .email('Insira um e-mail válido')
    .required('Campo obrigatório'),
  passwd: Yup.string().max(45, 'Senha muito longa').required('Campo obrigatório'),
})

export const useLogin = () => {
  const [loginResult, loginUser] = useMutation(LOGIN_USER)
  const [refreshKey, setRefreshKey] = useState(0)

  const { auth } = useAppSelector((state) => state)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const initialValues: LoginFormValues = { email: '', passwd: '' }

  const handleSubmit = async (values: LoginFormValues, actions: FormikHelpers<LoginFormValues>) => {
    try {
      const result = await loginUser({
        email: values.email,
        passwd: values.passwd,
      })
      if (result?.error) dispatch(toastDanger('Email e/ou senha inválidos!'))
      else {
        dispatch(LoginAction(result?.data?.login?.access_token))
        setRefreshKey(refreshKey + 1)
      }
    } catch {
      dispatch(toastDanger('Erro, tente novamente mais tarde!'))
    }
    actions.setSubmitting(false)
  }

  const handleForget = () => navigate('/recuperar-senha')

  useEffect(() => {
    switch (auth?.user?.role) {
      case CONSTANTS.ROLES.ADMINISTRADOR:
        navigate('/geral/dashboard')
        break
      case CONSTANTS.ROLES.ADMIN_UNIDADE:
        navigate('/geral/dashboard')
        break
      case CONSTANTS.ROLES.TREINADOR:
        navigate(`/tecnico/treinadores/${auth?.coach?.id}`)
        break
      default:
        navigate('/')
        break
    }
  }, [auth?.athlete?.id, auth?.coach?.id, auth?.user?.role, navigate, refreshKey])

  return {
    loginResult,
    initialValues,
    handleSubmit,
    handleForget,
  }
}
