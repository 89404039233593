import { GraphQLBoolean, GraphQLInputObjectType, GraphQLInt, GraphQLString } from 'graphql'
import { gql } from 'urql'

export const GET_ALL_ATTENDANCE_LIST = gql`
  query(
    $pageNumber: Int!
    $pageSize: Int!
    $teamId: Int
    $month: Int
    $year: Int
    $filterFranchisesIds: [Int!]
    $sort: String
  ) {
    getAllAttendanceList(
      input: {
        pageNumber: $pageNumber
        pageSize: $pageSize
        teamId: $teamId
        month: $month
        year: $year
        sort: $sort
      }
      filterFranchisesIds: $filterFranchisesIds
    ) {
      data {
        id
        attendanceDay
        franchise {
          name
        }
        team {
          name
        }
        updatedAt
      }
      total
    }
  }
`

const REPORT_ATTENDANCE_LIST_INPUT = new GraphQLInputObjectType({
  name: 'ReportAttendanceListInput',
  fields: {
    athleteId: { type: GraphQLInt },
    p: { type: GraphQLBoolean },
    f: { type: GraphQLString },
    fj: { type: GraphQLString },
    pse: { type: GraphQLInt },
    reason: { type: GraphQLString },
  },
})

export const ADD_ATTENDANCE_LIST = `
  mutation(
    $attendanceDay: DateTime!,
    $franchiseId: Int!,
    $userId: Int!,
    $teamId: Int!,
    $reports: [${REPORT_ATTENDANCE_LIST_INPUT}!]!
    ) {
      createAttendanceList(input:{
        attendanceDay: $attendanceDay,
        franchiseId: $franchiseId,
        userId: $userId,
        teamId: $teamId, 
        reports: $reports
      }){
        id
      }
  }
`

const UPDATE_ATTENDANCE_LIST_INPUT = new GraphQLInputObjectType({
  name: 'ReportAttendanceListUpdateInput',
  fields: {
    id: { type: GraphQLInt },
    athleteId: { type: GraphQLInt },
    p: { type: GraphQLBoolean },
    f: { type: GraphQLString },
    fj: { type: GraphQLString },
    pse: { type: GraphQLInt },
    reason: { type: GraphQLString },
  },
})

export const UPDATE_ATTENDANCE_LIST = `
  mutation(
    $id: Int!,
    $attendanceDay: DateTime!,
    $reports: [${UPDATE_ATTENDANCE_LIST_INPUT}!]!
    ) {
      updateAttendanceList(input:{
        id: $id,
        attendanceDay: $attendanceDay,
        reports: $reports
      }){
        id
      }
  }
`

export const GET_ALL_CHILDREN_BY_ATTENDANCE_LIST_ID = gql`
  query($id: Int!) {
    getAllChildrenByAttendanceListById(id: $id) {
      id
      attendanceDay
      franchise {
        id
        name
      }
      team {
        id
        name
      }
      items {
        id
        athleteId
        name
        p
        f
        fj
        pse
        reason
      }
    }
  }
`

export const REPORT_ATTENDANCE_LIST = gql`
  query($attendanceDate: DateTime!, $teamId: Int!) {
    reportAttendanceListQuery(input: { attendanceDate: $attendanceDate, teamId: $teamId }) {
      period
      franchiseName
      ownerName
      teamName
      reportItems {
        idNumber
        athleteName
        pseAvg
        pPercentage
        pCount
        fCount
        fjCount
        total
      }
    }
  }
`

export const DELETE_ATTENDANCE_LIST = gql`
  mutation($id: Int!) {
    deleteAttendanceList(id: $id)
  }
`

export const GET_ALL_UNAVAILABLE_DATES = gql`
  query($teamId: Int!) {
    getAllUnavailableDates(teamId: $teamId) {
      unavailableDate
    }
  }
`
