import styled from 'styled-components'
import { Add as AddIcon } from '@mui/icons-material'
import AddEditTeamForm from '../../../../Forms/AddEditTeamForm/AddEditTeamForm'
import { AthletesTable, BreadcrumbItem, ButtonComponent, Panel } from '../../../../components'
import { useAddEditTeamContainer } from './useAddEditTeamContainer'

const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

function AddEditTeam() {
  const {
    backButton,
    isFetching,
    initialValues,
    handleSubmit,
    labelValue,
    isEditMode,
    optionsSelect,
    checkedIds,
    setCheckedIds,
    getFieldValue,
    genderFranchise,
    teamId,
    handleAdAthletes,
  } = useAddEditTeamContainer()

  const breadcrumbs = [
    <BreadcrumbItem key="tecnico" text="Técnico" link="/geral/turmas" />,
    <BreadcrumbItem key="turmas" text="Turmas" link="/geral/turmas" />,
    <BreadcrumbItem key="addEditTeam" text={labelValue} />,
  ]

  return (
    <Panel
      title={labelValue}
      isLoading={isFetching}
      breadcrumbs={breadcrumbs}
      backButton={backButton}
    >
      <AddEditTeamForm
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        optionsFranchises={optionsSelect}
        isEditMode={isEditMode}
        getFieldValue={getFieldValue}
      >
        {isEditMode ? (
          <ButtonWrapper>
            <ButtonComponent
              text="Adicionar atletas"
              buttonIcon={<AddIcon />}
              onClick={handleAdAthletes}
            />
          </ButtonWrapper>
        ) : (
          <></>
        )}
        <AthletesTable
          fetching={isFetching}
          editMode={isEditMode}
          checkedIds={checkedIds}
          setCheckedIds={setCheckedIds}
          genderName={genderFranchise.gender}
          franchiseId={genderFranchise.franchise}
          teamId={teamId}
        />
      </AddEditTeamForm>
    </Panel>
  )
}

export default AddEditTeam
