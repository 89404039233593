import { useQuery } from 'urql'
import { GET_ALL_COACHES, GET_COACH_BY_ID } from '../../queries/coaches'
import { GetCoachesQueryTypes } from './interface'
import { getAllCoaches, getCoachById } from '../../@types/responses'
import { IdQueryVariableType } from '../interface'

export const useCoachesQuery = (pause: boolean, variables: GetCoachesQueryTypes) => {
  const [getAllCoachesResult, reExecute] = useQuery({
    query: GET_ALL_COACHES,
    requestPolicy: 'network-only',
    variables,
    pause,
  })

  const data: getAllCoaches = getAllCoachesResult.data?.getAllCoaches
  const isFetching: boolean = getAllCoachesResult.fetching
  const { error } = getAllCoachesResult

  return { data, error, isFetching, reExecute }
}

export const useCoachByIdQuery = (pause: boolean, variables: IdQueryVariableType) => {
  const [getCoachByIdResult, reExecute] = useQuery({
    query: GET_COACH_BY_ID,
    variables,
    pause,
  })

  const data: getCoachById = getCoachByIdResult.data?.getCoachById
  const isFetching: boolean = getCoachByIdResult.fetching
  const { error } = getCoachByIdResult

  return { data, error, isFetching, reExecute }
}
