import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'urql'
import moment from 'moment'
import { Add as AddIcon } from '@mui/icons-material'
import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../redux/store'
import { DELETE_PHYSICAL_PERFORMANCE } from '../../../queries/physicalPerformance'
import { usePhysicalPerformanceQuery } from '../../../hooks/PhysicalPerformances/usePhysicalPerformances'
import { filterFranchisesIds } from '../../../utils/utils'
import { handleErrors } from '../../../utils/handleGraphQLErrors'
import { toastDanger, toastSuccess } from '../../../redux/toast/actions'

export const usePhysicalPerformanceContainer = (isOpen: boolean) => {
  const {
    auth: { user },
    globalFilter: { selectedFranchises },
  } = useAppSelector((state) => state)

  const { data, isFetching, reExecute } = usePhysicalPerformanceQuery(
    !['Administrador', 'Admin Unidade', 'Treinador'].includes(user?.role),
    {
      ...(!isEmpty(selectedFranchises) && {
        filterFranchisesIds: filterFranchisesIds(selectedFranchises),
      }),
    }
  )

  const [tableData, setTableData] = useState([] as any)
  const [pageSize, setPageSize] = useState(50)
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(0)

  const navigate = useNavigate()

  const addPhysicalPerformance = () => navigate(`/tecnico/avaliacoes/addPhysicalPerformance`)
  const detailsPhysicalPerformance = (userId: any) => navigate(`/tecnico/avaliacoes/${userId}`)

  const panelButtons = [
    {
      onClick: addPhysicalPerformance,
      buttonIcon: <AddIcon />,
      name: 'Criar avaliação',
    },
  ]

  useEffect(() => {
    if (!isOpen) reExecute({ requestPolicy: 'network-only' })
  }, [reExecute, isOpen])

  useEffect(() => {
    if (!isFetching && data) {
      const physicalPerformanceListMapped = data?.data?.map((physicalPerformance: any) => ({
        id: physicalPerformance?.id,
        name: physicalPerformance?.name,
        franchise: physicalPerformance?.franchise?.name,
        user: physicalPerformance?.user?.name,
        team: physicalPerformance?.team?.name,
        status: physicalPerformance?.status,
        createdAt: moment(physicalPerformance?.createdAt).format('DD/MM/YYYY'),
        updatedAt: moment(physicalPerformance?.updatedAt).format('DD/MM/YYYY'),
      }))
      setTableData(physicalPerformanceListMapped ?? [])
      setTotal(data?.total ?? 0)
    }
  }, [data, isFetching])

  return {
    isFetching,
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    total,
    addPhysicalPerformance,
    detailsPhysicalPerformance,
    panelButtons,
  }
}

export const useDeletePhysicalPerformance = () => {
  const [deletePhysicalPerformanceResult, deletePhysicalPerformance] = useMutation(
    DELETE_PHYSICAL_PERFORMANCE
  )
  const [isOpen, setIsOpen] = useState(false)
  const [deleteOptions, setDeleteOptions] = useState({ id: '', name: '' })

  const dispatch = useDispatch()

  const handleDeletePhysicalPerformance = (physicalId: string, physicalName: string) => {
    setDeleteOptions({ id: physicalId ?? '0', name: physicalName })
    setIsOpen(true)
  }

  const handleDeleteAction = () => {
    deletePhysicalPerformance({
      id: parseInt(deleteOptions.id, 10),
    })
      .then((result: any) => {
        if (result?.error) {
          const [error] = handleErrors(result?.error)
          dispatch(toastDanger(error))
        } else {
          dispatch(toastSuccess('Avaliação física removida com sucesso!'))
          setIsOpen(false)
          setDeleteOptions({ id: '', name: '' })
        }
      })
      .catch(() => {
        dispatch(toastDanger('Erro ao remover avaliação física'))
      })
  }

  useEffect(() => {
    if (!isOpen) setDeleteOptions({ id: '', name: '' })
  }, [isOpen])

  const isFetchingDelete = deletePhysicalPerformanceResult.fetching

  return {
    handleDeletePhysicalPerformance,
    handleDeleteAction,
    isOpen,
    setIsOpen,
    deleteOptions,
    isFetchingDelete,
  }
}
