import { BreadcrumbItem, Panel } from '../../../../components'
import { useAddEditCoachContainer } from './useAddEditCoachContainer'
import { useFranchisesCached } from '../../../../hooks/Franchises/useFranchises'
import AddEditCoachForm from '../../../../Forms/AddEditCoachForm/AddEditCoachForm'

function AddEditCoach() {
  const {
    isFetching,
    initialValues,
    handleSubmit,
    getTitle,
    breadcrumbTitle,
    isCoach,
    backButton,
  } = useAddEditCoachContainer()
  const { optionsSelect } = useFranchisesCached()

  const breadcrumbs = [
    <BreadcrumbItem key="tecnico" text="Técnico" link="/tecnico/treinadores" />,
    <BreadcrumbItem key="treinadores" text="Treinadores" link="/tecnico/treinadores" />,
    <BreadcrumbItem key="treinador" text={breadcrumbTitle} />,
  ]

  return (
    <Panel
      title={getTitle()}
      isLoading={isFetching}
      breadcrumbs={!isCoach ? breadcrumbs : []}
      backButton={backButton}
    >
      <AddEditCoachForm
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        optionsFranchises={optionsSelect}
        isCoach={isCoach}
      />
    </Panel>
  )
}

export default AddEditCoach
