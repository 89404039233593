import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Add as AddIcon } from '@mui/icons-material'
import { isEmpty } from 'lodash'
import { useAppSelector } from '../../../redux/store'
import { useGetAllFranchises } from '../../../hooks/Franchises/useFranchises'
import { filterFranchisesIds, isAdmin, isCoachOrAthlete } from '../../../utils/utils'

export const useFranchisesContainer = () => {
  const {
    auth: { user },
    globalFilter: { selectedFranchises },
  } = useAppSelector((state) => state)

  const [isFetching, response] = useGetAllFranchises(isCoachOrAthlete(user?.role), {
    ...(!isEmpty(selectedFranchises) && {
      filterFranchisesIds: filterFranchisesIds(selectedFranchises),
    }),
  })

  const [tableData, setTableData] = useState([])
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(0)

  const navigate = useNavigate()

  const addFranchise = () => navigate(`/geral/unidades/addFranchise`)
  const editFranchise = (userId: any) => navigate(`/geral/unidades/${userId}`)

  const panelButtons = isAdmin(user?.role)
    ? [
        {
          onClick: addFranchise,
          buttonIcon: <AddIcon />,
          name: 'Criar unidade',
        },
      ]
    : undefined

  useEffect(() => {
    if (!isFetching && response) {
      const franchiseMappedList = response?.data?.map((franchiseData: any) => ({
        id: franchiseData?.id,
        name: franchiseData?.name,
        cnpj: franchiseData?.cnpj,
        neighborhood: franchiseData?.neighborhood,
        city: franchiseData?.city,
        active: franchiseData?.active,
      }))
      setTableData(franchiseMappedList ?? [])
      setTotal(response?.total ?? 0)
    }
  }, [isFetching, response])

  return {
    isFetching,
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    total,
    setCurrentPage,
    addFranchise,
    editFranchise,
    panelButtons,
  }
}
