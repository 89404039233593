import {
  RemoveRedEye as RemoveRedEyeIcon,
  DeleteForever as DeleteForeverIcon,
  ModeEdit as ModeEditIcon,
  Download as DownloadIcon,
  HighlightOff as HighlightOffIcon,
} from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import styled from 'styled-components'
import { TableActionIconProps, TableActionProps } from './interface'

const ActionsWrapper = styled('div')`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  svg {
    color: ${(props) => props?.theme?.colors?.primary};
    cursor: pointer;
    font-size: ${(props) => props?.theme?.fontSizes?.font24};
  }
`

const getActionType = (type: TableActionIconProps, handleClick: () => void) => {
  if (type === 'delete') return <DeleteForeverIcon onClick={handleClick} />
  if (type === 'remove') return <HighlightOffIcon onClick={handleClick} />
  if (type === 'edit') return <ModeEditIcon onClick={handleClick} />
  if (type === 'view') return <RemoveRedEyeIcon onClick={handleClick} />
  if (type === 'download') return <DownloadIcon onClick={handleClick} />
  return <span />
}

function TableActions({ actionsList }: TableActionProps) {
  return (
    <ActionsWrapper>
      {actionsList?.map((action, index) => (
        <Tooltip key={`action-item-${index}`} title={action.title}>
          {getActionType(action.type, action.handleClick)}
        </Tooltip>
      ))}
    </ActionsWrapper>
  )
}

export default TableActions
