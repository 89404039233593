import { useMemo } from 'react'
import styled from 'styled-components'
import { Checkbox } from '@mui/material'
import { InputComponent, TableComponent } from '..'
import { AttendanceListAthletesTableProps } from './interface'
import { useAttendanceListTable } from './useAttendanceListTable'

const CheckboxWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const AttendanceWrapper = styled.div`
  ${({ theme }) => `
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    input,
    select {
      width: 90%;
      height: 2rem;
      border-radius:  ${theme.spacing.spc6};
      color: ${theme.colors.black};
    }
  `}
`

const ButtonPse = styled.button`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    color: ${theme?.colors?.black};
    background-color: ${theme?.colors?.white};
    border: 1px solid ${theme?.colors?.primary};
    border-radius: ${theme?.spacing?.spc6};
    &:disabled {
      cursor: not-allowed;
      opacity: 0.2;
    }
  `}
`

interface TableItemProps {
  Header: string
  accessor: string
  width: number
  mobileName?: string
  hideMobile?: boolean
  Cell?: (props: any) => JSX.Element
}

function AttendanceListTable({
  fetching,
  tableData,
  setTableData,
  editMode,
}: Readonly<AttendanceListAthletesTableProps>) {
  const {
    handleCheck,
    handleReason,
    currentPage,
    setCurrentPage,
    data,
    searchValue,
    setSearchValue,
  } = useAttendanceListTable({
    tableData,
    setTableData,
  })

  const columns = useMemo(() => {
    const tableList: TableItemProps[] = [
      {
        Header: 'Atleta',
        accessor: 'name',
        width: 150,
      },
    ]

    if (editMode) {
      tableList.push({
        Header: 'PSE',
        accessor: 'pse',
        width: 80,
        mobileName: 'PSE:',
        Cell: (props: any) => {
          const {
            row: {
              original: { athleteId, pse, p },
            },
          } = props

          return (
            <div
              style={{
                display: 'flex',
                gap: '5px',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.2rem',
              }}
            >
              {p ? (
                <>
                  <ButtonPse
                    type="button"
                    onClick={() => handleCheck(athleteId, 'PSE', pse - 1)}
                    disabled={pse === 0}
                  >
                    -
                  </ButtonPse>
                  <span style={{ width: '2rem', textAlign: 'center' }}>{pse}</span>
                  <ButtonPse
                    type="button"
                    onClick={() => handleCheck(athleteId, 'PSE', pse + 1)}
                    disabled={pse === 10}
                  >
                    +
                  </ButtonPse>
                </>
              ) : (
                <span>-</span>
              )}
            </div>
          )
        },
      })
    }

    const tableListRender = [
      {
        Header: 'Presença',
        accessor: 'p',
        width: 60,
        mobileName: 'Presença:',
        Cell: (props: any) => {
          const {
            row: {
              original: { athleteId, p },
            },
          } = props

          return (
            <CheckboxWrapper>
              <Checkbox
                checked={p ?? false}
                style={{ color: '#173f37' }}
                onChange={() => handleCheck(athleteId, 'P')}
              />
            </CheckboxWrapper>
          )
        },
      },
      {
        Header: 'Falta',
        accessor: 'f',
        mobileName: 'Falta:',
        width: 60,
        Cell: (props: any) => {
          const {
            row: {
              original: { athleteId, f },
            },
          } = props

          return (
            <CheckboxWrapper>
              <Checkbox
                checked={f ?? false}
                color="error"
                onChange={() => handleCheck(athleteId, 'F')}
              />
            </CheckboxWrapper>
          )
        },
      },
      {
        Header: 'F.Justificada',
        accessor: 'fj',
        width: 60,
        mobileName: 'F.Justificada',
        Cell: (props: any) => {
          const {
            row: {
              original: { athleteId, fj },
            },
          } = props

          return (
            <CheckboxWrapper>
              <Checkbox
                checked={fj ?? false}
                color="error"
                onChange={() => handleCheck(athleteId, 'FJ')}
              />
            </CheckboxWrapper>
          )
        },
      },
      {
        Header: 'Justificativa',
        accessor: 'reason',
        width: 80,
        mobileName: 'Justificativa',
        Cell: (props: any) => {
          const {
            row: {
              original: { athleteId, fj, reason },
            },
          } = props

          return (
            <AttendanceWrapper>
              <select
                onChange={(e) => handleReason(athleteId, e?.target?.value)}
                value={reason}
                disabled={!fj ?? true}
              >
                <option value="" selected disabled hidden>
                  Justificativa...
                </option>
                <option value="Escola">Escola</option>
                <option value="Médico">Médico</option>
                <option value="Lesão">Lesão</option>
                <option value="Enfermidade">Enfermidade</option>
                <option value="Outro">Outro</option>
              </select>
              {fj && !reason && (
                <div style={{ fontSize: '12px', marginTop: '0.1rem', color: 'red' }}>
                  Campo obrigatório
                </div>
              )}
            </AttendanceWrapper>
          )
        },
      },
    ]

    tableList.push(...tableListRender)

    return tableList
  }, [editMode, handleCheck, handleReason])

  return (
    <div style={{ margin: '2.5rem 0rem 2.5rem 0rem', width: '100%' }}>
      <InputComponent
        label="Pesquisar por nome:"
        name="search"
        value={searchValue}
        setValue={setSearchValue}
      />
      <TableComponent
        columns={columns}
        data={data}
        fetching={fetching}
        canPreviousPage={currentPage !== 1}
        canNextPage={currentPage !== Math.ceil(data?.length / 100)}
        pageSize={100}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={Math.ceil(data?.length / 100)}
        messageNoData="Turma sem atletas..."
      />
    </div>
  )
}

export default AttendanceListTable
