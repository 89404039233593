import * as XLSX from 'xlsx'
import saveAs from 'file-saver'
import moment from 'moment'
import { SelectedFranchiseOption } from '../redux/types/globalFilter'

export const saveFile = async (reportLink: string) => {
  const blob: any = await fetch(reportLink).then((r) => r.blob())

  if (blob) {
    const filename = reportLink.split('/')[5]
    saveAs(blob, filename)
  }
}

export const parseStringArrayToDates = (data: string[]) => data?.map((item) => new Date(item))

export const exportToExcel = (apiData: any[], fileName: string) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const ws = XLSX.utils.json_to_sheet(apiData)
  const wb = { Sheets: { dados: ws }, SheetNames: ['dados'] }
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const data = new Blob([excelBuffer], { type: fileType })
  saveAs(data, fileName + fileExtension)
}

export const filterFranchisesIds = (franchiseOptions: SelectedFranchiseOption[]) =>
  franchiseOptions.map((option) => option.id)

export const concatTableTexts = (arr: any[]) => arr.map((item: any) => item?.name ?? '').join(', ')

export const formatDate = (value: string | Date) => moment(value).format('DD/MM/YYYY')
export const isCoachOrAthlete = (userRole: string) => ['Treinador', 'Atleta'].includes(userRole)
export const isAdmin = (userRole: string) => userRole === 'Administrador'
