import styled from 'styled-components'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { NavigationProps, PaginatorIT } from './interface'

const PaginatorWrapper = styled.div<{ isMobile: boolean }>`
  ${(props) => `
    width: 100%;
    display: flex;
    justify-content: ${props.isMobile ? 'flex-end;' : 'center;'}
    align-items: center;
    ${props.isMobile ? 'flex-direction: column;' : ''}
    margin-top: ${props.theme.spacing.spc35};
  `}
`

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    justify-content: center;
    margin-top: ${(props) => props.theme.spacing.spc10};
  }
`

const TextPaginator = styled.span`
  ${(props) => `
    font-size: ${props.theme.fontSizes.font16};
    margin-right: ${props.theme.spacing.spc10};
`}
`

const NavigationButton = styled('span')<NavigationProps>`
  ${(props) => `
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props.theme.colors.primary};
    color: ${props.theme.colors.white};
    border-radius: ${props.theme.spacing.spc6};
    margin-left: ${props.theme.spacing.spc6};
    border: none;
    padding: ${props.theme.spacing.spc8};
    cursor: pointer;
    &:hover {
      background: ${props.theme.colors.primary};
    }
    svg {
      font-size: ${props.isMobile ? props.theme.fontSizes.font14 : props.theme.fontSizes.font20};
    }
  ${
    props?.isDisabled &&
    `
    background: ${props.theme.colors.greyBase};
    cursor: not-allowed;
    &:hover{
      background: ${props.theme.colors.greyBase};
      }
    `
  }
  `}
`

const SelectStyled = styled.select`
  ${({ theme }) => `
    background-color: ${theme.colors.greySoftLight};
    color: ${theme.colors.black};
    border-radius: ${theme.spacing.spc10};
    border: 1px solid ${theme.colors.primary};
    padding: ${theme.spacing.spc8};
    font-size: ${theme.fontSizes.font16};
    outline: none;
    cursor: pointer;
`}
`

const PageSizeValue = styled.span`
  ${({ theme }) => `
    color: ${theme.colors.black};
    font-size: ${theme.fontSizes.font16};
  `}
`

const Paginator = ({
  canPreviousPage,
  canNextPage,
  nextPage,
  previousPage,
  setPageSize,
  currentPage,
  pageSize,
  totalPages,
  dynamically,
  isMobile,
}: PaginatorIT) => (
  <PaginatorWrapper isMobile={!!isMobile}>
    <ContentWrapper>
      <TextPaginator>
        {`${dynamically ? currentPage + 1 : currentPage} - ${totalPages} de páginas`}
      </TextPaginator>
    </ContentWrapper>
    {(canPreviousPage || canNextPage) && (
      <ContentWrapper style={{ justifyContent: 'center' }}>
        <NavigationButton
          isMobile={!!isMobile}
          isDisabled={!canPreviousPage}
          onClick={() => {
            if (canPreviousPage) {
              previousPage()
            }
          }}
        >
          <ArrowBackIosIcon />
        </NavigationButton>

        <NavigationButton
          isMobile={!!isMobile}
          isDisabled={!canNextPage}
          onClick={() => {
            if (canNextPage) nextPage()
          }}
        >
          <ArrowForwardIosIcon />
        </NavigationButton>
      </ContentWrapper>
    )}
    {!isMobile ? (
      <ContentWrapper style={{ justifyContent: 'flex-end' }}>
        <TextPaginator>Linhas por página: </TextPaginator>
        {setPageSize ? (
          <SelectStyled
            value={pageSize}
            onChange={(e) => {
              if (setPageSize) setPageSize(parseInt(e.target.value, 10))
            }}
          >
            {[10, 50, 100].map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </SelectStyled>
        ) : (
          <PageSizeValue>{pageSize}</PageSizeValue>
        )}
      </ContentWrapper>
    ) : null}
  </PaginatorWrapper>
)

export default Paginator
