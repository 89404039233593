export const handleErrors = (error: any): string[] => {
  const genericError = 'Erro ao executar operação!'
  const errorResult = error?.graphQLErrors?.map((item: any) => {
    let message =
      item?.extensions?.exception?.response?.message?.[0] || error?.message || genericError
    if (message?.includes('[GraphQL]')) message = message?.split('[GraphQL]')[1]
    return message
  })
  return errorResult
}
