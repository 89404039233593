import {
  Person as PersonIcon,
  Business as BusinessIcon,
  Sports as SportsIcon,
  Timer as TimerIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  Science as ScienceIcon,
} from '@mui/icons-material'
import {
  faChalkboardUser,
  faChartSimple,
  faClipboard,
  faBars,
  faPersonRunning,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppSelector } from '../redux/store'
import { MainMenuItemIT, MenuItemIT } from '../@types/menu'

export const useMenuList = () => {
  const { auth } = useAppSelector((state) => state)

  const mainMenuList: MainMenuItemIT[] = [
    {
      name: 'Geral',
      permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
    },
    {
      name: 'Técnico',
      permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
    },
  ]

  const subMenuList: MenuItemIT[] = [
    {
      name: 'Dashboard',
      icon: <FontAwesomeIcon icon={faChartSimple} />,
      link: '/geral/dashboard',
      place: 'Geral',
      permissions: ['Administrador', 'Admin Unidade'],
    },
    {
      name: 'Unidades',
      icon: <BusinessIcon />,
      link: '/geral/unidades',
      place: 'Geral',
      permissions: ['Administrador', 'Admin Unidade'],
    },
    {
      name: 'Treinos exp.',
      icon: <ScienceIcon />,
      link: '/geral/experimental-trainings',
      place: 'Geral',
      permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
    },
    {
      name: 'Minha conta',
      icon: <PersonIcon />,
      link: `/tecnico/treinadores/${auth?.coach?.id}`,
      place: 'Geral',
      permissions: ['Treinador'],
    },
    {
      name: 'Atletas',
      icon: <FontAwesomeIcon icon={faPersonRunning} />,
      link: '/geral/atletas',
      place: 'Geral',
      permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
    },
    {
      name: 'Turmas',
      icon: <FontAwesomeIcon icon={faChalkboardUser} />,
      link: '/geral/turmas',
      place: 'Geral',
      permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
    },
    {
      name: 'Administradores',
      icon: <AdminPanelSettingsIcon />,
      link: '/geral/administradores',
      place: 'Geral',
      permissions: ['Administrador', 'Admin Unidade'],
    },
    {
      name: 'Treinadores',
      icon: <SportsIcon />,
      link: '/tecnico/treinadores',
      place: 'Técnico',
      permissions: ['Administrador', 'Admin Unidade'],
    },
    {
      name: 'Chamadas',
      icon: <FontAwesomeIcon icon={faClipboard} />,
      link: '/tecnico/chamadas',
      place: 'Técnico',
      permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
    },
    {
      name: 'Avaliações',
      icon: <TimerIcon />,
      link: '/tecnico/avaliacoes',
      place: 'Técnico',
      permissions: ['Administrador', 'Admin Unidade', 'Treinador'],
    },
  ]

  const navBarItems = [
    {
      icon: faChartSimple,
      route: '/geral/dashboard',
    },
    {
      icon: faPersonRunning,
      route: '/geral/atletas',
    },
    {
      icon: faChalkboardUser,
      route: '/geral/turmas',
    },
    {
      icon: faClipboard,
      route: '/tecnico/chamadas',
    },
    {
      icon: faBars,
      route: '/config',
    },
  ]

  return {
    mainMenuList,
    subMenuList,
    navBarItems,
  }
}
