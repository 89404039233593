import { gql } from 'urql'

export const GET_ALL_PHYSICAL_PERFORMANCES = gql`
  query($filterFranchisesIds: [Int!]) {
    getAllPhysicalPerformances(filterFranchisesIds: $filterFranchisesIds) {
      data {
        id
        name
        franchise {
          name
        }
        user {
          name
        }
        team {
          name
        }
        status
        createdAt
        updatedAt
      }
      total
    }
  }
`

export const ADD_PHYSICAL_PERFORMANCE = gql`
  mutation($franchiseId: Int!, $userId: Int!, $teamId: Int!) {
    createPhysicalPerformance(
      input: { franchiseId: $franchiseId, userId: $userId, teamId: $teamId }
    ) {
      id
      status
    }
  }
`

export const UPDATE_PHYSICAL_PERFORMANCE = gql`
  mutation($id: Int!, $name: String!) {
    updatePhysicalPerformance(input: { id: $id, name: $name }) {
      id
      status
    }
  }
`

export const GET_PHYSICAL_PERFORMANCE_BY_ID = gql`
  query($id: Int!) {
    getPhysicalPerformanceById(id: $id) {
      id
      name
      franchise {
        id
        name
      }
      user {
        name
      }
      team {
        id
        name
      }
      status
      createdAt
    }
  }
`

export const GET_TEAM_INFO_ON_PHYSICAL_PERFORMANCE = gql`
  query($id: Int!) {
    getTeamInfoOnPhysicalPerformance(id: $id) {
      teamName
      totalTeamAthletes
      totalRemainingAthletes
      remainingAthletes {
        id
        name
      }
    }
  }
`

export const DELETE_PHYSICAL_PERFORMANCE = gql`
  mutation($id: Int!) {
    deletePhysicalPerformance(id: $id)
  }
`

export const DOWNLOAD_ALL_REPORTS = gql`
  mutation($id: Int!) {
    downloadAllReports(id: $id) {
      id
      name
      zipLink
    }
  }
`
