import { useEffect, useState } from 'react'
import { FormikHelpers } from 'formik'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from 'urql'
import { toastDanger, toastSuccess } from '../../../../redux/toast/actions'
import { ADD_ATHLETE, UPDATE_ATHLETE } from '../../../../queries/athletes'
import { handleErrors } from '../../../../utils/handleGraphQLErrors'
import { useAppSelector } from '../../../../redux/store'
import { useFranchisesCached } from '../../../../hooks/Franchises/useFranchises'
import { AddEditAthleteFormValues } from '../../../../@types/athlete'
import { useGetAthleteByIdQuery } from '../../../../hooks/Athletes/useAthletes'
import { useTeamsQuery } from '../../../../hooks/Teams/useTeams'

export const useAddEditAthlete = () => {
  const {
    auth: {
      user: { role: userRole },
    },
  } = useAppSelector((state) => state)

  const { optionsSelect } = useFranchisesCached()
  const { id: athleteId } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isAddMode = athleteId === 'addAthlete'
  const isAthlete = userRole === 'Atleta'

  const [addAthleteResult, addAthlete] = useMutation(ADD_ATHLETE)
  const [updateAthleteResult, updateAthlete] = useMutation(UPDATE_ATHLETE)
  const {
    data: dataQuery,
    error: errorQuery,
    isFetching: isFetchingQuery,
  } = useGetAthleteByIdQuery(isAddMode, {
    id: parseInt(athleteId || '0', 10),
  })
  const { data: dataTeams } = useTeamsQuery(isAthlete, {})

  const isFetching = addAthleteResult.fetching || updateAthleteResult.fetching || isFetchingQuery

  const [initialValues, setInitialValues] = useState<AddEditAthleteFormValues>({
    idNumber: '',
    name: '',
    email: '',
    cpf: '',
    phone: '',
    passwd: '',
    gender: '',
    franchise: 0,
    birthday: null,
    teams: [],
    active: true,
    confirmPassword: '',
  })

  useEffect(() => {
    if (!isAddMode) {
      if (errorQuery) dispatch(toastDanger('Erro ao buscar atleta!'))

      const teams = dataQuery?.teams.map((item) => item?.id) ?? []
      const birthday = dataQuery?.birthday ? Date.parse(dataQuery?.birthday) : null

      setInitialValues({
        idNumber: dataQuery?.idNumber,
        name: dataQuery?.name,
        email: dataQuery?.email,
        cpf: dataQuery?.cpf,
        phone: dataQuery?.phone,
        birthday,
        gender: dataQuery?.gender,
        franchise: dataQuery?.user?.franchises[0]?.id ?? 0,
        passwd: '',
        confirmPassword: '',
        teams,
        active: dataQuery?.active,
      })
    }
  }, [dispatch, isAddMode, dataQuery, errorQuery])

  const handleSubmit = async (
    values: AddEditAthleteFormValues,
    actions: FormikHelpers<AddEditAthleteFormValues>
  ) => {
    const variablesAddEdit = {
      name: values.name,
      email: values.email,
      cpf: values.cpf,
      phone: values.phone,
      gender: values.gender,
      birthday: values?.birthday,
      franchiseId: values.franchise,
      active: values.active,
      teamsId: values.teams,
      ...(!isAddMode && { id: parseInt(athleteId || '0', 10) }),
      ...(isAthlete && !isAddMode && { passwd: values.passwd }),
    }
    if (isAddMode) {
      const response = await addAthlete(variablesAddEdit)
      if (response?.error) {
        const [error] = handleErrors(response?.error)
        dispatch(toastDanger(error))
      } else {
        dispatch(toastSuccess('Atleta criado com sucesso!'))
        navigate('/geral/atletas')
      }
    } else {
      const response = await updateAthlete(variablesAddEdit)
      if (response?.error) {
        const [error] = handleErrors(response?.error)
        dispatch(toastDanger(error))
      } else {
        const msgUpdateAthlete = () =>
          !isAthlete ? 'Atleta atualizado com sucesso!' : 'Atualizado com sucesso'
        dispatch(toastSuccess(msgUpdateAthlete()))
        if (!isAthlete) navigate('/geral/atletas')
      }
    }
    actions.setSubmitting(false)
  }

  const handleGoBack = () => navigate('/geral/atletas')

  const getTitle = () => {
    if (isAthlete) return 'Minha conta'
    if (isAddMode) return 'Adicionar atleta'

    return 'Editar atleta'
  }

  return {
    isAddMode,
    isFetching,
    initialValues,
    optionsSelect,
    handleSubmit,
    handleGoBack,
    getTitle,
    isAthlete,
    dataTeams,
  }
}
