import { useEffect, useState } from 'react'
import { FormikHelpers } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from 'urql'
import { useDispatch } from 'react-redux'
import { toastDanger, toastSuccess } from '../../../../redux/toast/actions'
import { ADD_COACH, UPDATE_COACH } from '../../../../queries/coaches'
import { handleErrors } from '../../../../utils/handleGraphQLErrors'
import { useAppSelector } from '../../../../redux/store'
import { AddEditCoachFormValues } from '../../../../@types/coach'
import { useCoachByIdQuery } from '../../../../hooks/Coaches/useCoaches'

export const useAddEditCoachContainer = () => {
  const {
    auth: { user },
  } = useAppSelector((state) => state)

  const { id: coachId } = useParams()

  const isAddMode = coachId === 'addCoach'
  const isCoach = user?.role === 'Treinador'

  const [addCoachResult, addCoach] = useMutation(ADD_COACH)
  const [updateCoachResult, updateCoach] = useMutation(UPDATE_COACH)

  const { data, isFetching: isFetchingGetById, error: errorGetById } = useCoachByIdQuery(
    isAddMode,
    {
      id: parseInt(coachId || '0', 10),
    }
  )

  const [initialValues, setInitialValues] = useState({
    name: '',
    email: '',
    cpf: '',
    phone: '',
    position: '',
    franchise: 0,
    passwd: '',
    confirmPassword: '',
    active: true,
  })

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isAddMode) {
      if (errorGetById) dispatch(toastDanger('Erro ao buscar treinador!'))

      setInitialValues({
        name: data?.name,
        email: data?.email,
        franchise: data?.user?.franchises[0]?.id ?? 0,
        cpf: data?.cpf,
        phone: data?.phone,
        position: data?.position,
        passwd: '',
        confirmPassword: '',
        active: data?.active,
      })
    }
  }, [dispatch, data, isAddMode, errorGetById])

  const handleSubmit = (
    values: AddEditCoachFormValues,
    actions: FormikHelpers<AddEditCoachFormValues>
  ) => {
    if (isAddMode) {
      addCoach({
        name: values.name,
        email: values.email,
        phone: values.phone,
        cpf: values.cpf,
        position: values.position,
        franchiseId: values.franchise,
        active: values.active,
      })
        .then((result: any) => {
          if (result?.error) {
            const [error] = handleErrors(result?.error)
            dispatch(toastDanger(error))
          } else {
            dispatch(toastSuccess('Treinador criado com sucesso!'))
            navigate('/tecnico/treinadores')
          }
        })
        .catch(() => dispatch(toastDanger('Erro ao criar treinador!')))
    } else {
      updateCoach({
        id: parseInt(coachId || '0', 10),
        name: values.name,
        email: values.email,
        phone: values.phone,
        cpf: values.cpf,
        position: values.position,
        franchiseId: values.franchise,
        ...(isCoach && { passwd: values.passwd }),
        ...(!isCoach && { active: values.active }),
      })
        .then((result) => {
          if (result?.error) {
            const [error] = handleErrors(result?.error)
            dispatch(toastDanger(error))
          } else {
            const savedMsg = () =>
              isCoach ? 'Conta atualizada com sucesso' : 'Treinador atualizado com sucesso!'
            dispatch(toastSuccess(savedMsg()))
            if (!isCoach) navigate('/tecnico/treinadores')
          }
        })
        .catch(() => dispatch(toastDanger('Erro ao atualizar treinador!')))
    }
    actions.setSubmitting(false)
  }

  const handleGoBack = () => navigate('/tecnico/treinadores')

  const getTitle = () => {
    if (isCoach) return 'Minha conta'
    if (isAddMode) return 'Adicionar treinador'
    return 'Editar treinador'
  }
  const backButton = isCoach
    ? undefined
    : { name: 'Voltar', onClick: () => navigate('/tecnico/treinadores') }
  const breadcrumbTitle = isAddMode ? 'Adicionar treinador' : 'Editar treinador'
  const isFetching = addCoachResult?.fetching || updateCoachResult?.fetching || isFetchingGetById

  return {
    isFetching,
    coachId,
    user,
    initialValues,
    handleSubmit,
    handleGoBack,
    getTitle,
    breadcrumbTitle,
    isAddMode,
    isCoach,
    backButton,
  }
}
