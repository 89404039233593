import { useMemo } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import TableComponent from '../TableComponent'
import TableActions from '../TableActions/TableActions'
import { usePhysicalPerformanceTable } from './usePhysicalPerformanceTable'
import { PhysicalPerformanceTableProps } from './interface'
import { saveFile } from '../../utils/utils'

const ResultsWrapper = styled('div')`
  width: 100%;
  margin-top: ${(props) => props?.theme?.spacing?.spc20};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const TitlePerformance = styled('h2')`
  color: ${(props) => props?.theme.colors.primary};
`

function PhysicalPerformanceTable({
  isAddPhysicalPerformance,
  isOpen,
  isAthlete,
  physicalIdNumber,
  status,
}: PhysicalPerformanceTableProps) {
  const {
    tableData,
    total,
    currentPage,
    setCurrentPage,
    showReport,
    editRating,
    isFetching,
  } = usePhysicalPerformanceTable({
    isAddPhysicalPerformance,
    isOpen,
    isAthlete,
    physicalIdNumber,
  })

  const columns = useMemo(() => {
    const columnsList = [] as any
    if (!isAthlete) {
      columnsList.push({
        Header: 'Atleta',
        accessor: 'athleteName',
        width: 150,
      })
    }

    if (isAthlete) {
      columnsList.push({
        Header: 'Data',
        accessor: 'updatedAt',
        width: 80,
        Cell: (props: any) => moment(props.row?.original?.updatedAt).format('MM/DD/YYYY'),
      })
    }

    columnsList.push({
      Header: 'Veloc.',
      accessor: 'speed',
      width: 80,
      Cell: (props: any) => props.row?.original?.speed?.toFixed(2),
    })

    columnsList.push({
      Header: 'Salto',
      accessor: 'impulse',
      width: 80,
      Cell: (props: any) => props.row?.original?.impulse?.toFixed(2),
    })

    columnsList.push({
      Header: 'Beep',
      accessor: 'beep',
      width: 80,
    })

    columnsList.push({
      Header: 'Comun.',
      accessor: 'communication',
      width: 80,
    })

    columnsList.push({
      Header: 'Técnico',
      accessor: 'technical',
      width: 80,
    })

    columnsList.push({
      Header: 'Tático',
      accessor: 'tatical',
      width: 80,
    })

    columnsList.push({
      Header: 'Físico',
      accessor: 'physical',
      width: 80,
    })

    columnsList.push({
      Header: 'Ações',
      accessor: 'actions',
      width: 50,
      noSort: true,
      Cell: (props: any) => {
        const {
          row: {
            original: { id: ratingId, reportLink },
          },
        } = props
        return (
          <TableActions
            actionsList={
              status === 'Fechada'
                ? [
                    {
                      type: 'download',
                      title: 'Baixar',
                      handleClick: () => saveFile(reportLink),
                    },
                    {
                      type: 'view',
                      title: 'Visualizar',
                      handleClick: () => showReport(physicalIdNumber, ratingId),
                    },
                  ]
                : [
                    {
                      type: 'edit',
                      title: 'Editar',
                      handleClick: () => editRating(physicalIdNumber, ratingId),
                    },
                    {
                      type: 'delete',
                      title: 'Excluir',
                      handleClick: () => editRating(physicalIdNumber, ratingId),
                    },
                  ]
            }
          />
        )
      },
    })

    return columnsList
  }, [editRating, isAthlete, physicalIdNumber, showReport, status])

  return (
    <ResultsWrapper>
      {!isAthlete && <TitlePerformance>Avaliações realizadas</TitlePerformance>}
      <TableComponent
        columns={columns}
        data={tableData ?? []}
        fetching={isFetching}
        canPreviousPage={currentPage !== 1}
        canNextPage={currentPage !== Math.ceil(total / 100)}
        pageSize={100}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={Math.ceil(total / 100)}
      />
    </ResultsWrapper>
  )
}

export default PhysicalPerformanceTable
