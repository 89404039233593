import styled from 'styled-components'
import TextareaAutosize from 'react-textarea-autosize'
import { Field } from 'formik'
import { TextAreaComponentProps, WrapperProps, TextAreaWrapperProps } from './interface'

const Wrapper = styled('div')<WrapperProps>`
  display: flex;
  flex-direction: column;
  flex: 1 1 ${(props) => (props?.width ? props?.width : '200px')};
  @media screen and (max-width: 769px) {
    width: 100%;
  }
  margin-top: ${(props) => props?.theme?.spacing?.spc10};
  label {
    font-size: ${(props) => props?.theme?.fontSizes?.font18};
    font-weight: 600;
    color: ${(props) => props?.theme?.colors?.primary};
  }
`
const TextAreaWrapper = styled('div')<TextAreaWrapperProps>`
  display: flex;
  align-items: center;
  background-color: ${(props) => props?.theme?.colors?.greySoftLight};
  padding: ${(props) => props?.theme?.spacing?.spc8} ${(props) => props?.theme?.spacing?.spc8};
  border-radius: 4px;
  border: 1px solid
    ${(props) => (props.isError ? props?.theme?.colors?.red : props?.theme?.colors?.primary)};
  margin-top: ${(props) => props?.theme?.spacing?.spc8};
  textarea {
    width: 100%;
    font-size: ${(props) => props?.theme?.fontSizes?.font20};
    color: ${(props) => props?.theme?.colors?.black};
    border: none;
    outline: none;
    resize: none;
    background-color: ${(props) => props?.theme?.colors?.greySoftLight};
  }
  textarea: disabled {
    background-color: #f7f7f7;
    color: #aaa;
  }
`
const CleanCountWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 0.2rem;
  button {
    border: none;
    background: none;
    font-size: ${(props) => props?.theme?.fontSizes?.font16};
    font-weight: 600;
    color: ${(props) => props?.theme?.colors?.primary};
    text-decoration: underline;
    cursor: pointer;
  }
  span {
    font-size: ${(props) => props?.theme?.fontSizes?.font16};
    font-weight: 600;
    color: ${(props) => props?.theme?.colors?.primary};
  }
`

const ErrorDiv = styled('div')`
  color: ${(props) => props?.theme?.colors?.red};
  font-size: ${(props) => props?.theme?.fontSizes?.font14};
  margin-top: ${(props) => props?.theme?.spacing?.spc6};
`

function TextAreaComponent({
  value,
  setValue,
  props,
  label,
  name,
  disabled,
  width,
  limitCharacters,
}: TextAreaComponentProps) {
  const handleClear = () => {
    if (setValue) setValue('')
    if (props?.setFieldValue) props?.setFieldValue(name, '')
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (setValue) setValue(e.target.value)
    if (props?.setFieldValue) props?.setFieldValue(name, e.target.value)
  }

  return (
    <Wrapper width={width} data-testid="input-component">
      {label && <label htmlFor={name}>{label}</label>}
      <TextAreaWrapper isError={!!props?.errors[name] || value?.length > limitCharacters}>
        <Field
          component={TextareaAutosize}
          id="positivePoints"
          name="positivePoints"
          type="text"
          value={value}
          onChange={handleOnChange}
          minRows={10}
          disabled={disabled}
        />
      </TextAreaWrapper>
      <CleanCountWrapper>
        <span>
          {value?.length} / {limitCharacters}
        </span>
        {!disabled && (
          <button onClick={handleClear} type="button">
            Limpar
          </button>
        )}
      </CleanCountWrapper>
      {props?.touched && props?.errors && props?.touched[name] && props?.errors[name] && (
        <ErrorDiv>{props?.errors[name]}</ErrorDiv>
      )}
    </Wrapper>
  )
}

export default TextAreaComponent
