import { useQuery } from 'urql'
import { GET_ALL_TEAMS, GET_TEAM_BY_ID } from '../../queries/teams'
import { TeamPaginated, getTeamById } from '../../@types/responses'
import { GetAllTeamsQueryTypes, GetTeamByIdQueryTypes } from './interface'

export const useTeamsQuery = (
  pause: boolean,
  variables: GetAllTeamsQueryTypes,
  isCacheFirst?: boolean
) => {
  const [getAllTeamsQueryResult, reExecute] = useQuery({
    query: GET_ALL_TEAMS,
    requestPolicy: isCacheFirst ? 'cache-first' : 'network-only',
    pause,
    variables,
  })

  const data: TeamPaginated = getAllTeamsQueryResult.data?.getAllTeams
  const isFetching: boolean = getAllTeamsQueryResult.fetching
  const { error } = getAllTeamsQueryResult

  return { data, error, isFetching, reExecute }
}

export const useTeamByIdQuery = (pause: boolean, variables: GetTeamByIdQueryTypes) => {
  const [getTeamByIdResult] = useQuery({
    query: GET_TEAM_BY_ID,
    variables,
    requestPolicy: 'network-only',
    pause,
  })

  const data: getTeamById = getTeamByIdResult.data?.getTeamById
  const isFetching: boolean = getTeamByIdResult.fetching
  const { error } = getTeamByIdResult

  return { data, error, isFetching }
}
