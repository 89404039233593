import { gql } from 'urql'

export const GET_ACTIVE_INACTIVE_ATHLETES = gql`
  query($filterFranchisesIds: [Int!]) {
    getActiveInactiveAthletes(filterFranchisesIds: $filterFranchisesIds) {
      active
      inactive
    }
  }
`

export const DASH_ATHLETE_ATTENDANCE_PERC = gql`
  query($search: String!) {
    dashAthleteAttendancePerc(search: $search) {
      p
      f
      fj
      sumTotal
    }
  }
`
