import { useEffect, useState } from 'react'
import { FormikHelpers } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from 'urql'
import { useDispatch } from 'react-redux'
import { toastDanger, toastSuccess } from '../../../../redux/toast/actions'
import { ADD_USER, UPDATE_USER } from '../../../../queries/users'
import { handleErrors } from '../../../../utils/handleGraphQLErrors'
import { useAppSelector } from '../../../../redux/store'
import { AddEditUserFormValues } from '../../../../@types/user'
import { useGetUserByIdQuery } from '../../../../hooks/Users/useUsers'

export const useAddEditUser = () => {
  const {
    auth: { user },
  } = useAppSelector((state) => state)

  const { id: userId } = useParams()
  const isEditMode = userId !== 'addUser'
  const isAdmin = user?.role === 'Administrador'

  const [addUserResult, addUser] = useMutation(ADD_USER)
  const [updateUserResult, updateUser] = useMutation(UPDATE_USER)

  const { data, error: errorGetById, isFetching: isFetchingGetById } = useGetUserByIdQuery(
    !isEditMode,
    {
      id: parseInt(userId || '0', 10),
    }
  )

  const [initialValues, setInitialValues] = useState<AddEditUserFormValues>({
    name: '',
    email: '',
    franchises: [],
    passwd: '',
    role: '',
    confirmPassword: '',
  })

  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isEditMode) {
      if (errorGetById) dispatch(toastDanger('Erro ao buscar administrador!'))

      const userFranchisesMapped = data?.franchises?.map((franchise) => franchise.id)
      setInitialValues({
        name: data?.name,
        email: data?.email,
        passwd: '',
        franchises: userFranchisesMapped,
        role: data?.role,
        confirmPassword: '',
      })
    }
  }, [dispatch, data, isEditMode, errorGetById])

  useEffect(() => {
    if (user?.role === 'Admin Unidade') {
      setInitialValues({
        ...initialValues,
        role: 'Admin Unidade',
      })
    }
  }, [initialValues, user?.role])

  const handleSubmit = (
    values: AddEditUserFormValues,
    actions: FormikHelpers<AddEditUserFormValues>
  ) => {
    if (!isEditMode) {
      addUser({
        name: values.name,
        email: values.email,
        role: values.role,
        passwd: values.passwd,
        franchisesId: values?.franchises,
      })
        .then((result) => {
          if (result?.error) {
            const [error] = handleErrors(result?.error)
            dispatch(toastDanger(error))
          } else {
            dispatch(toastSuccess('Administrador criado com sucesso!'))
            navigate('/geral/administradores')
          }
        })
        .catch(() => dispatch(toastDanger('Erro ao criar administrador!')))
    } else {
      updateUser({
        id: parseInt(userId || '0', 10),
        name: values.name,
        email: values.email,
        role: values.role,
        passwd: values.passwd,
        franchisesId: values?.franchises,
      })
        .then((result) => {
          if (result?.error) {
            const [error] = handleErrors(result?.error)
            dispatch(toastDanger(error))
          } else {
            dispatch(toastSuccess('Administrador atualizado com sucesso!'))
            navigate('/geral/administradores')
          }
        })
        .catch(() => dispatch(toastDanger('Erro ao atualizar administrador!')))
    }
    actions.setSubmitting(false)
  }

  const titleText = userId === 'addUser' ? 'Adicionar administrador' : 'Editar administrador'
  const backButton = { name: 'Voltar', onClick: () => navigate('/geral/administradores') }
  const isFetching = addUserResult?.fetching || updateUserResult?.fetching || isFetchingGetById

  return {
    isAdmin,
    titleText,
    initialValues,
    handleSubmit,
    backButton,
    isFetching,
  }
}
