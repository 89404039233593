import { Add as AddIcon } from '@mui/icons-material'
import AddEditPhysicalPerformanceForm from '../../../../Forms/AddEditPhysicalPerformanceForm/AddEditPhysicalPerformanceForm'
import { BreadcrumbItem, Overlay, Panel } from '../../../../components'
import {
  useAddEditPhysicalPerformanceContainer,
  useModalGenerateReports,
} from './useAddEditPhysicalPerformanceContainer'
import { useFranchisesCached } from '../../../../hooks/Franchises/useFranchises'
import { useTeamsQuery } from '../../../../hooks/Teams/useTeams'
import PhysicalPerformanceTable from '../../../../components/PhysicalPerformanceTable/PhysicalPerformanceTable'

function AddEditPhysicalPerformance() {
  const {
    isOpen,
    setIsOpen,
    isFetching,
    initialValues,
    isAthlete,
    handleSubmit,
    addAthleteTest,
    labelValue,
    isAddPhysicalPerformance,
    handleGoBack,
    physicalIdNumber,
    handleGenerateReports,
  } = useAddEditPhysicalPerformanceContainer()

  const { isFetching: isFetchingModalInfo, infoReport } = useModalGenerateReports(isOpen)
  const { optionsSelect } = useFranchisesCached()
  const { data: dataTeams } = useTeamsQuery(isAthlete, {})

  const breadcrumbs = [
    <BreadcrumbItem key="tecnico" text="Técnico" link="/tecnico/avaliacoes" />,
    <BreadcrumbItem key="avaliacoes" text="Avaliações" link="/tecnico/avaliacoes" />,
    <BreadcrumbItem key="criarEditarAvaliacao" text={labelValue()} />,
  ]
  const panelButtons = isAthlete
    ? []
    : [
        {
          onClick: addAthleteTest,
          buttonIcon: <AddIcon />,
          name: 'Avaliar atleta',
        },
      ]

  return (
    <Panel
      title={labelValue()}
      isLoading={isFetching || isFetchingModalInfo}
      buttons={isAddPhysicalPerformance || initialValues.status === 'Fechada' ? [] : panelButtons}
      breadcrumbs={!isAthlete ? breadcrumbs : []}
      backButton={
        !isAthlete
          ? {
              onClick: handleGoBack,
              name: 'Voltar',
            }
          : undefined
      }
    >
      {!isAthlete ? (
        <AddEditPhysicalPerformanceForm
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          optionsFranchises={optionsSelect}
          editMode={!isAddPhysicalPerformance}
          dataTeams={dataTeams}
        />
      ) : (
        <></>
      )}
      {!isAddPhysicalPerformance ? (
        <PhysicalPerformanceTable
          isAddPhysicalPerformance={isAddPhysicalPerformance}
          isOpen={false}
          isAthlete={isAthlete}
          physicalIdNumber={physicalIdNumber}
          status={initialValues.status}
        />
      ) : (
        <></>
      )}
      <Overlay
        overlayTitle="Gerar relatórios"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        buttonText="Confirmar"
        handleClick={handleGenerateReports}
      >
        <div>
          <ul>
            <li>
              Turma: {infoReport.teamName}
              {infoReport.totalTeamAthletes === 1 ? ' atleta' : ' atletas'}
              {infoReport.remainingAthletes.length > 0 ? (
                <li>
                  Os(as) atletas abaixo não estão neste relatório:
                  <ul>
                    {infoReport.remainingAthletes?.map((item, key) => (
                      <li key={key}>{item?.name}</li>
                    ))}
                  </ul>
                </li>
              ) : null}
            </li>
          </ul>
        </div>
      </Overlay>
    </Panel>
  )
}

export default AddEditPhysicalPerformance
