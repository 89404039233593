import jwt_decode from 'jwt-decode'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { toastDanger } from '../redux/toast/actions'
import { useAppSelector } from '../redux/store'
import { LogoutAction } from '../redux/auth/actions'

export const useDashboardLayout = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const {
    auth,
    auth: { user, franchises },
  } = useAppSelector((state) => state)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { pathname } = window.location
  const isMobileScreen = windowWidth < 769

  const isAuthenticated = () => {
    const token: string | null = localStorage.getItem('token')

    if (token) {
      const decoded: any = jwt_decode(token)
      if (decoded?.exp < (new Date().getTime() + 1) / 1000) return false

      return true
    }
    return false
  }

  const handleLogout = () => {
    dispatch(LogoutAction())
    navigate('/')
  }

  const handleClickMenu = (link: string) => navigate(link)
  const updateDimensions = () => setWindowWidth(window.innerWidth)
  const userFirstName = user?.name?.split(' ')[0] ?? ''
  const businessName = franchises[0].name

  useEffect(() => {
    if (!user?.id) {
      const authenticated: boolean = isAuthenticated()
      if (!authenticated) {
        dispatch(LogoutAction())
        navigate('/')
      }
    }
    const currentTime: number = Date.now() / 1000
    const isTokenExpired = () => auth?.exp <= currentTime
    if (auth?.exp && isTokenExpired()) {
      dispatch(toastDanger('Sua sessão expirou!'))
      dispatch(LogoutAction())
      navigate('/')
    }
  }, [dispatch, navigate, auth, user?.id])

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  return {
    pathname,
    handleClickMenu,
    handleLogout,
    userFirstName,
    businessName,
    showTopBar: !isMobileScreen,
    user,
    windowWidth,
    isMobileScreen,
  }
}
