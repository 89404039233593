import styled from 'styled-components'
import IdLogo from '../../assets/ID-logo-loading.jpeg'

const LoadingWrapper = styled('div')`
  border: 5px solid #f3f3f3;
  border-top: 5px solid ${(props) => props?.theme?.colors?.primary};
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spinLoading 1s linear infinite;
  img {
    height: 120px;
    width: 120px;
    animation: spinLogo 1s linear infinite;
    border-radius: 100%;
  }
  @keyframes spinLoading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes spinLogo {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`

const Loading = () => (
  <LoadingWrapper data-testid="loading-component">
    <img src={IdLogo} alt="ID Sports" />
  </LoadingWrapper>
)

export default Loading
