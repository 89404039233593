import { useEffect, useState } from 'react'
import { useMutation } from 'urql'
import { useNavigate } from 'react-router-dom'
import { Add as AddIcon } from '@mui/icons-material'
import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'
import { DELETE_USER } from '../../../queries/users'
import { useAppSelector } from '../../../redux/store'
import { filterFranchisesIds } from '../../../utils/utils'
import { useUsersQuery } from '../../../hooks/Users/useUsers'
import { toastDanger, toastSuccess } from '../../../redux/toast/actions'
import { handleErrors } from '../../../utils/handleGraphQLErrors'

export const useUsersContainer = (isOpen: boolean) => {
  const {
    auth: { user },
    globalFilter: { selectedFranchises },
  } = useAppSelector((state) => state)
  const { data, isFetching: fetching, reExecute } = useUsersQuery(
    ['Treinador', 'Atleta'].includes(user?.role),
    {
      ...(!isEmpty(selectedFranchises) && {
        filterFranchisesIds: filterFranchisesIds(selectedFranchises),
      }),
    }
  )

  const [tableData, setTableData] = useState([] as any)
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(0)

  const navigate = useNavigate()
  const addUser = () => navigate(`/geral/administradores/addUser`)
  const editUser = (userId: string | undefined) => navigate(`/geral/administradores/${userId}`)

  const panelButtons = [
    {
      onClick: addUser,
      buttonIcon: <AddIcon />,
      name: 'Criar administrador',
    },
  ]

  useEffect(() => {
    if (!isOpen) reExecute({ requestPolicy: 'network-only' })
  }, [reExecute, isOpen])

  useEffect(() => {
    if (!fetching && data) {
      const usersListMapped = data.data?.map((item: any) => ({
        id: item?.id,
        name: item?.name,
        email: item?.email,
        role: item?.role,
        franchises: item?.franchises,
      }))
      setTableData(usersListMapped ?? [])
      setTotal(data?.total ?? 0)
    }
  }, [data, fetching, user])

  return {
    fetching,
    isOpen,
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    total,
    addUser,
    editUser,
    panelButtons,
  }
}

export const useDeleteUsers = () => {
  const [deleteUserResult, deleteUser] = useMutation(DELETE_USER)
  const [isOpen, setIsOpen] = useState(false)
  const [deleteOptions, setDeleteOptions] = useState({ id: '', name: '' })

  const dispatch = useDispatch()

  const handleDeleteAdmin = (teamId: string, teamName: string) => {
    setDeleteOptions({ id: teamId ?? '0', name: teamName })
    setIsOpen(true)
  }

  const handleDeleteAction = () => {
    deleteUser({
      id: parseInt(deleteOptions.id, 10),
    })
      .then((result: any) => {
        if (result?.error) {
          const [error] = handleErrors(result?.error)
          dispatch(toastDanger(error))
        } else {
          dispatch(toastSuccess('Administrador removido(a) com sucesso!'))
          setIsOpen(false)
          setDeleteOptions({ id: '', name: '' })
        }
      })
      .catch(() => {
        dispatch(toastDanger('Erro ao remover administrador'))
      })
  }

  useEffect(() => {
    if (!isOpen) setDeleteOptions({ id: '', name: '' })
  }, [isOpen])

  const isFetchingDelete = deleteUserResult.fetching

  return {
    handleDeleteAdmin,
    handleDeleteAction,
    isOpen,
    setIsOpen,
    deleteOptions,
    isFetchingDelete,
  }
}
