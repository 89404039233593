import { useMemo } from 'react'
import {
  Add as AddIcon,
  Download as DownloadIcon,
  FilterAltOutlined as FilterAltOffIcon,
  FilterAlt as FilterAltIcon,
} from '@mui/icons-material'
import {
  ActiveLabel,
  BreadcrumbItem,
  Overlay,
  Panel,
  TableActions,
  TableComponent,
} from '../../../components'
import { useAthletesContainer } from './useAthletesContainer'
import { TableActionItemProps } from '../../../components/TableActions/interface'
import { concatTableTexts } from '../../../utils/utils'
import { useActivateOrDeleteAthlete } from '../../../hooks/Athletes/useAthletes'
import AthletesFilterForm from '../../../Forms/Filters/AthletesFilterForm/AthletesFilterForm'

function Athletes() {
  const {
    handleDeleteAthlete,
    handleModalConfirmation,
    isOpen,
    setIsOpen,
    deleteActivateAthleteFetching,
    handleOnClickLabel,
    modalTitle,
    modalMessage,
  } = useActivateOrDeleteAthlete()

  const {
    fetching,
    tableData,
    pageSize,
    currentPage,
    setPageSize,
    setCurrentPage,
    total,
    editAthlete,
    isCoach,
    downloadAthletes,
    addAthlete,
    isOpenFilter,
    setIsOpenFilter,
    initialValuesFilter,
    handleSubmitFilter,
  } = useAthletesContainer(isOpen, deleteActivateAthleteFetching)

  const columns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name',
        noSort: true,
        width: 180,
      },
      {
        Header: 'Unidades',
        accessor: 'franchises',
        mobileName: 'Unidades:',
        noSort: true,
        width: 100,
        Cell: (props: any) => {
          const franchises = props.row.original?.franchises
          return concatTableTexts(franchises)
        },
      },
      {
        Header: 'Turmas',
        accessor: 'team',
        mobileName: 'Turmas:',
        noSort: true,
        width: 120,
        Cell: (props: any) => {
          const teams = props.row.original?.teams
          return concatTableTexts(teams)
        },
      },
      {
        Header: 'Categoria',
        accessor: 'gender',
        mobileName: 'Categoria:',
        noSort: true,
        width: 100,
      },
      {
        Header: 'Responsável',
        accessor: 'clientParents',
        noSort: true,
        width: 120,
        Cell: (props: any) => {
          const clientParents = props.row.original?.clientParents
          return concatTableTexts(clientParents)
        },
      },
      {
        Header: 'Status',
        accessor: 'active',
        noSort: true,
        width: 50,
        Cell: (props: any) => {
          const {
            row: {
              original: { id: athleteId, active, name },
            },
          } = props
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ActiveLabel
                isActive={!!active}
                handleOnClick={() => handleOnClickLabel(athleteId, name, !!active)}
              />
            </div>
          )
        },
      },
      {
        Header: 'Ações',
        accessor: 'actions',
        width: 50,
        noSort: true,
        Cell: (props: any) => {
          const {
            row: {
              original: { id: athleteId, name: athleteName },
            },
          } = props
          const actionsList: TableActionItemProps[] = [
            {
              type: 'edit',
              title: 'Editar',
              handleClick: () => editAthlete(athleteId),
            },
          ]
          if (!isCoach) {
            actionsList.push({
              type: 'delete',
              title: 'Excluir',
              handleClick: () => handleDeleteAthlete(athleteId, athleteName),
            })
          }
          return <TableActions actionsList={actionsList} />
        },
      },
    ],
    [handleOnClickLabel, isCoach, editAthlete, handleDeleteAthlete]
  )

  const breadcrumbs = [
    <BreadcrumbItem key="tecnico" text="Técnico" link="/geral/atletas" />,
    <BreadcrumbItem key="atletas" text="Atletas" />,
  ]

  const panelButtons = [
    {
      onClick: () => setIsOpenFilter(!isOpenFilter),
      buttonIcon: isOpenFilter ? <FilterAltOffIcon /> : <FilterAltIcon />,
      name: 'Fitros',
    },
    {
      onClick: downloadAthletes,
      buttonIcon: <DownloadIcon />,
      name: 'Baixar atletas',
    },
    {
      onClick: addAthlete,
      buttonIcon: <AddIcon />,
      name: 'Criar atleta',
    },
  ]

  return (
    <Panel title="Atletas" buttons={panelButtons} isLoading={fetching} breadcrumbs={breadcrumbs}>
      {isOpenFilter ? (
        <AthletesFilterForm initialValues={initialValuesFilter} handleSubmit={handleSubmitFilter} />
      ) : (
        <></>
      )}
      <TableComponent
        columns={columns}
        data={tableData}
        canPreviousPage={currentPage + 1 !== 1}
        canNextPage={currentPage + 1 !== Math.ceil(total / pageSize)}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={Math.ceil(total / pageSize)}
        total={total}
        dynamically
        fetching={fetching}
      />
      <Overlay
        overlayTitle={modalTitle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        buttonText="Confirmar"
        handleClick={handleModalConfirmation}
      >
        <p>{modalMessage}</p>
      </Overlay>
    </Panel>
  )
}

export default Athletes
