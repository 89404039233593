import { useState } from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { FormikHelpers } from 'formik'
import { useMutation } from 'urql'
import { useNavigate } from 'react-router-dom'
import { RECOVERY_PASSWORD } from '../../queries/users'
import { toastDanger } from '../../redux/toast/actions'
import {
  isSubmittedRecoveryPasswordProps,
  RecoveryPasswordFormValues,
} from '../../@types/recoveryPassword'

export const validationRecoveryPassword = Yup.object({
  email: Yup.string()
    .max(45, 'E-mail muito longo')
    .email('Insira um e-mail válido')
    .required('Campo obrigatório'),
})

export const useRecoveryPassword = () => {
  const [recoveryPasswordResult, recoveryPassword] = useMutation(RECOVERY_PASSWORD)
  const [isSubmitted, setIsSubmitted] = useState<isSubmittedRecoveryPasswordProps>({
    isSubmitted: false,
    status: '',
    email: '',
  })
  const initialValues: RecoveryPasswordFormValues = { email: '' }
  const isFetching = recoveryPasswordResult.fetching

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSubmit = async (
    values: RecoveryPasswordFormValues,
    actions: FormikHelpers<RecoveryPasswordFormValues>
  ) => {
    try {
      const response = await recoveryPassword({
        email: values.email,
      })
      if (response?.error) dispatch(toastDanger('E-mail não existente!'))
      else {
        setIsSubmitted({
          isSubmitted: true,
          status: 'success',
          email: values.email,
        })
      }
    } catch (error) {
      setIsSubmitted({ isSubmitted: true, status: 'error', email: '' })
    }

    actions.setSubmitting(false)
  }
  const backToLogin = () => navigate('/')

  return {
    recoveryPasswordResult,
    isSubmitted,
    initialValues,
    handleSubmit,
    isFetching,
    backToLogin,
  }
}
