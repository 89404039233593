import styled from 'styled-components'
import {
  DirectionsRun as DirectionsRunIcon,
  Height as HeightIcon,
  Timer as TimerIcon,
  Psychology as PsychologyIcon,
  Sports as SportsIcon,
  Star as StarIcon,
  FitnessCenter as FitnessCenterIcon,
  Insights as InsightsIcon,
  PersonRemove as PersonRemoveIcon,
} from '@mui/icons-material'
import AddEditPhysicalPerformanceAthleteForm from '../../../../../Forms/AddEditPhysicalPerformanceAthleteForm/AddEditPhysicalPerformanceAthleteForm'
import { BreadcrumbItem, Overlay, Panel } from '../../../../../components'
import { useAddEditPhysicalPerformanceAthlete } from './useAddEditPhysicalPerformanceAthlete'

const OverlayContentWrapper = styled('ul')`
  list-style: none;
  padding: 0;
  min-width: 350px;
  li {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    font-size: ${(props) => props?.theme?.fontSizes?.font18};
    font-weight: 600;
    max-width: 850px;
    svg {
      color: ${(props) => props?.theme?.colors?.primary};
      font-size: ${(props) => props?.theme?.fontSizes?.font27};
      margin-right: 0.5rem;
    }
  }
`

const getItemFormatted = (keyName: any, index: number, entireObj: any) => {
  let displayText = ''
  let icon = null
  if (keyName === 'speed') {
    displayText = 'Velocidade(segundos)'
    icon = <DirectionsRunIcon />
  }
  if (keyName === 'impulse') {
    displayText = 'Salto(metros)'
    icon = <HeightIcon />
  }
  if (keyName === 'beep') {
    displayText = 'Beep(nível)'
    icon = <TimerIcon />
  }
  if (keyName === 'communication') {
    displayText = 'Comportamento (1-5)'
    icon = <PsychologyIcon />
  }
  if (keyName === 'technical') {
    displayText = 'Técnico (1-5)'
    icon = <SportsIcon />
  }
  if (keyName === 'tatical') {
    displayText = 'Tático (1-5)'
    icon = <InsightsIcon />
  }
  if (keyName === 'physical') {
    displayText = 'Físico (1-5)'
    icon = <FitnessCenterIcon />
  }
  if (keyName === 'positivePoints') {
    displayText = 'Pontos positivos'
    icon = <StarIcon />
  }
  if (keyName === 'negativePoints') {
    displayText = 'Pontos à evoluir'
    icon = <PersonRemoveIcon />
  }
  return displayText ? (
    <li key={`table-item-${index}`}>
      {icon && icon}
      {displayText}: {entireObj[keyName]}
    </li>
  ) : (
    ''
  )
}

function AddEditPhysicalPerformance() {
  const {
    isFetching,
    physicalId,
    getTitle,
    handleGoBack,
    handleSubmit,
    initialValues,
    optionsAthletes,
    teamName,
    isOpen,
    setIsOpen,
    handleSaveAction,
  } = useAddEditPhysicalPerformanceAthlete()

  const breadcrumbs = [
    <BreadcrumbItem key="tecnico" text="Técnico" link="/tecnico/avaliacoes" />,
    <BreadcrumbItem key="avaliacoes" text="Avaliações" link="/tecnico/avaliacoes" />,
    <BreadcrumbItem
      key="editar-tecnico"
      text="Editar avaliação"
      link={`/tecnico/avaliacoes/${physicalId}`}
    />,
    <BreadcrumbItem key="editar-add-tecnico" text={getTitle} />,
  ]

  return (
    <Panel
      title={getTitle}
      isLoading={isFetching}
      breadcrumbs={breadcrumbs}
      backButton={{
        onClick: handleGoBack,
        name: 'Voltar',
      }}
    >
      <AddEditPhysicalPerformanceAthleteForm
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        athletesList={optionsAthletes}
        teamName={teamName}
      />
      <Overlay
        overlayTitle={getTitle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        buttonText="Confirmar"
        handleClick={handleSaveAction}
      >
        <OverlayContentWrapper>
          {Object.keys(initialValues).map((keyValue: any, index: number) =>
            getItemFormatted(keyValue, index, initialValues)
          )}
        </OverlayContentWrapper>
      </Overlay>
    </Panel>
  )
}

export default AddEditPhysicalPerformance
