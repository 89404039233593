import { gql } from 'urql'

export const LOGIN_USER = gql`
  mutation($email: String!, $passwd: String!) {
    login(input: { email: $email, passwd: $passwd }) {
      access_token
    }
  }
`

export const ADD_USER = gql`
  mutation(
    $name: String!
    $email: String!
    $passwd: String!
    $role: String!
    $franchisesId: [Int!]!
  ) {
    createUser(
      input: {
        name: $name
        email: $email
        passwd: $passwd
        role: $role
        franchisesId: $franchisesId
      }
    ) {
      id
      name
      email
      role
    }
  }
`

export const UPDATE_USER = gql`
  mutation(
    $id: Int!
    $name: String!
    $email: String!
    $passwd: String!
    $role: String!
    $franchisesId: [Int!]!
  ) {
    updateUser(
      input: {
        id: $id
        name: $name
        email: $email
        passwd: $passwd
        role: $role
        franchisesId: $franchisesId
      }
    ) {
      id
      name
      email
      role
    }
  }
`

export const GET_USER_BY_ID = gql`
  query($id: Int!) {
    getUserById(id: $id) {
      id
      name
      email
      role
      franchises {
        id
        name
      }
    }
  }
`

export const RECOVERY_PASSWORD = gql`
  mutation($email: String!) {
    recoveryPassword(email: $email)
  }
`

export const CREATE_NEW_PASSWORD = gql`
  mutation($password: String!) {
    createNewPassword(password: $password)
  }
`

export const DELETE_USER = gql`
  mutation($id: Int!) {
    deleteUser(id: $id)
  }
`

export const GET_ALL_USERS = gql`
  query($filterFranchisesIds: [Int!]) {
    getAllUsers(filterFranchisesIds: $filterFranchisesIds) {
      data {
        id
        name
        email
        role
        franchises {
          name
        }
      }
      total
    }
  }
`
