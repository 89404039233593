import { useEffect, useState } from 'react'
import { FormikHelpers } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from 'urql'
import { useDispatch } from 'react-redux'
import { toastDanger, toastSuccess } from '../../../../redux/toast/actions'
import { ADD_ATTENDANCE_LIST, UPDATE_ATTENDANCE_LIST } from '../../../../queries/attendanceList'
import { handleErrors } from '../../../../utils/handleGraphQLErrors'
import { useAppSelector } from '../../../../redux/store'
import { useTeamsQuery } from '../../../../hooks/Teams/useTeams'
import {
  AttendanceListAthleteTableInterface,
  AttendanceListFormValues,
  useAddUpdateAttendanceListProps,
} from '../../../../@types/attendanceList'
import {
  useGetAllAttendanceListValuesQuery,
  useGetAllUnavailableDatesQuery,
} from '../../../../hooks/AttendanceList/useAttendanceList'
import { parseStringArrayToDates } from '../../../../utils/utils'

export const useAddEditAttendanceListContainer = () => {
  const { id: attendanceListId } = useParams()
  const [initialValues, setInitialValues] = useState<AttendanceListFormValues>({
    attendanceDay: null,
    franchise: 0,
    team: 0,
  })
  const [tableData, setTableData] = useState<AttendanceListAthleteTableInterface[]>([])
  const [excludeDates, setExcludeDates] = useState<Date[]>([])
  const [teamId, setTeamId] = useState<number>(0)

  const editMode = attendanceListId !== 'addAttendanceList'
  const dispatch = useDispatch()

  const { data: dataTeams, isFetching: isFetchingTeams } = useTeamsQuery(
    false,
    {
      isAthleteActive: true,
    },
    true
  )
  const {
    data: dataGetExcludeDate,
    isFetching: isFetchingExcludeDate,
  } = useGetAllUnavailableDatesQuery(!teamId, { teamId })
  const {
    data: attendanceListValues,
    error: errorAttendanceListValues,
    isFetching: isFetchingAttendanceListValues,
  } = useGetAllAttendanceListValuesQuery(!editMode, {
    id: parseInt(attendanceListId || '0', 10),
  })

  const navigate = useNavigate()

  const getFieldValue = (_: string, value: number) => setTeamId(value)
  const handleGoBack = () => navigate('/tecnico/chamadas')
  const backButton = { name: 'Voltar', onClick: () => navigate('/tecnico/chamadas') }
  const getLabelValue = !editMode ? 'Criar chamada' : 'Editar chamada'
  const isFetching = isFetchingExcludeDate || isFetchingTeams || isFetchingAttendanceListValues

  useEffect(() => {
    const mappedUnavailableDatesList = dataGetExcludeDate?.map((item) => item?.unavailableDate)
    const parsedList = parseStringArrayToDates(mappedUnavailableDatesList)
    setExcludeDates(parsedList)
  }, [dataGetExcludeDate])

  useEffect(() => {
    if (!editMode && teamId && dataTeams) {
      const selectedTeam = dataTeams.data.find((item) => item?.id === teamId)
      const athletesMapped = selectedTeam?.athletes
        ?.sort((a, b) => (a?.name < b?.name ? -1 : 1))
        ?.map((item) => ({
          athleteId: item?.id,
          name: item?.name,
          p: false,
          f: true,
          fj: false,
          pse: 0,
          reason: '',
        }))
      setTableData(athletesMapped ?? [])
    }
  }, [editMode, teamId, dataTeams])

  useEffect(() => {
    if (editMode && attendanceListValues && !isFetchingAttendanceListValues) {
      if (errorAttendanceListValues) dispatch(toastDanger('Erro ao buscar chamada!'))

      const attendanceDay = Date.parse(attendanceListValues?.attendanceDay)
      setInitialValues({
        attendanceDay,
        franchise: attendanceListValues?.franchise?.id,
        team: attendanceListValues?.team?.id,
      })
      const data = attendanceListValues?.items?.sort((a, b) => (a?.name < b?.name ? -1 : 1))
      setTableData(data ?? [])
    }
  }, [
    dispatch,
    attendanceListId,
    errorAttendanceListValues,
    editMode,
    attendanceListValues,
    isFetchingAttendanceListValues,
  ])

  return {
    isFetching,
    dataTeams,
    tableData,
    setTableData,
    attendanceListId,
    initialValues,
    setInitialValues,
    editMode,
    handleGoBack,
    getLabelValue,
    backButton,
    excludeDates,
    getFieldValue,
  }
}

export const useAddUpdateAttendanceList = ({
  initialValues,
  setInitialValues,
  tableData,
}: useAddUpdateAttendanceListProps) => {
  const {
    auth: { user },
  } = useAppSelector((state) => state)

  const [isOpen, setIsOpen] = useState(false)
  const { id: attendanceListId } = useParams()
  const editMode = attendanceListId !== 'addAttendanceList'

  const [addAttendanceListResult, addAttendanceList] = useMutation(ADD_ATTENDANCE_LIST)
  const [updateAttendanceListResult, updateAttendanceList] = useMutation(UPDATE_ATTENDANCE_LIST)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const validation = (athletesList: AttendanceListAthleteTableInterface[]) => {
    let isValid = true
    athletesList.forEach((item) => {
      if (item?.fj && !item.reason) isValid = false
    })
    return isValid
  }

  const handleSubmit = (
    values: AttendanceListFormValues,
    actions: FormikHelpers<AttendanceListFormValues>
  ) => {
    const isValid = validation(tableData)
    if (isValid) {
      setIsOpen(true)
      setInitialValues({
        attendanceDay: values.attendanceDay,
        franchise: values.franchise,
        team: values.team,
      })
    } else {
      dispatch(toastDanger('As faltas justificadas devem possuir justificativa!'))
    }
    actions.setSubmitting(false)
  }

  const handleAction = () => {
    if (!editMode) {
      addAttendanceList({
        attendanceDay: initialValues.attendanceDay,
        franchiseId: initialValues.franchise,
        teamId: initialValues.team,
        userId: user?.id,
        reports: tableData,
      })
        .then((result) => {
          if (result?.error) {
            const [error] = handleErrors(result?.error)
            dispatch(toastDanger(error))
          } else {
            dispatch(toastSuccess('Chamada criada com sucesso!'))
            navigate('/tecnico/chamadas')
          }
        })
        .catch(() => dispatch(toastDanger('Erro ao criar chamada!')))
    } else {
      updateAttendanceList({
        id: parseInt(attendanceListId || '0', 10),
        attendanceDay: initialValues.attendanceDay,
        reports: tableData,
      })
        .then((result) => {
          if (result?.error) {
            const [error] = handleErrors(result?.error)
            dispatch(toastDanger(error))
          } else {
            dispatch(toastSuccess('Chamada atualizada com sucesso!'))
            navigate('/tecnico/chamadas')
          }
        })
        .catch(() => dispatch(toastDanger('Erro ao atualizar chamada!')))
    }
  }

  const isFetching = addAttendanceListResult.fetching || updateAttendanceListResult.fetching

  return {
    isFetching,
    handleAction,
    isOpen,
    setIsOpen,
    handleSubmit,
  }
}
