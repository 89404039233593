import { useMemo } from 'react'
import {
  Panel,
  TableComponent,
  BreadcrumbItem,
  TableActions,
  Overlay,
  ActiveLabel,
} from '../../../components'
import { useDeleteTeam, useTeamsContainer } from './useTeamsContainer'

function Teams() {
  const {
    handleDeleteAction,
    isOpen,
    setIsOpen,
    deleteOptions,
    isFetchingDelete,
    handleDeleteTeam,
  } = useDeleteTeam()

  const {
    isFetching,
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    total,
    panelButtons,
    editTeam,
  } = useTeamsContainer(isOpen)

  const columns = useMemo(
    () => [
      {
        Header: 'Nome',
        accessor: 'name',
        width: 180,
      },
      {
        Header: 'Categoria',
        accessor: 'gender',
        width: 80,
        mobileName: 'Categoria:',
      },
      {
        Header: 'Dias',
        accessor: 'days',
        width: 180,
      },
      {
        Header: 'Horário',
        accessor: 'schedule',
        width: 100,
      },
      {
        Header: 'Unidade',
        accessor: 'franchise',
        width: 150,
        mobileName: 'Unidade:',
      },
      {
        Header: 'Ativo',
        accessor: 'active',
        noSort: true,
        width: 50,
        Cell: (props: any) => {
          const {
            row: {
              original: { active },
            },
          } = props
          return <ActiveLabel isActive={!!active} />
        },
      },
      {
        Header: 'Ações',
        accessor: 'actions',
        width: 50,
        noSort: true,
        Cell: (props: any) => {
          const {
            row: {
              original: { id: teamId, name: teamName },
            },
          } = props
          return (
            <TableActions
              actionsList={[
                {
                  type: 'edit',
                  title: 'Editar',
                  handleClick: () => editTeam(teamId),
                },
                {
                  type: 'delete',
                  title: 'Excluir',
                  handleClick: () => handleDeleteTeam(teamId, teamName),
                },
              ]}
            />
          )
        },
      },
    ],
    [editTeam, handleDeleteTeam]
  )

  const breadcrumbs = [
    <BreadcrumbItem key="tecnico" text="Técnico" link="/geral/turmas" />,
    <BreadcrumbItem key="turmas" text="Turmas" />,
  ]

  return (
    <Panel title="Turmas" buttons={panelButtons} isLoading={isFetching} breadcrumbs={breadcrumbs}>
      <TableComponent
        columns={columns}
        data={tableData}
        canPreviousPage={currentPage !== 1}
        canNextPage={currentPage !== Math.ceil(total / pageSize)}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        fetching={isFetching || isFetchingDelete}
        totalPages={Math.ceil(total / pageSize)}
      />
      <Overlay
        overlayTitle="Excluir turma"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        buttonText="Confirmar"
        handleClick={handleDeleteAction}
      >
        <p>{`Realmente deseja excluir a turma${deleteOptions.name} ?`}</p>
      </Overlay>
    </Panel>
  )
}

export default Teams
