import * as Yup from 'yup'
import { cpfRegex, phoneRegex } from '../../utils/regex'

export const validation = Yup.object({
  name: Yup.string().max(45, 'Nome muito longo').required('Campo obrigatório'),
  email: Yup.string()
    .max(45, 'E-mail muito longo')
    .email('Insira um e-mail válido')
    .required('Campo obrigatório'),
  cpf: Yup.string().matches(cpfRegex, 'CPF inválido'),
  franchise: Yup.number().required('Campo obrigatório'),
  position: Yup.string().required('Campo obrigatório'),
  phone: Yup.string().matches(phoneRegex, 'Telefone inválido').required('Campo obrigatório'),
})

export const validationCoach = Yup.object({
  name: Yup.string().max(45, 'Nome muito longo').required('Campo obrigatório'),
  email: Yup.string()
    .max(45, 'E-mail muito longo')
    .email('Insira um e-mail válido')
    .required('Campo obrigatório'),
  cpf: Yup.string().matches(cpfRegex, 'CPF inválido'),
  franchise: Yup.number().required('Campo obrigatório'),
  position: Yup.string().required('Campo obrigatório'),
  phone: Yup.string().matches(phoneRegex, 'Telefone inválido').required('Campo obrigatório'),
  passwd: Yup.string()
    .min(3, 'Senha muito curta')
    .max(45, 'Senha muito longa')
    .required('Campo obrigatório'),
  confirmPassword: Yup.string()
    .min(3, 'Senha muito curta')
    .oneOf([Yup.ref('passwd'), null], 'As senhas não coincidem')
    .required('Campo obrigatório'),
})
