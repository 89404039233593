import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Add as AddIcon } from '@mui/icons-material'
import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'
import { useMutation } from 'urql'
import { useAppSelector } from '../../../redux/store'
import { useCoachesQuery } from '../../../hooks/Coaches/useCoaches'
import { filterFranchisesIds } from '../../../utils/utils'
import { DELETE_COACH } from '../../../queries/coaches'
import { handleErrors } from '../../../utils/handleGraphQLErrors'
import { toastDanger, toastSuccess } from '../../../redux/toast/actions'

export const useCoachesContainer = (isOpen: boolean) => {
  const {
    auth: { user },
    globalFilter: { selectedFranchises },
  } = useAppSelector((state) => state)

  const { data, isFetching, reExecute } = useCoachesQuery(
    !['Administrador', 'Admin Unidade'].includes(user?.role),
    {
      ...(!isEmpty(selectedFranchises) && {
        filterFranchisesIds: filterFranchisesIds(selectedFranchises),
      }),
    }
  )

  const [tableData, setTableData] = useState([] as any)
  const [pageSize, setPageSize] = useState<number>(100)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [total, setTotal] = useState<number>(0)

  const navigate = useNavigate()

  useEffect(() => {
    if (!isOpen) reExecute({ requestPolicy: 'network-only' })
  }, [reExecute, isOpen])

  useEffect(() => {
    if (!isFetching && data) {
      const coachListMapped = data?.data?.map((coach: any) => ({
        id: coach?.id,
        name: coach?.name,
        position: coach?.position,
        active: coach?.active,
        franchises: coach?.user?.franchises,
        role: coach?.role,
      }))
      setTableData(coachListMapped ?? [])
      setTotal(data?.total ?? 0)
    }
  }, [data, isFetching])

  const addCoach = () => navigate(`/tecnico/treinadores/addCoach`)
  const editCoach = (userId: any) => navigate(`/tecnico/treinadores/${userId}`)

  const panelButtons = [
    {
      onClick: addCoach,
      buttonIcon: <AddIcon />,
      name: 'Criar treinador',
    },
  ]

  return {
    tableData,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    total,
    addCoach,
    editCoach,
    panelButtons,
    isFetching,
  }
}

export const useDeleteCoaches = () => {
  const [deleteCoachResult, deleteCoach] = useMutation(DELETE_COACH)
  const [isOpen, setIsOpen] = useState(false)
  const [deleteOptions, setDeleteOptions] = useState({ id: '', name: '' })

  const dispatch = useDispatch()

  const handleDeleteCoach = (coachId: string, coachName: string) => {
    setDeleteOptions({ id: coachId ?? '0', name: coachName })
    setIsOpen(true)
  }

  const handleDeleteAction = () => {
    deleteCoach({
      id: parseInt(deleteOptions.id, 10),
    })
      .then((result: any) => {
        if (result?.error) {
          const [error] = handleErrors(result?.error)
          dispatch(toastDanger(error))
        } else {
          dispatch(toastSuccess('Treinador removido com sucesso!'))
          setIsOpen(false)
          setDeleteOptions({ id: '', name: '' })
        }
      })
      .catch(() => {
        dispatch(toastDanger('Erro ao remover treinador'))
      })
  }

  useEffect(() => {
    if (!isOpen) setDeleteOptions({ id: '', name: '' })
  }, [isOpen])

  const isFetchingDelete = deleteCoachResult.fetching

  return {
    handleDeleteCoach,
    handleDeleteAction,
    isOpen,
    setIsOpen,
    deleteOptions,
    isFetchingDelete,
  }
}
