import * as Yup from 'yup'

export const ValidationFranchiseForm = Yup.object({
  name: Yup.string().max(45, 'Nome muito longo').required('Campo obrigatório'),
  email: Yup.string()
    .max(45, 'E-mail muito longo')
    .email('Insira um e-mail válido')
    .required('Campo obrigatório'),
  cnpj: Yup.string()
    .required('Campo obrigatório')
    .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, 'CNPJ inválido'),
  street: Yup.string().required('Campo obrigatório'),
  streetNumber: Yup.string().required('Campo obrigatório'),
  neighborhood: Yup.string().required('Campo obrigatório'),
  cep: Yup.string()
    .required('Campo obrigatório')
    .matches(/^[0-9]{5}-[\d]{3}$/, 'CEP inválido'),
  city: Yup.string().required('Campo obrigatório'),
  state: Yup.string().required('Campo obrigatório'),
})
