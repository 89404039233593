import { ActionsGlobalFilterTypes } from '../types'
import { GlobalFilterInterface } from '../types/globalFilter'

const initialState: GlobalFilterInterface = {
  selectedFranchises: [],
}

const globalFilterReducer = (
  state = initialState,
  action: ActionsGlobalFilterTypes
): GlobalFilterInterface => {
  switch (action.type) {
    case 'CHANGE_FILTER_FRANCHISE':
      return {
        selectedFranchises: action.payload.selectedFranchises,
      }
    case 'CHANGE_FILTER_FRANCHISE_RESET':
      return {
        selectedFranchises: [],
      }
    default:
      return {
        ...state,
      }
  }
}

export default globalFilterReducer
