import styled from 'styled-components'

const NotFoundWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${(props) => props?.theme?.spacing?.spc30} ${(props) => props?.theme?.spacing?.spc30};
  margin-top: ${(props) => props?.theme?.spacing?.spc30};
  border-radius: ${(props) => props?.theme?.spacing?.spc10};
  height: 20rem;
  background-color: ${(props) => props?.theme?.colors?.white};
`
const ErrorTitle = styled('h1')`
  font-size: 8rem;
  color: ${(props) => props?.theme?.colors?.primary};
  margin-bottom: 0;
`

const Title = styled('h1')`
  color: ${(props) => props?.theme?.colors?.primary};
`

function PageNotFound() {
  return (
    <NotFoundWrapper>
      <ErrorTitle>404</ErrorTitle>
      <Title>Essa página não foi encontrada!</Title>
    </NotFoundWrapper>
  )
}

export default PageNotFound
