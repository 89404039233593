// @ts-nocheck
import { createGlobalStyle, DefaultTheme } from 'styled-components'
import SequelBlack from '../fonts/SEQUEL BLACK/Sequel100Black-45.ttf'
import Tungsten from '../fonts/TUNGSTEN/Tungsten-Bold.ttf'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: #f5f5f5;
    @font-face {
        font-family: "sequelBlack";
        src: url(${SequelBlack});
    }
    @font-face {
        font-family: "tungsten";
        src: url(${Tungsten});
    }
  }
`

const theme: DefaultTheme = {
  colors: {
    white: '#fff',
    black: '#000',
    softBlack: '#1c1c1c',
    golden: '#ddb665',
    green: '#459b87',
    middleGreen: '#2D7275',
    primary: '#173f37',
    waterGreen: '#00FFE0',
    greyBase: '#9c9ba0',
    greyDark: '#F8F9FA',
    greyLight: '#CFD7ED',
    greySoftLight: '#f7f7f7',
    fontWhite: '#fff',
    fontBlack: '#000',
    border: '#d8d8d8',
    whiteGrey: '#F8FAFF',
    grey: '#efefef',
    red: '#cc2f2c',
    lightGreen: '#7cea85',
    lightGreenLowOpacity: '#e0ffe2',
    lightYellow: '#fdff9e',
    lightRed: '#ff9e9e',
  },
  spacing: {
    spc2: '0.1rem',
    spc4: '0.2rem',
    spc6: '0.3rem',
    spc8: '0.4rem',
    spc10: '0.5rem',
    spc12: '0.6rem',
    spc14: '0.7rem',
    spc16: '0.8rem',
    spc18: '0.9rem',
    spc20: '1.0rem',
    spc25: '1.2rem',
    spc30: '1.5rem',
    spc35: '2.0rem',
    spc40: '2.5rem',
    spc45: '3.0rem',
    spc50: '3.5rem',
    spc60: '5.5rem',
    spc65: '6.5rem',
    spc70: '7.5rem',
    spc80: '10rem',
    spc90: '12rem',
  },
  fontSizes: {
    font8: '0.5rem',
    font9: '0.55rem',
    font10: '0.6rem',
    font12: '0.7rem',
    font14: '0.8rem',
    font16: '0.9rem',
    font18: '1.0rem',
    font20: '1.1rem',
    font22: '1.2rem',
    font24: '1.3rem',
    font26: '1.4rem',
    font27: '1.5rem',
    font30: '2.0rem',
    font35: '2.5rem',
    font40: '3.0rem',
    font45: '3.5rem',
    font50: '5.0rem',
  },
  screens: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
  },
}

export { theme, GlobalStyle }
