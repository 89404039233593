import { ActionsToastTypes } from '../types'
import { ToastStateInterface } from '../types/toast'

const initialState: ToastStateInterface = {
  message: '',
}
const toastReducer = (state = initialState, action: ActionsToastTypes): ToastStateInterface => {
  switch (action.type) {
    case 'TOAST_CHANGE_SUCCESS':
      return {
        ...state,
        message: action.message,
      }
    case 'TOAST_CHANGE_WARNING':
      return {
        ...state,
        message: action.message,
      }
    case 'TOAST_CHANGE_ERROR':
      return {
        ...state,
        message: action.message,
      }
    case 'TOAST_CHANGE_INFO':
      return {
        ...state,
        message: action.message,
      }
    case 'TOAST_RESET':
      return {
        ...state,
        message: initialState.message,
      }
    default:
      return {
        ...state,
      }
  }
}

export default toastReducer
