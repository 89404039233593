import styled from 'styled-components'

interface LabelWrapperProps {
  isActive?: boolean
  isClickable?: boolean
}

const LabelWrapper = styled.div<LabelWrapperProps>`
  ${(props) => `
  width: 70px;
  height: 25px;
  display: flex;
  padding: 0 5px;
  align-items: center;
  gap: 5px;
  background-color: ${
    props?.isActive ? props.theme.colors.lightGreenLowOpacity : props.theme.colors.greyBase
  };
  color: ${props?.isActive ? props.theme.colors.primary : props.theme.colors.white};
  font-size: ${props.theme.fontSizes.font16};
  font-weight: bold;
  border-radius: 20px;
  cursor: ${props.isClickable ? 'pointer' : 'default'};
  `}
`

const CircleElement = styled('div')<LabelWrapperProps>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.isActive ? props?.theme?.colors?.primary : props?.theme?.colors?.white};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-right: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  @media (max-width: 768px) {
    width: 8px;
    height: 8px;
  }
`

export default function ActiveLabel({
  isActive,
  handleOnClick,
}: Readonly<{
  isActive: boolean
  handleOnClick?: () => void
}>) {
  const handleActivateDeactivate = () => handleOnClick?.()

  return (
    <LabelWrapper
      onClick={handleActivateDeactivate}
      isActive={isActive}
      isClickable={!!handleOnClick}
    >
      <CircleElement isActive={isActive} />
      <div>{isActive ? 'Ativo' : 'Inativo'}</div>
    </LabelWrapper>
  )
}
